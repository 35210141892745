.histo-chart-wrapper {
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 2%;
  padding: 20px;
  .title {
    h3 {
      color: black;
      margin-left: 20px;
    }
    button {
      box-shadow: none;
    }
  }
  .alert {
    margin-top: 20px;
  }
}

.histo-chart-container {
  position: relative;
  #tooltip {
    z-index: 1;
    position: absolute;
    border-radius: 5px;
    transform: translateX(-50%);
    left: 0;
    .carret-container {
      position: relative;
      height: 100%;
      top: 100%;
    }
  }
  #canvas-x {
    cursor: pointer;
  }
  #hover-line {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

.galery-container {
  border-radius: 10px;
  border: 1px solid lightgrey;
  background-color: white;
  padding: 20px;
  margin-bottom: 2%;
  .title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .flex-box {
      align-items: center;
    }
    .switch-mode {
      display: flex;
      align-items: center;
      .mode-button {
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.activ {
          box-shadow: inset 0 0 5px #333;
        }
        &.inactiv {
          box-shadow: 0 0 4px #333;
        }
        &:last-child {
          border-left: none;
        }
      }
    }
    * {
      margin: 0;
    }
    h3 {
      margin-left: 10px;
      color: black;
    }
  }
  .galery-wrapper {
    width: 100%;
    border-top: 1px solid lightgrey;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    position: relative;

    .more-picture {
      position: absolute;
      bottom: -15px;
      right: 5px;
      color: #c6c6c6;
      cursor: pointer;
      &:hover {
        color: #333;
      }
    }

    &.column {
      max-height: unset;
      overflow: hidden;
      .list-wrapper {
        width: 40%;
        max-width: 400px;
        max-height: 550px;
        overflow: auto;
        flex-flow: column;
      }
      .image-container {
        margin-bottom: 30px;
        margin-right: 0;
        max-height: 150px;
      }
    }
    .list-wrapper {
      display: flex;
      flex-wrap: wrap;
      max-height: 330px;
      overflow: hidden;
      width: 100%;
      min-width: min-content;
    }
    .separator {
      border-bottom: 2px solid #ccc;
    }
    .image-container {
      margin-top: 10px;
      margin-right: 20px;
      display: flex;
      img {
        object-fit: cover;
      }
      .info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 20px;
        padding: 15px 0;
        height: 150px;
        .info-box {
          display: flex;
          align-items: center;
          .svg-box {
            width: 25px;
          }
          .text-field {
            margin-left: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            h5 {
              margin: 0;
            }
          }
        }
      }
    }
    img {
      height: 150px;
      width: 150px;
      cursor: pointer;
    }

    .vertical-line {
      height: 100%;
      background-color: lightgrey;
      width: 0.5px;
    }
    .zoom-info-container {
      width: 100%;
      position: relative;
      padding-left: 40px;
      .info-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .svg-wrapper {
          width: 40px;
          height: 40px;
          background-color: lightgrey;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-right: 10px;
          &.geolocalisable {
            background-color: white;
            border: 2px solid #31c6b3;
            box-shadow: 2px 2px 5px #34827a;
            &:hover {
              background-color: #34827a;
            }
          }
        }
        .info-wrapper {
          display: flex;
          width: 100%;
          justify-content: center;
        }
        .info-box {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          &:nth-child(2) {
            margin-left: 30px;
            margin-right: 30px;
          }
          h5 {
            margin: 0;
          }
          .text-field {
            margin-left: 20px;
          }
          .svg-box {
            width: 25px;
          }
        }
      }
      .vertical-line {
        height: 100%;
        position: absolute;
        left: 0;
      }
      .picture-name h3 {
        color: black;
      }
      .selected-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .map-pdi {
          .mapbox-wrapper {
            width: 100%;
            height: 100%;
            .mapboxgl-map {
              width: 100%;
              height: 100%;
            }
          }
        }

        .zoomed-pic {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          img {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
          }
        }
      }
    }
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 500px;
    perspective: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    background-color: transparent;
  }

  .flip-card.back .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }

  .flip-card-front {
    color: black;
  }

  .flip-card-back {
    color: white;
    transform: rotateY(180deg);
  }
}
