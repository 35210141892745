.radio-button-container {
  display: flex;
  width: 100%;
  min-width: 300px;
  .r-button {
    width: 50%;
    border: 1px solid #31c6b3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 5px;
    cursor: pointer;
    transition: 0.3s;
    &.on {
      background-color: #31c6b3;
      outline: 3px solid #31c6b3cf;
    }
    span {
      font-size: 16px;
      text-align: center;
      color: black;
    }
  }
}

#popover-multi {
  .btn-info {
    margin: 10px;
  }
}

.popover-body {
  .button-box {
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 2px solid lightgrey;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    p {
      margin: 0;
    }
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}

.popover-container {
  position: fixed;
  border: 1px solid lightgrey;
  background-color: white;
  left: 0;
  top: 500px;
  z-index: 99999;
  transform: translateX(-50%);
  border-radius: 5px;
  box-shadow: 0 3px 8px grey;

  .title-box {
    background-color: #efefefff;
    padding: 5px 10px;
    position: relative;
    z-index: 1000;
    h5 {
      font-size: 12px;
      margin: 0;
    }
  }
  .body-wrapper {
    padding: 5px 20px;
  }
  .arrow-block {
    position: absolute;
    height: 10px;
    width: 10px;
    transform: rotate(45deg) translateX(-50%);
    background-color: #efefefff;
    border: 1px solid lightgrey;
    left: 50%;
    top: -2.5px;
    z-index: 999;
  }
}

#black-background {
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
