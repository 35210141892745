@charset "UTF-8";
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #31c6b3;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --green-dark: #34827a;
  --primary: #31c6b3;
  --secondary: #34827a;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #fff;
  --dark: #343a40;
  --green: #31c6b3;
  --green-dark: #31c6b3;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2c303b;
  text-align: left;
  background-color: #ebecf1;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #2c303b;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #34827a;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.6rem;
}

h3, .h3 {
  font-size: 1.4rem;
}

h4, .h4 {
  font-size: 1.2rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.8rem;
}

.lead {
  font-size: 1rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ebecf1;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .clusterMeterList .react-bootstrap-table-pagination-list, .remoteReadingData .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12, .clusterMeterList .react-bootstrap-table-pagination-list, .remoteReadingData .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #2c303b;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #2c303b;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5efea;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #94e1d7;
}

.table-hover .table-primary:hover {
  background-color: #b1e9e3;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b1e9e3;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c6dcda;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #95beba;
}

.table-hover .table-secondary:hover {
  background-color: #b6d2d0;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b6d2d0;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: white;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: white;
}

.table-hover .table-light:hover {
  background-color: #f2f2f2;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f2f2f2;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-green,
.table-green > th,
.table-green > td {
  background-color: #c5efea;
}
.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #94e1d7;
}

.table-hover .table-green:hover {
  background-color: #b1e9e3;
}
.table-hover .table-green:hover > td,
.table-hover .table-green:hover > th {
  background-color: #b1e9e3;
}

.table-green-dark,
.table-green-dark > th,
.table-green-dark > td {
  background-color: #c5efea;
}
.table-green-dark th,
.table-green-dark td,
.table-green-dark thead th,
.table-green-dark tbody + tbody {
  border-color: #94e1d7;
}

.table-hover .table-green-dark:hover {
  background-color: #b1e9e3;
}
.table-hover .table-green-dark:hover > td,
.table-hover .table-green-dark:hover > th {
  background-color: #b1e9e3;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #92e4da;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #2c303b;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #2c303b;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #2c303b;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-primary:hover {
  color: #fff;
  background-color: #29a797;
  border-color: #279d8e;
}
.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 174, 158, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #279d8e;
  border-color: #249385;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 174, 158, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #34827a;
  border-color: #34827a;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #296760;
  border-color: #255e58;
}
.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 149, 142, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #34827a;
  border-color: #34827a;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #255e58;
  border-color: #22544f;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 149, 142, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-light:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-green {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-green:hover {
  color: #fff;
  background-color: #29a797;
  border-color: #279d8e;
}
.btn-green:focus, .btn-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 174, 158, 0.5);
}
.btn-green.disabled, .btn-green:disabled {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active, .show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #279d8e;
  border-color: #249385;
}
.btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus, .show > .btn-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 174, 158, 0.5);
}

.btn-green-dark {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-green-dark:hover {
  color: #fff;
  background-color: #29a797;
  border-color: #279d8e;
}
.btn-green-dark:focus, .btn-green-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 174, 158, 0.5);
}
.btn-green-dark.disabled, .btn-green-dark:disabled {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-green-dark:not(:disabled):not(.disabled):active, .btn-green-dark:not(:disabled):not(.disabled).active, .show > .btn-green-dark.dropdown-toggle {
  color: #fff;
  background-color: #279d8e;
  border-color: #249385;
}
.btn-green-dark:not(:disabled):not(.disabled):active:focus, .btn-green-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-green-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 174, 158, 0.5);
}

.btn-outline-primary {
  color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-primary:hover {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #31c6b3;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}

.btn-outline-secondary {
  color: #34827a;
  border-color: #34827a;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #34827a;
  border-color: #34827a;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 130, 122, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #34827a;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #34827a;
  border-color: #34827a;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 130, 122, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-green {
  color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-green:hover {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-green:focus, .btn-outline-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}
.btn-outline-green.disabled, .btn-outline-green:disabled {
  color: #31c6b3;
  background-color: transparent;
}
.btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active, .show > .btn-outline-green.dropdown-toggle {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}

.btn-outline-green-dark {
  color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-green-dark:hover {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-green-dark:focus, .btn-outline-green-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}
.btn-outline-green-dark.disabled, .btn-outline-green-dark:disabled {
  color: #31c6b3;
  background-color: transparent;
}
.btn-outline-green-dark:not(:disabled):not(.disabled):active, .btn-outline-green-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-green-dark.dropdown-toggle {
  color: #212529;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.btn-outline-green-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-green-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-green-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #2c303b;
  text-decoration: none;
}
.btn-link:hover {
  color: #34827a;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  box-shadow: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8rem;
  color: #2c303b;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #31c6b3;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.7rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.2rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #31c6b3;
  background-color: #31c6b3;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #92e4da;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #bbeee8;
  border-color: #bbeee8;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.1rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.1rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #31c6b3;
  background-color: #31c6b3;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(49, 198, 179, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(49, 198, 179, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(49, 198, 179, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.1rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(49, 198, 179, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #92e4da;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #92e4da;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ebecf1, 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ebecf1, 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #ebecf1, 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #31c6b3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #bbeee8;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #31c6b3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #bbeee8;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #31c6b3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #bbeee8;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ebecf1;
  border-color: #dee2e6 #dee2e6 #ebecf1;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #31c6b3;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(44, 48, 59, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(44, 48, 59, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(44, 48, 59, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(49, 198, 179, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(44, 48, 59, 0.9);
}
.navbar-light .navbar-toggler {
  color: #fff;
  border-color: rgba(52, 130, 122, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23");
}
.navbar-light .navbar-text {
  color: #fff;
}
.navbar-light .navbar-text a {
  color: rgba(44, 48, 59, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(44, 48, 59, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: flex;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion > .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #2c303b;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #34827a;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #212529;
  background-color: #31c6b3;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #212529;
  background-color: #279d8e;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #34827a;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #255e58;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 130, 122, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #fff;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #e6e6e6;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.badge-green {
  color: #212529;
  background-color: #31c6b3;
}
a.badge-green:hover, a.badge-green:focus {
  color: #212529;
  background-color: #279d8e;
}
a.badge-green:focus, a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}

.badge-green-dark {
  color: #212529;
  background-color: #31c6b3;
}
a.badge-green-dark:hover, a.badge-green-dark:focus {
  color: #212529;
  background-color: #279d8e;
}
a.badge-green-dark:focus, a.badge-green-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(49, 198, 179, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.7rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #19675d;
  background-color: #d6f4f0;
  border-color: #c5efea;
}
.alert-primary hr {
  border-top-color: #b1e9e3;
}
.alert-primary .alert-link {
  color: #0f3e38;
}

.alert-secondary {
  color: #1b443f;
  background-color: #d6e6e4;
  border-color: #c6dcda;
}
.alert-secondary hr {
  border-top-color: #b6d2d0;
}
.alert-secondary .alert-link {
  color: #0d1f1d;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #858585;
  background-color: white;
  border-color: white;
}
.alert-light hr {
  border-top-color: #f2f2f2;
}
.alert-light .alert-link {
  color: #6c6c6c;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

.alert-green {
  color: #19675d;
  background-color: #d6f4f0;
  border-color: #c5efea;
}
.alert-green hr {
  border-top-color: #b1e9e3;
}
.alert-green .alert-link {
  color: #0f3e38;
}

.alert-green-dark {
  color: #19675d;
  background-color: #d6f4f0;
  border-color: #c5efea;
}
.alert-green-dark hr {
  border-top-color: #b1e9e3;
}
.alert-green-dark .alert-link {
  color: #0f3e38;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.6rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #31c6b3;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #2c303b;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #31c6b3;
  border-color: #31c6b3;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal .list-group-item {
  margin-right: -1px;
  margin-bottom: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal .list-group-item:last-child {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item {
    margin-right: -1px;
    margin-bottom: 0;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.list-group-item-primary {
  color: #19675d;
  background-color: #c5efea;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #19675d;
  background-color: #b1e9e3;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #19675d;
  border-color: #19675d;
}

.list-group-item-secondary {
  color: #1b443f;
  background-color: #c6dcda;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #1b443f;
  background-color: #b6d2d0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #1b443f;
  border-color: #1b443f;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #858585;
  background-color: white;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.list-group-item-green {
  color: #19675d;
  background-color: #c5efea;
}
.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
  color: #19675d;
  background-color: #b1e9e3;
}
.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #19675d;
  border-color: #19675d;
}

.list-group-item-green-dark {
  color: #19675d;
  background-color: #c5efea;
}
.list-group-item-green-dark.list-group-item-action:hover, .list-group-item-green-dark.list-group-item-action:focus {
  color: #19675d;
  background-color: #b1e9e3;
}
.list-group-item-green-dark.list-group-item-action.active {
  color: #fff;
  background-color: #19675d;
  border-color: #19675d;
}

.close {
  float: right;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.7rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #2c303b;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #31c6b3 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #279d8e !important;
}

.bg-secondary {
  background-color: #34827a !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #255e58 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #fff !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-green {
  background-color: #31c6b3 !important;
}

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #279d8e !important;
}

.bg-green-dark {
  background-color: #31c6b3 !important;
}

a.bg-green-dark:hover, a.bg-green-dark:focus,
button.bg-green-dark:hover,
button.bg-green-dark:focus {
  background-color: #279d8e !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #31c6b3 !important;
}

.border-secondary {
  border-color: #34827a !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #fff !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-green {
  border-color: #31c6b3 !important;
}

.border-green-dark {
  border-color: #31c6b3 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #31c6b3 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #22897c !important;
}

.text-secondary {
  color: #34827a !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #1e4b47 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #fff !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d9d9d9 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-green {
  color: #31c6b3 !important;
}

a.text-green:hover, a.text-green:focus {
  color: #22897c !important;
}

.text-green-dark {
  color: #31c6b3 !important;
}

a.text-green-dark:hover, a.text-green-dark:focus {
  color: #22897c !important;
}

.text-body {
  color: #2c303b !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
#root {
  height: 100%;
  font-family: "Roboto", sans-serif !important;
}

html {
  height: 100%;
}

body {
  color: #2c303b;
  height: 100%;
}

.flex-box {
  display: flex;
}

.row-expansion-style th {
  display: none;
}

.parent-row {
  background: rgba(49, 198, 179, 0.6509803922);
}

.logo-sidebar {
  width: 260px;
  margin: 0 auto;
  background: white;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  height: 90px;
  padding-top: 10px;
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background: #31c6b3;
}

.sidebar .navbar-nav {
  display: inline;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.sidebar nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  display: block;
  z-index: 1;
}
.sidebar nav .nav-active {
  color: #2c303b !important;
}

.sidebar-item {
  margin-left: 40px;
  margin-top: 10px;
}
.sidebar-item svg {
  margin-right: 5px;
  width: 35px;
}

.sidebar-mini-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.logo-sidebar-dashboard {
  width: 260px;
  background: white;
  height: 90px;
  padding-top: 10px;
}

.sidebar-mini .navbar-nav {
  display: inline;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  color: white;
}

.sidebar-mini nav {
  position: fixed;
  top: 90px;
  left: 0;
  max-width: 60px;
  height: 100%;
  display: block;
  z-index: 1;
  color: #fff;
}
.sidebar-mini nav a {
  color: #fff;
}
.sidebar-mini nav a:hover {
  color: #2c303b;
}

.sidebar-mini-synchro nav {
  position: fixed;
  top: 90px;
  left: 60px;
  max-width: 60px;
  height: 100%;
  display: block;
  z-index: 1;
  color: #fff;
}
.sidebar-mini-synchro nav #sidebar-synchro {
  color: white;
}
.sidebar-mini-synchro nav a {
  color: #fff;
}
.sidebar-mini-synchro nav a:hover {
  color: #2c303b;
}

.sidebar-locations-wrapper {
  position: fixed;
  top: 90px;
  height: 100%;
  left: 60px;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-location .navbar-nav {
  display: inline;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100%;
}
.sidebar-location .navbar-nav a#sidebar-tournee {
  color: #333;
}
.sidebar-location .navbar-nav .nav-item a#sidebar-tournee {
  color: #333;
}
.sidebar-location .navbar-nav .nav-item a.nav-link {
  color: #fff;
}
.sidebar-location .navbar-nav .nav-item a.nav-link:hover {
  color: #31c6b3 !important;
}
.sidebar-location .navbar-nav .nav-item a.nav-active {
  color: #31c6b3 !important;
}
.sidebar-location .navbar-nav .navbar-brand {
  height: 40px;
  border-bottom: 1px solid;
  width: 100%;
  line-height: 2em;
  padding: 0;
  padding-left: 10px;
  font-weight: bold;
  font-size: 18px;
}

.sidebar-location nav {
  position: fixed;
  top: 90px;
  bottom: 0;
  left: 60px;
  width: 200px;
  display: block;
  z-index: 1;
  background-color: #127a6d;
}

.sidebar-tournee nav {
  position: fixed;
  top: 90px;
  bottom: 60px;
  left: 120px;
  width: 200px;
  display: block;
  z-index: 1;
  height: 100%;
  background-color: white;
  border-right: 1px solid #333;
}

.interval-date .form-control.is-invalid ~ .invalid-feedback {
  display: none;
}

#tournee-navbar {
  position: fixed;
  top: 90px;
  left: 320px;
  width: calc(100% - 320px);
  display: block;
  z-index: 1;
  height: 60px;
  background-color: #df70ee;
}

.main-panel {
  position: relative;
  width: calc(100% - 260px);
  height: 100%;
}

.main-panel header nav.navbar {
  width: calc(100% - 60px);
  display: block;
}

.container-widget {
  height: 100%;
  padding-bottom: 60px !important;
  padding-left: 10px;
}

section.dash {
  padding-top: 100px;
}

.widget-action {
  bottom: 0;
  width: 100%;
}

.widget-unready {
  text-align: center;
}

.clickable td, .reportPopover .verticalA4 td, .reportPopover .horizontalA4 td, .settingListGroup li .orderName td, .reportContainer #preview-report #fullscreen td {
  cursor: pointer;
}

a.navbar-brand {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

td.dashboard-cell {
  height: 60px;
  padding-bottom: 0;
  padding-top: 0;
}

td.dashboard-cell div {
  width: 100%;
  height: 100%;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.btn-bookmarked {
  font-weight: 400;
  color: #dc3545;
  background-color: transparent;
}

.btn-unbookmarked {
  font-weight: 400;
  color: #6c757d;
  background-color: transparent;
}

.dashboards-margin {
  margin-bottom: 100px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  background-color: #ffffff;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.details-device > .list-group-item {
  border: none;
  padding: 0.5rem 1rem;
  padding-left: 0;
}

.no-data {
  height: 100%;
}

#helpme {
  color: black;
  cursor: pointer;
}

#HelpMeePlease {
  background: none;
  color: #808080;
  border: none;
}

.header-item {
  min-width: 70px;
  border-right: 1px solid #ccc !important;
  display: block;
  text-align: center;
  color: #808080;
}

.header-item-last {
  min-width: 80px;
  display: block;
  text-align: center;
}

#helpmeMini {
  position: fixed;
  bottom: 100px;
  color: black;
  left: 15px;
  z-index: 20000;
  cursor: pointer;
}

.helpModal textarea {
  min-height: 300px;
}

.bottomChoice {
  bottom: 0;
  margin-top: 2em;
  width: 100%;
}

.abstractInModal {
  min-height: 370px;
  position: relative;
}

.chart-container {
  width: 100%;
  margin-top: 15px;
}
.chart-container .alert-info {
  align-self: center;
  width: 100%;
}

.presentation-nonGeo-meters canvas {
  margin-top: -8vh;
}

.loading-last-read .alert-info {
  margin-top: 2vh;
  width: 100%;
}

.chart-container-light {
  width: 100%;
  height: 250px;
  margin-top: 15px;
}

.datasourceChoices {
  min-height: 370px;
  position: relative;
  margin-bottom: 10px;
}

.templateChoices {
  font-size: 24px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.CrystalMainHeader {
  position: fixed;
  width: calc(100% - 260px);
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

.CrystalFullMainHeader {
  position: fixed;
  width: 100%;
  margin-left: -260px;
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

.CrystalMainHeaderLocations {
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

.CrystalMainHeaderDashboard {
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

td.crystalList-column {
  white-space: nowrap;
}

.crystalList-column th {
  white-space: nowrap;
}

#associate-alone {
  height: 30px;
  width: 30px;
  background-color: lightgrey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#associate-alone:hover {
  background-color: #34827a;
}

.crystalList-container {
  max-width: 1800px;
}
.crystalList-container .dropdown-menu {
  left: 50px;
  margin-top: -80px;
}

.crystalList-container table {
  min-width: 0;
  white-space: nowrap;
  overflow: auto;
  display: block;
  table-layout: auto;
}

.crystalList-container-user table {
  min-width: 0;
  white-space: nowrap;
  display: block;
  table-layout: auto;
}

.breadcrumb-item.active {
  color: #31c6b3;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #31c6b3;
  content: ">";
}

.table-info-container .row-disabled-export .selection-checkbox {
  opacity: 0.2;
}
.table-info-container .error-chip {
  padding: 0 10px;
  margin: 5px;
  border-radius: 100px;
  background: white;
  border: 1px solid #dc3545;
}
.table-info-container .main-error-chip {
  padding: 0 10px;
  margin: 5px;
  border-radius: 100px;
  color: white;
  background: #dc3545;
  border: 1px solid #dc3545;
}

.table-info-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;
}
.table-info-container .row-user-table .svg {
  height: 20px;
  width: 20px;
  align-self: center;
  margin-left: 10px;
  background-color: lightgrey;
  border: 1px solid lightgrey;
  border-radius: 50%;
  text-align-last: center;
}
.table-info-container .title-chart.alarm {
  margin: 10px 0;
}
.table-info-container .margin {
  margin: 2vh 0;
}
.table-info-container .flex-box {
  display: flex;
}
.table-info-container .flex-box .title-chart {
  margin: 10px 0;
}
.table-info-container .flex-box .title-chart.right {
  margin-left: 2ch;
  cursor: pointer;
}
.table-info-container .table-bordered {
  border: none !important;
}
.table-info-container .text-filter {
  font-style: italic;
  color: #808080;
}
.table-info-container .btn-secondary {
  color: #fff;
  background-color: #31c6b3;
  border-color: #31c6b3;
  border-radius: 50px;
}
.table-info-container .import-silex-selection-button {
  background-color: #ebecf1;
  color: #2c303b;
  border-width: 0;
}
.table-info-container .import-silex-selection-button:hover, .table-info-container .import-silex-selection-button.selected {
  background-color: #31c6b3;
  border-width: 0;
  color: #fff;
}
.table-info-container h2 {
  border-left: 3px solid #31c6b3;
  margin-left: -22px;
  padding-left: 50px;
  padding-bottom: 5px;
  color: #2c303b;
}
.table-info-container h2 span {
  margin-left: -30px;
  margin-right: 15px;
}
.table-info-container .borderDiv {
  border-right: 3px solid #34827a;
}
.table-info-container h3 {
  margin-right: -22px;
  padding-right: 50px;
  padding-bottom: 5px;
  margin-top: 10px;
  text-align: center;
  vertical-align: middle;
}
.table-info-container .searchbar input {
  font-style: italic;
  color: #808080;
}
.table-info-container .add {
  cursor: pointer;
}

.switch-button {
  top: 7px;
  left: 5px;
}

.information-container {
  background: white;
  border-radius: 0 0 20px 20px;
  margin-left: 10px;
  padding: 10px;
}

.information-container-header {
  background: white;
  border-radius: 20px 20px 0 0;
  margin-left: 10px;
  padding: 15px 30px;
  padding-right: 15px;
  background: url(./_svgs/background.svg);
  background-size: 896px 1080px;
  color: #31c6b3;
}

.infoLibelle {
  font-size: 0.9em;
  color: #808080;
}

.infoDisplay {
  font-size: 1.5em;
  color: #2c303b;
  font-weight: bold;
  position: relative;
}

.infoDisplayFit {
  font-size: 1.2em;
  color: #2c303b;
  font-weight: bold;
  position: relative;
}

.modal-body .btn-primary {
  color: white;
}
.modal-body .button-submit-date {
  width: 100%;
}
.modal-body .button-submit-date button {
  float: right;
  width: 20%;
}
.modal-body .button-submit-date button.btn-primary {
  margin-left: 10px;
}

.alarm-type-selector {
  width: 100%;
}
.alarm-type-selector .flex-box {
  justify-content: center;
}
.alarm-type-selector .text-container {
  width: fit-content;
  width: -moz-fit-content;
  text-align: center;
  overflow-x: scroll;
}
.alarm-type-selector .text-container div {
  margin-right: 5px;
}
.alarm-type-selector .text-container div h5 {
  padding: 10px 5px;
  width: 100%;
  border: 1px solid #34827a;
  color: white;
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.modal-footer .btn-primary {
  color: white;
}

.modal-header {
  color: #31c6b3;
}

svg {
  pointer-events: none;
}

div .clickable, div .reportPopover .verticalA4, .reportPopover div .verticalA4, div .reportPopover .horizontalA4, .reportPopover div .horizontalA4, div .settingListGroup li .orderName, .settingListGroup li div .orderName, div .reportContainer #preview-report #fullscreen, .reportContainer #preview-report div #fullscreen {
  cursor: pointer;
}

.containerDash {
  min-width: calc(100% - 260px);
  margin-left: 260px;
  display: grid;
}
.containerDash .over {
  padding-left: 8px;
}

.logo-sidebar-dashboard {
  position: fixed;
  top: 0;
  width: 260px;
  margin: 0 auto;
  background: white;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 300;
  height: 90px;
  padding-top: 10px;
}

.round {
  background: #ebecf1;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  text-align: center;
}

.round:hover {
  background: #34827a;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: white;
}

.round-disabled {
  background: #ebecf1;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  text-align: center;
}

.bookmark-container {
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 0;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
  max-width: 23%;
}

.bookmark-header {
  width: 100%;
  background: url(./_svgs/background_green.svg);
  background-size: 896px 1080px;
  min-height: 60px;
  border-radius: 10px 10px 0 0;
}
.bookmark-header .bookmark-dashboard-title {
  display: block;
  padding-top: 10px;
  padding-left: 10px;
  color: white;
}
.bookmark-header .bookmark-dashboard-title a {
  margin-top: 100px;
  font-size: 0.8em;
  color: white;
}
.bookmark-header .bookmark-dashboard-main-title {
  font-size: 1.2em;
  display: block;
  margin-left: 10px;
  color: #2c303b;
}

.bookmark-body {
  background: #fff;
  min-height: 150px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  padding-top: 20px;
}
.bookmark-body img {
  height: 120px;
  width: 120px;
}

.filter-container {
  background: white;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  border: 0.5px solid #ccc;
}

.trash-filter,
.save-filter {
  margin-top: 5px;
}

.filterbutton {
  margin-left: 15px;
  background: #ebecf1;
  border: none;
  color: #2c303b;
  border-radius: 15px;
}

#buttonAddWidget .btn {
  background: none;
  border: none;
}

h2 div#addWidget,
#addDashboard,
#addAlert {
  height: 30px;
  width: 30px;
  background: #ebecf1;
  border-radius: 20px;
  padding: 5px;
  text-align: center;
  font-size: 0.5em;
  color: white;
  cursor: pointer;
}

h2 div#addWidget:hover,
#addDashboard:hover,
#addAlert:hover {
  background: #34827a;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: white;
  cursor: pointer;
}

.widget-info-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;
}
.widget-info-container h2 {
  border-left: 3px solid #31c6b3;
  margin-left: -33px;
  padding-left: 50px;
  padding-bottom: 5px;
  color: #2c303b;
  font-size: 1.5em;
  font-weight: bold;
  float: left;
  position: absolute;
  left: 30px;
}
.widget-info-container h2 span {
  margin-left: -30px;
  margin-right: 15px;
  margin-bottom: -5px;
}
.widget-info-container .btn-group {
  margin-top: 10px;
}
.widget-info-container .btn-group button {
  border-radius: 25px;
  border: 1px solid #808080;
  background-color: #fff;
  color: #808080;
}

.container-login {
  height: 100% !important;
  width: 100% !important;
  justify-content: center !important;
  flex-direction: column !important;
  display: flex !important;
  background: url(./_svgs/background.svg);
  background-repeat: repeat;
  background-size: 896px 1081px;
  position: fixed;
  bottom: 0;
}
.container-login .login-form {
  width: 50%;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 40%;
  left: 60%;
}
.container-login .col {
  max-width: 50% !important;
}
.container-login .login-form form {
  padding: 50px;
  float: left;
  width: 60%;
  min-width: 30%;
  color: white;
}
.container-login .login-form form .btn-primary {
  float: right;
  color: white;
}
.container-login .login-form form .version {
  margin-top: 50%;
  text-align: center;
}
.container-login div.login-logo-container {
  background: #fff;
  position: fixed;
  bottom: 0;
  height: 100%;
  width: 50%;
}
.container-login div.login-logo-container .logo {
  position: absolute;
  width: 60%;
  top: 45%;
  margin-left: 20%;
}

.presentation-container {
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 0;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
}

.presentation-header {
  background-size: 700px;
  width: 100%;
  background: url(./_svgs/background_green.svg);
  background-size: 896px 1080px;
  min-height: 60px;
  border-radius: 10px 10px 0 0;
}
.presentation-header .presentation-title {
  display: block;
  padding-top: 10px;
  padding-left: 10px;
  color: white;
}
.presentation-header .presentation-title a {
  margin-top: 100px;
  font-size: 0.8em;
  color: white;
}
.presentation-header .presentation-main-title {
  font-size: 1.3em;
  display: block;
  margin-left: 10px;
  color: #2c303b;
}

.presentation-header-yellow {
  background-size: 700px;
  width: 100%;
  background: url(./_svgs/background_yellow.svg);
  background-size: 896px 1080px;
  min-height: 60px;
  border-radius: 10px 10px 0 0;
}
.presentation-header-yellow .presentation-title {
  display: block;
  padding-top: 10px;
  padding-left: 10px;
  color: white;
}
.presentation-header-yellow .presentation-title a {
  margin-top: 100px;
  font-size: 0.8em;
  color: white;
}
.presentation-header-yellow .presentation-main-title {
  font-size: 1.3em;
  display: block;
  margin-left: 10px;
  color: #2c303b;
}

.yellowHover:hover {
  background-color: #96740e;
}

.presentation-body {
  background: #fff;
  padding-bottom: 10px;
  min-height: 150px;
  border-radius: 10px 10px 10px 10px;
}
.presentation-body .display-info {
  margin-top: 20px;
}

.presentation-body-light {
  background: #fff;
  padding-bottom: 10px;
  min-height: 100px;
  border-radius: 10px 10px 10px 10px;
}
.presentation-body-light .display-info {
  margin-top: 20px;
}

#userEdit,
#passwordChange {
  width: 30px;
  height: 30px;
  font-size: 0.6em;
  margin-left: 20px;
}

#map_canvas {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 100vh;
  margin: 0;
  padding: 0;
}

th .form-control {
  padding: 0 0 0 5px;
  font-size: 10px;
}

.presentation-container-map {
  width: 100%;
  padding-right: 20px;
  height: 340px;
  position: relative;
}

.presentation-container-map-double {
  width: 100%;
  padding-right: 20px;
  padding-top: 20px;
  height: 500px;
  position: relative;
}

.presentation-container-map-full-height {
  width: 100%;
  height: 100%;
  position: relative;
}

.presentation-container-map-meter {
  width: 100%;
  height: 100%;
  position: relative;
}

.presentation-container-map-location {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background: rgba(134, 134, 134, 0.23);
}

.presentation-container-map-meter {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background: rgba(134, 134, 134, 0.23);
  border-radius: 10px 10px 10px 10px;
}

.react-grid-placeholder {
  background-color: #31c6b3 !important;
}

.react-draggable-dragging {
  cursor: grab;
  cursor: move;
}

#nav-mini-users {
  margin-left: -5px;
}

.containerDashFullScreen {
  height: 100vh;
  background: #000;
  overflow: auto;
}
.containerDashFullScreen .presentation-header {
  margin-top: 15px;
}
.containerDashFullScreen .react-grid-item,
.containerDashFullScreen .widget-info-container {
  background: #ebecf1;
}

#addPermission svg {
  margin-top: -13px;
}

.lostPass {
  color: white;
}

.reset-login {
  color: white;
}
.reset-login .alert {
  width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: -15px;
}
.reset-login .btn {
  float: right;
  color: white;
}

.burger-user {
  margin-left: 20px;
  margin-top: -2px;
}
.burger-user .dropdown .btn,
.burger-user .dropdown .btn-secondary,
.burger-user .show .btn,
.burger-user .show .btn-secondary {
  background: #ebecf1;
  border: none;
  border-radius: 0px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
}
.burger-user .dropdown .btn:hover,
.burger-user .dropdown .btn-secondary:hover,
.burger-user .show .btn:hover,
.burger-user .show .btn-secondary:hover {
  background: #34827a;
  border: none;
  border-radius: 0px;
  border-radius: 20px;
  width: 30px;
  height: 30px;
}
.burger-user .dropdown btn:active,
.burger-user .dropdown .btn-secondary:active,
.burger-user .show btn:active,
.burger-user .show .btn-secondary:active {
  border: none;
}
.burger-user .dropdown .burger-user-icon,
.burger-user .show .burger-user-icon {
  margin-left: -6px;
}

button.danger {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.virtual-meter-info {
  margin-left: 0px;
  border-radius: 15px !important;
}
.virtual-meter-info .presentation-header {
  border-radius: 15px 15px 0 0 !important;
  background-position: left;
}
.virtual-meter-info .presentation-body {
  min-height: 65px;
  border-radius: 15px !important;
}
.virtual-meter-info .presentation-body form {
  margin-top: 15px;
}
.virtual-meter-info .btn-secondary {
  color: #fff;
  background-color: #31c6b3;
  border-color: #31c6b3;
  border-radius: 50px;
}

.wrapper-modal-addUser h5 {
  width: 70%;
  display: flex;
}

#adduser {
  margin-left: 15%;
}
#adduser .add {
  margin-top: -13px;
}

#addTourmalineUser .add {
  margin-top: -13px;
}

.modal-add-user {
  display: flex;
  width: 70%;
}

.virtual-meter-editor .react-bootstrap-table-pagination > div:not(.react-bootstrap-table-pagination-list) {
  width: 40% !important;
  flex: 0 0 40% !important;
}
.virtual-meter-editor .existing-meter-list .react-bootstrap-table-pagination > div:not(.react-bootstrap-table-pagination-list) {
  display: none;
}

.react-bootstrap-table input[type=checkbox]:disabled {
  display: none;
}
.react-bootstrap-table td input[type=checkbox]:enabled,
.react-bootstrap-table th .all-cb {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid grey;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px !important;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}
.react-bootstrap-table input[type=checkbox]:active,
.react-bootstrap-table input[type=checkbox]:checked:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.react-bootstrap-table input[type=checkbox]:checked {
  background-color: #e9ecee;
  border: 1px solid #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
.react-bootstrap-table input[type=checkbox]:checked:after {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 1.5px;
  line-height: 14px;
}
.react-bootstrap-table .checkbox-add input[type=checkbox]:checked:after,
.react-bootstrap-table .checkbox-add:checked:after {
  content: "✔";
  color: green;
}
.react-bootstrap-table .checkbox-remove input[type=checkbox]:checked:after,
.react-bootstrap-table .checkbox-remove:checked:after {
  content: "⤫";
  color: red;
}
.react-bootstrap-table .checkbox-add-all:checked:after {
  content: "―";
  color: green;
  font-weight: bold;
}
.react-bootstrap-table .checkbox-remove-all:checked:after {
  content: "―";
  color: red;
  left: 1px;
  font-weight: bold;
}

.widget-info {
  margin-left: 0px;
  border-radius: 15px !important;
}
.widget-info .presentation-header {
  border-radius: 10px !important;
  background-position: left;
}

.notification-count {
  background-color: red;
  color: white;
  height: 15px;
  width: 15px;
  border-radius: 25px;
  position: absolute;
  bottom: 5px;
  right: 25px;
  font-size: 10px;
  font-weight: bold;
}
.notification-count span {
  margin: auto;
}

.gap-tooltip {
  z-index: 1200;
}

.title-chart {
  margin-bottom: 25px;
}

.head-widget div {
  float: right;
}

.simple-container .fullscreen-button {
  position: absolute;
  top: 17px;
  right: 35px;
}

.modal .config-form label,
.modal .label-text {
  font-weight: bold;
}

.bold-text {
  font-weight: bold;
}

/* Search bar */
.TreeView > input[type=search] {
  width: 100%;
  height: 3em;
  font-style: italic;
  color: #808080;
  background: white;
  padding: 10px;
  border-radius: 7px;
  border: 0.5px solid #ccc;
  outline: none;
  margin-top: 10px;
}

.depth-0 li:first-child > .item {
  border-top: 0px;
}

.separator {
  height: 1em;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

/*
filter text-filter form-control dashboard-custom-text-filter
*/
/* Elements */
.TreeView {
  white-space: nowrap;
}

.item {
  min-height: 2.5em;
  vertical-align: middle;
  line-height: 2em;
  font-size: 1em;
  border-top: 1px solid #ccc;
  padding-left: 20px;
  padding-top: 5px;
}

li.category:not(.folded) {
  padding-bottom: 10px;
}

li.category:not(.folded) ul li:first-child > .item {
  border-top: 0px;
}

.opener {
  padding: 0 10px;
}

.category {
  vertical-align: middle;
}

.TreeView ul {
  list-style: none;
}

.TreeView li {
  min-width: 100px;
  transition: all 0.25s ease-in-out;
}

.TreeView ul li a {
  color: #222;
}

.TreeView ul li > .item > a {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 55px);
  margin-right: 30px;
  padding: 10px 5px;
  text-decoration: none;
  transition: all 0.25s;
}

.TreeView ul li:not(.disabled) {
  cursor: pointer;
}

.TreeView ul li.selected > .item > a {
  color: crimson;
}

.TreeView ul li.selected > .item > a:hover {
  color: #aaa;
}

.TreeView ul li:not(.disabled) > .item > a:hover {
  color: rgba(49, 198, 178, 0.7333333333);
}

/* Root elements */
.TreeView ul.depth-0 {
  padding: 20px 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.4);
  user-select: none;
  transition: all 0.25s;
}

/* Categories : Nodes with children */
.TreeView li.category > .item {
  display: block;
  padding-bottom: 5px;
  transition: all 0.25s ease-in-out;
}

.TreeView li.category:not(.folded) > .item {
  background-color: rgba(49, 198, 178, 0.7333333333);
  margin-bottom: 5px;
  border-radius: 7px;
  color: #fff;
  width: 100%;
  border-top: 0px;
}

/* Category opener */
.TreeView .opener {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  margin-right: 10px;
  cursor: pointer;
  float: right;
}

.TreeView .opener::after {
  content: ">";
  display: block;
  transition: all 0.25s;
}

.TreeView li.category.async > .item > .opener::after {
  content: "!";
}

.TreeView .opener:hover {
  color: rgba(49, 198, 178, 0.7333333333);
}

.TreeView li.category:not(.folded) > .item > .opener::after {
  color: white;
  transform: rotate(90deg);
}

.TreeView li.category.loading > .item > .opener::after {
  animation: spin 1s infinite;
}

/* Animations on fold / unfold */
.TreeViewTransition-enter {
  opacity: 0;
  transform: translateX(-20px) scale(0);
  transform-origin: top;
  transition: all 0.3s ease-in-out;
}

.TreeViewTransition-enter-active {
  opacity: 1;
  transform: translateX(0px) scale(1);
}

.TreeViewTransition-leave {
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}

.TreeViewTransition-leave-active {
  opacity: 0;
  transform: translateX(20px) scale(0);
}

.with-border {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
}

.with-border h3 {
  border-left: 3px solid #31c6b3;
  margin-left: -22px;
  padding-left: 20px;
  padding-bottom: 5px;
  color: #2c303b;
}

.identityChoices small {
  font-weight: bold;
}

.addObj {
  cursor: pointer;
}
.addObj .add {
  width: 1em;
  height: 1em;
}

#more_info {
  padding: 0px 5px 0px 5px;
  margin-left: 10px;
}

a:not([href]):not([tabindex])[class=display_lines] {
  cursor: pointer;
  font-weight: bold;
  color: #31c6b3;
  font-size: 12px;
  text-decoration: underline;
}

.help-next-step {
  color: #999;
  font-style: italic;
}

.sidebar-stock {
  background-color: #2c303b !important;
}

.google-suggest {
  width: 89%;
  margin-top: 2%;
  margin-left: 5%;
}

.google-suggest-pdi {
  width: 50%;
  margin-top: 2%;
  margin-left: 5%;
}

.sc-EHOje.hijnLS {
  margin-left: 5%;
  width: 50%;
}

.locationchoice {
  display: inline;
}

.index-title {
  font-size: 1.5em;
  color: black;
  padding: 10px;
  text-align: center;
  padding-bottom: 0px;
}

.locationchoicelabel {
  padding: 6px;
}

.locationchoice label {
  margin-right: 5%;
}

.btn-info {
  margin-left: 25%;
}

.table-info-container-create-pdi .row {
  margin-top: 5%;
  margin-left: 25%;
  display: inline;
}

.row .col-lg-6 {
  display: inline-block;
}

.modal-footer .btn.btn-primary button {
  visibility: hidden;
}

.create-location {
  display: block;
  width: 100%;
}

.create-pdi {
  width: 100%;
}

.col-lg-78 {
  margin-top: 5%;
  width: 100%;
}

.additif-label {
  display: flex;
}

.form-group-additif {
  width: 100%;
}

.form-group-create-pdi {
  display: inline-flex;
  width: 50%;
  margin-left: 5%;
  margin-top: 2%;
}

.form-group-complementaire {
  margin-left: 5%;
  width: 100%;
}

.row .col-lg-80 {
  display: inline-table;
  width: 30%;
}

.dropdown-toggle.nav-link::after {
  display: none;
}

.container-tab-synchro {
  width: 60%;
  margin-left: 20%;
}
.container-tab-synchro .table-info-container h2 svg {
  margin-right: 15px;
}
.container-tab-synchro .table-info-container .react-bootstrap-table .table th {
  width: 10%;
}
.container-tab-synchro .table-info-container .react-bootstrap-table .table td {
  width: 10%;
  text-align: left;
}

.extra-tab-synchro {
  display: inline-flex;
}
.extra-tab-synchro svg {
  width: 30px;
}

.sidebar-mask-alarm {
  width: 100%;
  height: 7em;
  border-radius: 15px;
  background-color: white;
  display: inline-flex;
  border: 0.5px solid #ccc;
}
.sidebar-mask-alarm .masque-alarm-side {
  display: flex;
  margin: auto;
  width: 20%;
}
.sidebar-mask-alarm .masque-alarm-side .svg {
  width: 15%;
  height: auto;
}
.sidebar-mask-alarm .svgAlarm {
  display: inline-table;
  width: 100%;
  margin: auto;
  table-layout: fixed;
}
.sidebar-mask-alarm .svgAlarm div {
  padding: 8px;
  margin-left: 5%;
  display: inline-table;
  margin-top: 8px;
  background-color: white;
}
.sidebar-mask-alarm .svgAlarm div:hover {
  background-color: #333333;
  background-size: 3em;
}
.sidebar-mask-alarm .svgAlarm div:hover svg {
  fill: #31c6b3;
}
.sidebar-mask-alarm h2 {
  color: #2c303b;
  margin: auto;
  padding-left: 5%;
}
.sidebar-mask-alarm .masque-vert-display {
  margin: auto;
  width: 70px;
  max-width: 50px;
  margin-right: -8%;
  overflow: hidden;
  vertical-align: middle;
}

.svg-table-title {
  margin: inherit;
}

#deployFilter {
  display: inline-flex;
}
#deployFilter h2 {
  margin: auto;
  padding-left: 5%;
  color: #2c303b;
}
#deployFilter svg {
  margin: auto;
}

.dropdown-toggle.nav-link::after {
  display: none;
}

.container-tab-synchro {
  width: 60%;
  margin-left: 20%;
}

.extra-tab-synchro {
  display: inline-flex;
}
.extra-tab-synchro svg {
  width: 30px;
}

.sidebar-mask-alarm {
  width: 100%;
  height: 7em;
  border-radius: 15px;
  background-color: white;
  display: inline-flex;
  border: 0.5px solid #ccc;
}
.sidebar-mask-alarm .masque-alarm-side {
  display: flex;
  margin: auto;
  width: 20%;
}
.sidebar-mask-alarm .masque-alarm-side .svg {
  width: 15%;
  height: auto;
}
.sidebar-mask-alarm .svgAlarm {
  display: inline-table;
  width: 100%;
  margin: auto;
  table-layout: fixed;
}
.sidebar-mask-alarm .svgAlarm div {
  padding: 8px;
  margin-left: 5%;
  display: inline-table;
  margin-top: 8px;
  background-color: white;
}
.sidebar-mask-alarm .svgAlarm div:hover {
  background-color: #333333;
  background-size: 3em;
}
.sidebar-mask-alarm .svgAlarm div:hover svg {
  fill: #31c6b3;
}
.sidebar-mask-alarm h2 {
  color: #2c303b;
  margin: auto;
  padding-left: 5%;
}
.sidebar-mask-alarm .masque-vert-display {
  margin: auto;
  width: 70px;
  max-width: 50px;
  margin-right: -8%;
  overflow: hidden;
  vertical-align: middle;
}

.svg-table-title {
  margin: inherit;
}

#deployFilter {
  display: inline-flex;
}
#deployFilter h2 {
  margin: auto;
  padding-left: 5%;
  color: #2c303b;
}
#deployFilter svg {
  margin: auto;
}

#deployColumns {
  display: inline-flex;
}
#deployColumns h2 {
  margin: auto;
  color: #2c303b;
  margin-left: 5px;
}
#deployColumns svg {
  margin: auto;
}

.infoLibelle-drag {
  color: #31c6b3;
}

.task-content-drag {
  height: 140px;
}
.task-content-drag li {
  list-style: none;
  color: black;
  font-size: 12px;
  margin-top: 5px;
}
.task-content-drag .chart-container {
  max-height: 75px;
  max-width: 150px;
  height: 75px;
  width: 150px;
  position: relative;
}

#column-drag {
  z-index: 1;
}
#column-drag .information-container-header {
  border-radius: 5px 5px 0 0;
  margin-left: 0;
}
#column-drag .information-container-header .header-drag-column {
  vertical-align: middle;
  display: inline-flex;
  width: 100%;
}
#column-drag .information-container-header .header-drag-column .title {
  width: 70%;
  display: flex;
}
#column-drag .information-container-header .header-drag-column .title svg {
  width: 20%;
}
#column-drag .information-container-header .header-drag-column .col-12 .presentation-container {
  background-color: transparent;
  margin-bottom: 0;
}
#column-drag .information-container-header .header-drag-column .flex-box {
  width: 100%;
  display: inline-flex;
}
#column-drag .information-container-header .header-drag-column h3 {
  color: white;
  margin: auto;
  margin-left: 20px;
}
#column-drag .information-container {
  padding-top: 15px;
  border-radius: 0 0 5px 5px;
  padding-bottom: 0px;
  max-height: 590px;
  max-width: 100%;
  margin-left: 0;
  border: 1px solid lightgrey;
  border-top: none;
}
#column-drag .information-container .Wrapper {
  overflow-x: hidden;
}

.drag-associate-label {
  display: flex;
  flex-wrap: wrap;
}
.drag-associate-label .row {
  color: black;
}

.synchro-ul-hover {
  text-align: left;
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}

#drag-label-container-W {
  padding: 2px;
}
#drag-label-container-W .title-drag-W {
  white-space: nowrap;
  margin: 0 -5px 0 10px;
  margin-top: 2px;
  color: black;
  width: auto;
  align-self: center;
}
#drag-label-container-W svg {
  margin: auto;
  margin-left: 8px;
}

#import-silex-button::after {
  content: "Valider" !important;
}

#drag-label-container-A {
  padding: 2px;
  background-color: white;
  width: max-content;
}
#drag-label-container-A .title-drag-W {
  margin-left: 20%;
  white-space: nowrap;
}
#drag-label-container-A svg {
  margin-left: auto;
}

.waiting-action-drag {
  margin-top: 7px;
}
.waiting-action-drag b {
  color: black;
}

.waiting-action-label {
  overflow: auto;
  display: flex;
  width: 100%;
}

.button-valid-drag {
  background: lightgrey;
  color: black;
  border-color: lightgrey;
  width: 50%;
  height: 20%;
}
.button-valid-drag:hover {
  color: white;
  background-color: #31c6b3;
}

.waiting-action-button {
  width: 100%;
  font-size: 15px;
  display: flex;
}
.waiting-action-button .button-valid-drag {
  box-shadow: none;
  transition: none;
  -webkit-transition: none;
}

.drag-and-drop {
  width: 100%;
  display: flex;
  overflow: hidden;
}
.drag-and-drop .presentation-header {
  display: none;
}
.drag-and-drop .clearfix {
  display: none;
}
.drag-and-drop .presentation-body {
  display: inline-flex;
  width: 100%;
  margin-left: 20px;
  padding-bottom: 0;
}
.drag-and-drop .presentation-body .drag-and-drop-search {
  display: inline-flex;
  margin: auto;
  text-align: center;
}
.drag-and-drop .presentation-body .drag-and-drop-search h3 {
  margin: auto;
  margin-left: 15px;
  color: black;
}
.drag-and-drop .presentation-body .drag-and-drop-search svg {
  color: #31c6b3;
}

.header-item .nav-link {
  color: #808080;
}
.header-item .nav-active {
  color: #31c6b3;
}

.logo-sidebar-synchronisation {
  width: 260px;
  background: white;
  height: 90px;
  padding-top: 10px;
}

.CrystalMainHeader {
  position: fixed;
  width: calc(100% - 260px);
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 0;
}
.CrystalMainHeader.synchronisation {
  width: 100%;
}
.CrystalMainHeader.synchronisation .logo-sidebar {
  margin: 0;
}
.CrystalMainHeader.synchronisation .flex-box {
  width: 100%;
}
.CrystalMainHeader .CrystalBreadCrumb {
  width: 50%;
  display: flex;
}
.CrystalMainHeader #head-navbar {
  width: 50%;
}

.logo-sidebar-synchronisation {
  position: fixed;
  top: 0;
  width: 260px;
  margin: 0 auto;
  background: white;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 300;
  height: 90px;
  padding-top: 10px;
}

.fullContainer {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.fullContainer .over {
  padding-left: 8px;
}

.containerSynchro {
  width: 100%;
  padding-left: 260px;
}
.containerSynchro .over {
  padding-left: 8px;
}
.containerSynchro .react-chat-widget {
  position: fixed;
  bottom: 1vh;
  right: 1vh;
  z-index: 1000;
}

.containerTournees {
  min-width: calc(100% - 320px);
  margin-left: 320px;
  overflow: hidden;
}
.containerTournees .over {
  padding-left: 8px;
}

.container-tab-synchro {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
}

.extra-tab-synchro {
  display: inline-flex;
}
.extra-tab-synchro svg {
  width: 30px;
}

section.synchro {
  padding-top: 100px;
}

section.tournee {
  padding-top: 180px;
}

.title-drag-A {
  padding-left: 10px;
  margin: auto;
}

.title-drag-A:hover {
  cursor: pointer;
}

.title-drag-W {
  margin-left: 10%;
  margin: auto;
}

.synchro-button-wrapper {
  margin-top: 5%;
  padding-bottom: 5%;
}
.synchro-button-wrapper .synchro-button-pointer:hover {
  cursor: pointer;
}

.droppable-zone {
  border-style: dotted;
}

.task-content-wrapper {
  width: 100%;
}

.extra-content-wrapper {
  width: 10%;
}

.placeholder-synchro {
  border: 1px solid #31c6b3;
  border-radius: 0 0 10px 10px;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: #279d8e 0 0 5px;
  background-color: white;
  padding: 3px 20px 3px 20px;
  border-top: none;
}

.display-info {
  width: 100%;
  background-color: transparent;
}

.display-info .infoLibelle-drag {
  width: 100% !important;
  border: 1px solid;
  border-radius: 10px 10px 0 0;
  display: inline-flex;
  background-color: white;
}
.display-info .infoLibelle-drag.multi {
  background: red;
  position: relative;
}
.display-info .infoLibelle-drag.multi, .display-info .infoLibelle-drag.multi::before, .display-info .infoLibelle-drag.multi::after {
  /* Styles to distinguish sheets from one another */
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border: 1px solid #bbb;
}
.display-info .infoLibelle-drag.multi::before, .display-info .infoLibelle-drag.multi::after {
  content: "";
  position: absolute;
  height: 95%;
  width: 99%;
  background-color: #fff;
  border: 1px solid #31c6b3;
  border-radius: 10px;
}
.display-info .infoLibelle-drag.multi::before {
  right: 15px;
  top: 0;
  transform: rotate(-3deg);
  z-index: -1;
}
.display-info .infoLibelle-drag.multi::after {
  top: 5px;
  right: -5px;
  transform: rotate(3deg);
  z-index: -2;
}
.display-info .infoLibelle-drag .draggable-content-wrapper {
  width: 100%;
  display: inline-flex;
}
.display-info .infoLibelle-drag .draggable-content-wrapper h3 {
  margin-bottom: 3px;
}
.display-info .infoLibelle-drag .draggable-content-wrapper .draggable-icone-format {
  display: table-cell;
  height: 15px;
  margin: auto;
  margin-right: 8px;
  margin-left: 4px;
}
.display-info .infoLibelle-drag .draggable-content-wrapper .draggable-icone-format ul {
  padding-left: 0;
}
.display-info .infoLibelle-drag .draggable-content-wrapper .draggable-icone-format li {
  list-style: none;
}
.display-info .infoLibelle-drag .draggable-content-wrapper .draggable-icone-format .draggable-icone-row {
  display: flex;
  margin-bottom: 2px;
}
.display-info .infoLibelle-drag .draggable-content-wrapper .draggable-icone-format .draggable-icone-row .drag-point {
  margin: auto;
  height: 3px;
  width: 3px;
  border-radius: 1.5px;
  background-color: #34827a;
  margin: 0 1px 0 1px;
}

.delete-round-preview-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.delete-round-preview {
  border: 2px solid red;
  border-radius: 100px;
  padding: 0 5px;
  color: red;
  margin: 5px 2px;
}

.delete-round-preview-not-allowed {
  border: 2px solid lightgray;
  background-color: lightgray;
  border-radius: 100px;
  padding: 0 10px;
  color: gray;
  margin: 5px;
}

.delete-round-preview-undeletable {
  border: 2px solid red;
  border-radius: 100px;
  background-color: red;
  padding: 0 5px;
  color: white;
  font-weight: bold;
  margin: 5px 2px;
}

.synchroSwitch {
  vertical-align: text-top;
}
.synchroSwitch .react-switch-bg {
  height: 15px !important;
  width: 30px !important;
  margin-top: 21% !important;
}
.synchroSwitch .react-switch-handle {
  margin-left: -12px !important;
}

.synchro-column input::after {
  background-color: green;
}

.synchro-column .checkbox-synchro:checked ~ .display-info::before {
  background-color: green !important;
}

.drag-clone-wrapper {
  width: fit-content;
  width: -moz-fit-content;
  min-width: 200px;
  height: max-content;
  min-height: 80px;
  border-radius: 10px;
  margin: auto;
}
.drag-clone-wrapper span {
  font-size: 13px;
  color: white;
}
.drag-clone-wrapper h5 {
  font-size: 18px;
  color: #31c6b3;
  margin-left: 15px;
}

.form-check {
  background-color: transparent;
}

.provided-drop {
  margin-left: 150px;
}

.header-drag-clone {
  display: flex;
  margin-left: 10px;
}

.synchro-task-header {
  margin-top: 5px;
}

.progress-bar-wrapper {
  border: 2px dashed #777;
}

.checkbox-synchro .custom-checkbox .custom-control {
  margin-top: 100%;
}

.synchro-tab-reduc:hover {
  cursor: pointer;
}

.sidebar-mask-alarm {
  width: 100%;
  height: 7em;
  border-radius: 15px;
  background-color: white;
  display: inline-flex;
  border: 0.5px solid #ccc;
  margin-bottom: 10px;
}
.sidebar-mask-alarm .masque-alarm-side {
  display: flex;
  margin: auto;
  width: 20%;
}
.sidebar-mask-alarm .masque-alarm-side .svg {
  width: 15%;
  height: auto;
}
.sidebar-mask-alarm .svgAlarm {
  display: inline-table;
  width: 100%;
  margin: auto;
  table-layout: fixed;
}
.sidebar-mask-alarm .svgAlarm div {
  padding: 8px;
  margin-left: 5%;
  display: inline-table;
  margin-top: 8px;
  background-color: white;
}
.sidebar-mask-alarm .svgAlarm div:hover {
  background-color: #333333;
  background-size: 3em;
}
.sidebar-mask-alarm .svgAlarm div:hover svg {
  fill: #31c6b3;
}
.sidebar-mask-alarm h2 {
  color: #2c303b;
  margin: auto;
  padding-left: 5%;
}
.sidebar-mask-alarm .masque-vert-display {
  margin: auto;
  width: 70px;
  max-width: 50px;
  margin-right: -8%;
  overflow: hidden;
  vertical-align: middle;
}

.svg-table-title {
  margin: inherit;
}

#deployFilter {
  display: inline-flex;
  margin-left: -15px;
  width: 10.7%;
}
#deployFilter h2 {
  margin: auto;
  padding-left: 5%;
  color: #2c303b;
}
#deployFilter svg {
  margin: auto;
}

.best-date-component-mask {
  width: 100%;
  display: flex;
  margin-left: 5%;
}
.best-date-component-mask h5 {
  color: black;
}
.best-date-component-mask .flex-box {
  display: inline-flex;
}
.best-date-component-mask .c32 {
  margin-right: 2%;
  width: 30%;
  margin-bottom: 0;
  align-self: center;
}
.best-date-component-mask .c32 .text-content {
  text-align: center;
  width: fit-content;
  width: -moz-fit-content;
  align-self: center;
  white-space: nowrap;
  height: 50%;
}
.best-date-component-mask .c32 .text-content h5 {
  border: 1px solid lightgrey;
  color: black;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
}

.sidebar-stock {
  background-color: #333333 !important;
}

.button-all-associate {
  margin-left: 15px;
}

.export-pdi-content {
  color: black;
  width: 100%;
  margin-left: 0;
}
.export-pdi-content .filter-content-header {
  display: flex;
}
.export-pdi-content .filter-container {
  padding: 10px;
}
.export-pdi-content .filter-container .react-bootstrap-table {
  padding: 5px;
}
.export-pdi-content .filter-container .react-bootstrap-table .table {
  border-bottom: 1px solid lightgray;
}
.export-pdi-content .filter-container .react-bootstrap-table th {
  border: none;
}
.export-pdi-content .filter-container .flexbox {
  display: flex;
}
.export-pdi-content .filter-container .flexbox .title {
  display: flex;
  padding: 5px;
}
.export-pdi-content .filter-container .flexbox .title h3 {
  margin-left: 10px;
}
.export-pdi-content .presentation-header {
  display: none;
}
.export-pdi-content .presentation-body .drag-and-drop-search {
  display: flex;
  margin: auto;
  padding: 10px;
  margin-top: 5px;
}
.export-pdi-content .presentation-body .drag-and-drop-search h3 {
  margin: 4px 0px;
  margin-left: 10px;
}

.row-meter-bar {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
}
.row-meter-bar .col-lg-4 {
  padding: 0;
}
.row-meter-bar .col-lg-8 {
  padding-right: 0;
}
.row-meter-bar .col-lg-8 .container {
  padding-right: 0;
}
.row-meter-bar th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clean-meter-bar {
  margin-bottom: 2vh;
  width: 100%;
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background-color: white;
  color: black;
}
@media screen and (max-width: 1460px) {
  .clean-meter-bar h3 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .clean-meter-bar h3 {
    font-size: 12px;
  }
}
.clean-meter-bar .flex-box {
  width: 100%;
  display: flex;
  align-self: center;
}
.clean-meter-bar .c20 {
  width: 16.5%;
  height: 60%;
}
.clean-meter-bar .c20 svg {
  margin-right: 1vw;
  align-self: center;
}
.clean-meter-bar .c20.background-texture {
  background: url(./_svgs/background_green.svg);
  background-size: 800px;
}
.clean-meter-bar .c20.background-texture-yellow {
  background: url(./_svgs/background_yellow.svg);
  background-size: 800px;
}
.clean-meter-bar .c20.left {
  border-radius: 10px 0 0 10px;
  padding: 15px;
  height: auto;
  text-align-last: center;
  display: grid;
  align-content: center;
}
.clean-meter-bar .c20.left span {
  color: white;
}
.clean-meter-bar .c20.right {
  border-radius: 0 10px 10px 0;
  margin: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clean-meter-bar .c20.b-right {
  border-right: 1px solid lightgrey;
  margin: auto 0;
}
.clean-meter-bar .c20 .flex-box {
  display: flex;
}
.clean-meter-bar .c20 .flex-box.center {
  justify-content: center;
  align-items: center;
}
.clean-meter-bar .c20 .centerAlign {
  align-self: center;
}
.clean-meter-bar .c50 {
  width: 50%;
}
.clean-meter-bar .c50 svg {
  margin-right: 1vw;
}
.clean-meter-bar .c50.background-texture {
  background: url(./_svgs/background_green.svg);
  background-size: 800px;
}
.clean-meter-bar .c50.left {
  border-radius: 10px 0 0 10px;
  padding: 15px;
}
.clean-meter-bar .c50.left span {
  color: white;
}
.clean-meter-bar .c50.right {
  border-radius: 0 10px 10px 0;
  padding: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clean-meter-bar .c50.b-right {
  border-right: 1px solid lightgrey;
  margin: auto 0;
}
.clean-meter-bar .c50 .flex-box {
  display: flex;
}
.clean-meter-bar .c50 .flex-box.center {
  justify-content: center;
  align-items: center;
}
.clean-meter-bar .c50 .centerAlign {
  align-self: center;
}
.clean-meter-bar .c33 {
  width: 33%;
}
.clean-meter-bar .c33 svg {
  margin-right: 1vw;
}
.clean-meter-bar .c33.background-texture {
  background: url(./_svgs/background_green.svg);
  background-size: 800px;
}
.clean-meter-bar .c33.left {
  border-radius: 10px 0 0 10px;
  padding: 15px;
}
.clean-meter-bar .c33.left span {
  color: white;
}
.clean-meter-bar .c33.right {
  border-radius: 0 10px 10px 0;
  padding: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.clean-meter-bar .c33.b-right {
  border-right: 1px solid lightgrey;
  margin: auto 0;
}
.clean-meter-bar .c33 .flex-box {
  display: flex;
}
.clean-meter-bar .c33 .flex-box.center {
  justify-content: center;
  align-items: center;
}
.clean-meter-bar .c33 .centerAlign {
  align-self: center;
}
.clean-meter-bar .linkpdi {
  margin: auto;
}

.fixed {
  position: fixed;
}

.custom-file-label::after {
  width: 40px;
  background-size: 15px;
  content: url("./_svgs/loupe_bleu.svg") !important;
}

.fichePdi {
  padding: 0 10px;
  min-width: 20em;
}
.fichePdi .presentation-container {
  margin-left: 0;
}
.fichePdi .table-info-container {
  border: none;
}
.fichePdi .generateMeter-pdi {
  border: 1px solid lightgrey;
}
.fichePdi .generateMeter-pdi th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (max-height: 1080px) {
  .fichePdi .chart-container {
    height: 600px;
  }
  .fichePdi .chart-container.alarm {
    height: 300px;
  }
  .fichePdi .chart-container canvas {
    position: relative;
    max-height: 100%;
  }
}
@media screen and (max-height: 820px) {
  .fichePdi .chart-container {
    height: 400px;
  }
  .fichePdi .chart-container canvas {
    position: relative;
    max-height: 100%;
  }
}
@media screen and (max-height: 620px) {
  .fichePdi .chart-container {
    height: 300px;
  }
  .fichePdi .chart-container canvas {
    position: relative;
    max-height: 100%;
  }
}
.fichePdi .second-date-component {
  z-index: 10;
  background-color: white;
  border: 1px solid lightgrey;
  width: 100%;
  margin-bottom: 2%;
  border-radius: 10px;
  padding: 10px 0;
}
@media screen and (max-width: 1460px) {
  .fichePdi .second-date-component h3 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .fichePdi .second-date-component h3 {
    font-size: 12px;
  }
}
.fichePdi .second-date-component .title-date {
  align-self: center;
  padding: 15px;
  width: 25%;
  display: flex;
  margin-right: 6%;
}
.fichePdi .second-date-component .title-date svg {
  margin-right: 40px;
  padding-left: 15px;
}
.fichePdi .second-date-component .title-date h3 {
  color: black;
  margin-bottom: 0;
  white-space: nowrap;
}
.fichePdi .second-date-component .best-date-component {
  padding: 0;
}
.fichePdi .second-date-component .best-date-component .c32 {
  width: 25%;
  margin-bottom: 0;
}
.fichePdi .bi-container {
  border-radius: 10px;
  background-color: white;
  border: 1px solid lightgrey;
  margin-left: 0;
  height: 100%;
}
.fichePdi .own-container {
  border-radius: 10px;
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 2%;
}
.fichePdi .flex-box {
  display: flex;
  width: 100%;
}
.fichePdi .flex-box.infinite-box {
  overflow-x: scroll;
  justify-content: center;
}
.fichePdi .flex-box-pdi {
  display: flex;
  width: 100%;
  margin-bottom: 2%;
}
.fichePdi .flex-box-pdi .map-container {
  display: grid;
  height: 100%;
}
.fichePdi .flex-box-pdi .map-container .add-coordinates-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.fichePdi .flex-box-pdi .map-container .add-coordinates-container .flex-box {
  justify-content: center;
  align-items: center;
}
.fichePdi .flex-box-pdi .map-container .add-coordinates-container .flex-box h2 {
  margin: 0;
}
.fichePdi .info-pdi {
  width: 35%;
  margin-right: 4%;
  height: fit-content;
}
.fichePdi .info-pdi .ref-intervention {
  width: 100%;
}
.fichePdi .info-pdi .ref-intervention .ref-pdi {
  background-color: white;
  color: black;
  border-radius: 10px;
  border: 1px solid lightgrey;
  text-align: center;
  margin-left: 0;
}
.fichePdi .info-pdi .ref-intervention .ref-pdi .flex-box span {
  width: 33%;
}
.fichePdi .info-pdi .ref-intervention .ref-pdi .flex-box .custom-switch {
  width: 33%;
  text-align: center;
  margin-left: 1ch;
}
.fichePdi .info-pdi .presentation-container {
  margin-bottom: 4vh;
}
.fichePdi .info-pdi .smooth-border {
  border-radius: 10px;
}
.fichePdi .info-pdi .ref-pdi {
  margin-left: 7.5%;
}
.fichePdi .info-pdi .ref-pdi .form-group {
  margin-bottom: 0;
  padding: 10px 0;
}
.fichePdi .info-pdi .ref-pdi span {
  margin-left: 0;
  padding-left: 0;
}
.fichePdi .info-pdi .presentation-body p {
  margin: 20px 7.5%;
}
.fichePdi .map-pdi {
  width: 65%;
}
.fichePdi .map-pdi .presentation-container-map div {
  border-radius: 10px;
}
.fichePdi .map-pdi .ajout-gps {
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  height: 3em;
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  cursor: pointer;
}
.fichePdi .map-pdi .ajout-gps svg {
  fill: #31c6b3;
}
.fichePdi .map-pdi .ajout-gps:hover {
  background-color: #34827a;
}
.fichePdi .map-pdi .ajout-gps:hover svg {
  fill: white;
}
.fichePdi .last-read-pdi-container {
  height: 20%;
}
.fichePdi .last-read-pdi-container h3 {
  color: black;
  margin-bottom: 0;
}
@media screen and (max-width: 1460px) {
  .fichePdi .last-read-pdi-container h3 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .fichePdi .last-read-pdi-container h3 {
    font-size: 12px;
  }
}
.fichePdi .last-read-pdi-container .c25 {
  width: 25%;
  padding: 15px;
}
.fichePdi .last-read-pdi-container .c25.flex-box {
  align-self: center;
}
.fichePdi .last-read-pdi-container .c25 svg {
  padding-right: 30px;
  padding-left: 15px;
}
.fichePdi .last-read-pdi-container .c20 {
  width: 20%;
  padding: 15px;
}
.fichePdi .last-read-pdi-container .c20.flex-box {
  align-self: center;
}
.fichePdi .last-read-pdi-container .c20 svg {
  padding-right: 30px;
  padding-left: 15px;
}
.fichePdi .last-read-pdi-container .c50 {
  width: 50%;
  padding: 15px;
}
.fichePdi .last-read-pdi-container .c50.flex-box {
  align-self: center;
}
.fichePdi .last-read-pdi-container .c50 svg {
  padding-right: 30px;
  padding-left: 15px;
}
.fichePdi .last-read-pdi-container .c33 {
  width: 33%;
  padding: 15px;
}
.fichePdi .last-read-pdi-container .c33.flex-box {
  align-self: center;
}
.fichePdi .last-read-pdi-container .c33 svg {
  padding-right: 30px;
  padding-left: 15px;
}
.fichePdi .last-read-pdi-container .c15 {
  width: 15%;
  padding: 15px;
}
.fichePdi .last-read-pdi-container .c10 {
  width: 15%;
  padding: 15px;
}
.fichePdi .last-read-pdi-container .conso-container .center-content {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.fichePdi .last-read-pdi-container .conso-carousel {
  background: none;
  position: relative;
  padding: 0;
}
.fichePdi .last-read-pdi-container .conso-carousel p {
  margin: 0;
  font-size: 20px;
  color: #31c6b3;
}
.fichePdi .last-read-pdi-container .conso-carousel p:hover {
  cursor: pointer;
}
.fichePdi .last-read-pdi-container .conso-carousel button {
  border: none;
  background: none;
  outline: none;
  transition: 0.3s;
}
.fichePdi .last-read-pdi-container .conso-carousel button:hover {
  transition: 0.3s;
  scale: 1.3;
}
.fichePdi .last-read-pdi-container .conso-carousel button svg {
  padding: 0;
  scale: 1.3;
  color: #31c6b3;
}
.fichePdi .last-read-pdi-container .conso-carousel .MuiMobileStepper-dotActive {
  background-color: #31c6b3;
  scale: 1.3;
}
@media (max-width: 1450px) {
  .fichePdi #triangle {
    display: none;
  }
}
.fichePdi .last-read-pdi-container .horizontal-line {
  height: 40% !important;
}
.fichePdi .last-read-pdi-container .conso-display {
  height: 175px;
}
.fichePdi .conso-display {
  display: flex;
  flex-direction: row;
  color: #2e3643;
}
.fichePdi .conso-display .previousUnavailable {
  margin: 0 auto;
  font-size: 2em;
}
.fichePdi .conso-display .noFilled {
  color: #2e3643;
  opacity: 0.3;
  font-weight: bold;
  font-size: 1.5em;
}
.fichePdi .conso-display .real-prev-switch {
  margin: 0 10px;
  width: 40px !important;
  border-radius: 100px;
  cursor: pointer;
  box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.25);
}
.fichePdi .conso-display .real-prev-switch i {
  background: white;
  display: flex;
  justify-content: center;
  margin: 1px;
  align-items: center;
  width: fit-content;
  border-radius: 100px;
  position: relative;
}
.fichePdi .conso-display .real-prev-switch svg {
  height: 20px;
  width: 20px;
}
.fichePdi .conso-display .real-switch-on {
  transition: 0.2s;
  background: #ffc107;
  color: #ffc107;
}
.fichePdi .conso-display .real-switch-on i {
  left: 19px;
  animation: prev-switch-rollon 0.1s linear normal;
}
.fichePdi .conso-display .real-switch-off {
  background: #34827a;
  color: #31c6b3;
  transition: 0.2s;
}
.fichePdi .conso-display .real-switch-off i {
  left: 0;
  animation: prev-switch-rolloff 0.1s linear reverse;
}
.fichePdi .conso-display #triangle {
  position: relative;
  height: 40px;
  width: 40px;
  margin: 0;
  transform: rotate(45deg);
}
.fichePdi .conso-display .previous,
.fichePdi .conso-display .current {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 25px;
}
.fichePdi .conso-display .previous h3,
.fichePdi .conso-display .current h3 {
  font-size: 1.1rem;
  color: #2e3643;
}
@media (max-width: 1220px) {
  .fichePdi .conso-display .current h3,
  .fichePdi .conso-display .previous h3 {
    font-size: 0.8rem;
  }
}
.fichePdi .conso-display .previous {
  background-color: #8ebdb7;
}
.fichePdi .conso-display .current {
  border-radius: 0 10px 10px 0;
}
.fichePdi .conso-display .average {
  width: 30%;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  text-align: center;
}
.fichePdi .conso-display .average svg {
  height: 25px;
  fill: #31c6b3;
}
.fichePdi .conso-display .average .start-arrow {
  background: #8ebdb7;
  right: 20px;
}
.fichePdi .conso-display .average .end-arrow {
  background: white;
  left: 20px;
}
.fichePdi .conso-display .average h3 {
  color: #2e3643;
}
.fichePdi .conso-display .telereleve-radio {
  background: #91e0f2 !important;
}
.fichePdi .conso-display .manuelle {
  background: #26a653 !important;
  color: white;
}
.fichePdi .conso-display .manuelle h3 {
  filter: invert(1) grayscale(1) contrast(9) !important;
}
.fichePdi .conso-display .manuelle svg {
  fill: white !important;
}
.fichePdi .conso-display .unknown-method {
  background: #31c6b3 !important;
}
.fichePdi .conso-display .content {
  display: flex;
  width: max-content;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-evenly;
}
.fichePdi .conso-display .content div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fichePdi .conso-display .content div:nth-child(odd) {
  height: 35%;
}
.fichePdi .conso-display .last-read-pdi-container .content div {
  justify-content: space-evenly;
}
.fichePdi .conso-display .horizontal-line {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2%;
}
.fichePdi .conso-display .horizontal-line .noFilled {
  font-size: 0.9rem;
}
.fichePdi .conso-display .horizontal-line div {
  width: 50%;
}
@keyframes prev-switch-rollon {
  from {
    left: 0;
  }
  to {
    left: 19px;
  }
}
.fichePdi .block-pdi-container {
  padding: 30px;
  position: relative;
}
.fichePdi .block-pdi-container .chart-alarm-container {
  width: 100%;
}
.fichePdi .block-pdi-container .chart-alarm-container.absolute {
  position: absolute;
  bottom: 90px;
  left: 0;
}
.fichePdi .block-pdi-container .alarm-item-selector {
  position: absolute;
  bottom: 40px;
}
.fichePdi .block-pdi-container .alarm-item-selector {
  display: flex;
  width: 100%;
}
.fichePdi .block-pdi-container .alarm-item-selector h5 {
  white-space: nowrap;
}
.fichePdi .block-pdi-container .alarm-item-selector .alarm-item {
  width: 10%;
  text-align: center;
}
.fichePdi .block-pdi-container .alarm-item-selector svg {
  padding: 0;
  fill: #31c6b3;
}
.fichePdi .block-pdi-container .alarm-selector {
  justify-content: center;
  width: 100%;
  display: flex;
  margin-bottom: 3vh;
}
.fichePdi .block-pdi-container .alarm-selector .selector-item {
  border: 1px solid #31c6b3;
  padding: 5px 10px;
  text-align: center;
  width: 11vw;
}
.fichePdi .block-pdi-container .alarm-selector .selector-item h3 {
  margin-bottom: 0;
  white-space: nowrap;
}
.fichePdi .block-pdi-container .button-display-meter {
  background-color: #31c6b3;
  border-radius: 50px;
  border: none;
  white-space: nowrap;
  margin: 3vh 50% 3vh calc(50% - 80px);
}
.fichePdi .block-pdi-container h3 {
  color: black;
}
.fichePdi .block-pdi-container svg {
  padding-right: 30px;
}
.fichePdi .block-pdi-container .c32 {
  width: 32%;
}
.fichePdi .block-pdi-container .c32.margTop {
  margin-top: 2vh;
}
.fichePdi .block-pdi-container .table-info-container .nav-link {
  color: black;
}
.fichePdi .block-pdi-container .table-info-container svg {
  padding-right: 0;
}
.fichePdi .block-pdi-container .button-wrapper {
  margin: 15px 0;
}
.fichePdi .block-pdi-container .button-wrapper button {
  background-color: #31c6b3;
  border-radius: 50px;
  border: none;
  margin-right: 10px;
}
.fichePdi .block-pdi-container .old-meter {
  background-color: lightgrey;
}
.fichePdi .block-pdi-container .old-meter h3 {
  padding: 10px;
}
.fichePdi .second-date-component .flex-box .title-date {
  width: 20%;
  align-self: auto;
  align-items: center;
  margin-right: 0;
  border-right: 1px solid lightgrey;
  padding: 0 2%;
}
.fichePdi .second-date-component .conso-display {
  width: 100%;
  padding-top: 10px;
}
.fichePdi .second-date-component .conso-display .horizontal-line {
  justify-content: center;
}
.fichePdi .second-date-component .conso-display .horizontal-line div {
  text-align: center;
}
.fichePdi .second-date-component .conso-display .horizontal-line h3 {
  margin: 0;
}
.fichePdi .second-date-component .conso-display .horizontal-line .noFilled {
  font-size: 0.8rem;
  margin: 0 auto;
}
.fichePdi .second-date-component .conso-display .previous,
.fichePdi .second-date-component .conso-display .current {
  width: 30%;
  min-height: 80px;
}
.fichePdi .second-date-component .conso-display .current {
  border-radius: 0 0 9px 0;
}
.fichePdi .second-date-component .conso-display .average {
  min-width: 40%;
  align-items: center;
}
.fichePdi .second-date-component .conso-display .average .content {
  flex-direction: row;
  width: 80%;
  height: fit-content;
}
.fichePdi #fixed-date-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}
.fichePdi #fixed-date-component .title-date {
  width: 20%;
  align-self: auto;
  align-items: center;
  margin-right: 0;
  border-right: 1px solid lightgrey;
  padding: 0 2%;
}
@media (max-width: 1730px) {
  .fichePdi #fixed-date-component .title-date h3 {
    font-size: 20px;
  }
}
@media (max-width: 1575px) {
  .fichePdi #fixed-date-component .title-date h3 {
    font-size: 15px;
  }
}
@media (max-width: 1300px) {
  .fichePdi #fixed-date-component .title-date h3 {
    font-size: 13px;
  }
}
@media (max-width: 1150px) {
  .fichePdi #fixed-date-component .title-date h3 {
    font-size: 10px;
  }
}
.fichePdi #fixed-date-component .best-date-component {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}
.fichePdi #fixed-date-component .svg-container svg {
  margin-right: 10px;
  padding-left: 0;
}
.best-date-component {
  padding: 20px 0;
  width: 100%;
}
.best-date-component .flex-box {
  display: flex;
}
.best-date-component .c32 {
  margin-right: 2%;
}

.chart-wrapper {
  display: flex;
  justify-content: center;
}

.old-meter-bar {
  display: flex;
}
.old-meter-bar .c15 {
  width: 15%;
  border-bottom: 1px solid lightgrey;
  margin-right: 3vw;
  margin-top: 2vh;
}
.old-meter-bar .c15 h5 {
  color: black;
  font-size: 13px;
  margin-bottom: 3vh;
}
.old-meter-bar .c15 .pad10 {
  padding: 10px;
}

.pdi-alarm {
  width: 100%;
}
.pdi-alarm .c100 {
  padding: 20px 0;
  width: 60%;
}
.pdi-alarm .c100 svg {
  padding-right: 0;
}

.old-meter-displayer {
  padding: 0;
  padding-top: 0;
}
.old-meter-displayer .row-meter-bar {
  text-align: left;
}

.pdi-old-meter .btn-group {
  width: 100%;
  margin: 0 30px;
  height: 60px;
  overflow-x: scroll;
}
.pdi-old-meter h3 {
  align-self: center;
}
.pdi-old-meter h4 {
  align-self: center;
  display: contents;
}
.pdi-old-meter .flex-box {
  padding: 0 30px;
  justify-content: center;
}

.export-pdi-content {
  color: black;
  width: 100%;
  margin-left: 0;
}
.export-pdi-content .filter-content-header {
  display: flex;
}
.export-pdi-content .filter-container {
  padding: 10px;
}
.export-pdi-content .filter-container .react-bootstrap-table {
  padding: 5px;
}
.export-pdi-content .filter-container .react-bootstrap-table .table {
  border-bottom: 1px solid lightgray;
}
.export-pdi-content .filter-container .react-bootstrap-table th {
  border: none;
}
.export-pdi-content .filter-container .flexbox {
  display: flex;
}
.export-pdi-content .filter-container .flexbox .title {
  display: flex;
  padding: 5px;
}
.export-pdi-content .filter-container .flexbox .title h3 {
  margin-left: 10px;
}
.export-pdi-content .presentation-header {
  display: none;
}
.export-pdi-content .presentation-body .drag-and-drop-search {
  display: flex;
  margin: auto;
  padding: 10px;
  margin-top: 5px;
}
.export-pdi-content .presentation-body .drag-and-drop-search h3 {
  margin: 4px 0px;
  margin-left: 10px;
}

#deleteParentSite {
  margin-left: 0;
}

.synchro-gestionnaire li {
  border-bottom: 1px solid #ccc;
}
.synchro-gestionnaire span {
  border-top: 0;
}

.sidebar-synchro li svg {
  margin-right: 5px;
}

.synchro-batterie,
.battery-component {
  padding: 0px 20px 0px 20px;
}
.synchro-batterie svg,
.battery-component svg {
  transform: rotate(180deg);
}

.synchro-date {
  width: 50%;
  padding: 5px 5px 5px 5px;
  font-size: 15px;
}
.synchro-date .synchro-date-box {
  color: black;
  align-items: center;
  justify-content: center;
}

.synchro-batterie {
  width: 50%;
  display: flex;
  justify-content: center;
}
.synchro-batterie .battery-component {
  width: fit-content;
  width: -moz-fit-content;
  display: flex;
}
.synchro-batterie .battery-component .lvl-battery {
  background-color: red;
  width: 36px;
  height: 16px;
  position: absolute;
  margin: 3px;
}

.synchro-version {
  font-size: 15px;
  width: 50%;
  color: black;
  border-left: 1px solid lightgrey;
}
.synchro-version .synchro-version-container {
  padding: 10px 0px 0px 5px;
}

.multi-drag-bar {
  border: 1px solid lightgrey;
  height: calc(100vh - 114px);
  border-radius: 5px;
  background-color: white;
  width: 5%;
  padding: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.multi-drag-bar .title {
  z-index: 50;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 1px solid #31c6b3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.multi-drag-bar .title svg {
  fill: #31c6b3;
}
.multi-drag-bar .title:hover {
  transition: 0.25s;
  background-color: white;
  width: 180px;
  border-radius: 10px;
  border: 2px solid #31c6b3;
  transform: translateX(-60px);
}
.multi-drag-bar .title:hover svg {
  fill: #31c6b3;
  margin-right: 5px;
}
.multi-drag-bar .title.checked {
  background-color: #31c6b3;
}
.multi-drag-bar .title.checked svg {
  fill: white;
}
.multi-drag-bar .title .span-button {
  width: auto;
}
.multi-drag-bar .title .span-button h5 {
  margin-bottom: 0;
}
@keyframes display-span {
  from {
    width: 0%;
  }
  to {
    width: 90%;
  }
}
.multi-drag-bar .svg-container {
  margin-top: 15px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.multi-drag-bar .switched-button {
  float: right;
}
.multi-drag-bar .synchro-button-wrapper-column {
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.multi-drag-bar .synchro-button-wrapper-column .button-bottom-column {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  cursor: pointer;
  border: none;
}
.multi-drag-bar .synchro-button-wrapper-column .button-bottom-column .body-container {
  display: flex;
  align-items: center;
}
.multi-drag-bar .synchro-button-wrapper-column .button-bottom-column:hover {
  transition: 0.25s;
  width: 130px;
  border-radius: 10px;
  background-color: white;
  border: 2px solid;
  transform: translateX(-35px);
}
.multi-drag-bar .synchro-button-wrapper-column .button-bottom-column h5 {
  color: white;
  margin-bottom: 0;
}
.multi-drag-bar .synchro-button-wrapper-column .button-bottom-column .span-button {
  width: auto;
}
.multi-drag-bar .synchro-button-wrapper-column .button-bottom-column .span-button h5 {
  margin-bottom: 0;
}
@keyframes display-span {
  from {
    width: 0%;
  }
  to {
    width: 90%;
  }
}

.column-synchronisation .col-12 {
  padding: 0;
}
.column-synchronisation .Wrapper {
  overflow-x: hidden;
}
.column-synchronisation .DefaultContainer {
  z-index: 1;
}

.drag-title-clone {
  padding: 2px;
  margin-right: 5px;
  padding-right: 5px;
  white-space: nowrap;
  border-radius: 5px;
}

.synchro-online {
  width: 50%;
  padding: 0px 20px 0px 20px;
  border-left: 1px solid lightgrey;
}

.backSidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.containerLocs {
  min-width: calc(100% - 260px);
  margin-left: 260px;
  padding: 10px;
}
.containerLocs .over {
  padding-left: 8px;
}

.locationContainer .crumbContainer .crumbContent {
  margin: 1px 0 0 0;
  width: fit-content;
  width: -moz-fit-content;
  white-space: nowrap;
}
.locationContainer .crumbContainer .home {
  height: fit-content;
}
.locationContainer .crumbContainer .crumbTooltip {
  position: relative;
  display: inline-block;
}
.locationContainer .crumbContainer .crumbTooltip .tooltipText {
  visibility: hidden;
  width: fit-content;
  width: -moz-fit-content;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  bottom: 125%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}
.locationContainer .crumbContainer .crumbTooltip .tooltipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.locationContainer .crumbContainer .crumbTooltip:hover .tooltipText {
  visibility: visible;
  display: block;
  opacity: 1;
}
.locationContainer .slick-list {
  background-color: #e9ecef;
}
.locationContainer .slick-dots > li {
  display: inline-block;
}
.locationContainer .slick-dots > li:only-child {
  display: inline-block;
}
.locationContainer .slick-track {
  height: 500px;
}
.locationContainer .slick-track div {
  height: 100%;
}
.locationContainer .slick-prev:before {
  color: #31c6b3;
}
.locationContainer .slick-next:before {
  color: #31c6b3;
}
.locationContainer .locationsList {
  overflow-y: auto;
  background-color: #ffffff;
}
.locationContainer .slick-slide > div {
  margin: 0 1px;
}
.locationContainer .slick-list {
  margin: 0 -1px;
}
.locationContainer .addLocationButton {
  position: absolute;
  bottom: 30px;
  right: 20px;
  cursor: pointer;
}
.locationContainer .addLocationButton .round {
  height: 30px;
}
.locationContainer .addLocationButton .round .absoluteCentered, .locationContainer .addLocationButton .round .reportContainer .noReportSelected, .reportContainer .locationContainer .addLocationButton .round .noReportSelected, .locationContainer .addLocationButton .round .reportContainer #preview-report #fullscreen .svgContent .svgFullScreen, .reportContainer #preview-report #fullscreen .svgContent .locationContainer .addLocationButton .round .svgFullScreen, .locationContainer .addLocationButton .round .centerMeterSelected svg, .centerMeterSelected .locationContainer .addLocationButton .round svg {
  padding: 0 0 3px 0;
}
.locationContainer .noChildLocation {
  padding: 15px;
  color: darkGray;
  font-style: italic;
}
.locationContainer .slick-slider {
  border-top: 1px solid lightgrey;
}
.locationContainer .locBrowser {
  background: #ffffff;
  border: 1px solid lightgrey;
  border-radius: 15px;
  height: 100%;
}
.locationContainer .locBrowser .rowListLocation {
  height: 100%;
  margin: 0;
  width: max-content;
}
.locationContainer .addWidget {
  padding: 0;
}
.locationContainer div.scrollLocBrowser > div:last-child > div:last-child > .locationsList {
  border-right: 0px;
}
.locationContainer .addLocationButton {
  position: absolute;
  bottom: 30px;
  right: 20px;
  cursor: pointer;
}
.locationContainer .noLocationFoundError {
  margin: -15px 0 0 5px;
  font-size: 10px;
  color: red;
  height: 20px;
}
.locationContainer .locationParentBlock {
  border: silver;
}
.locationContainer .locationShowInfo {
  background: #ffffff;
  margin: 0;
  border-radius: 15px;
  min-height: 300px;
  height: 100%;
  width: 100%;
  border: 1px solid #ccc;
}
.locationContainer .locationShowInfo h4 {
  padding-top: 15px;
  color: black;
}
.locationContainer .locationShowInfo .mapContainer {
  height: 100%;
  padding: 0 0 60px 0;
}
.locationContainer .locationShowInfo #map_locations {
  position: relative;
  width: 100%;
  height: 220px;
  margin: 0;
  padding: 0;
}
.locationContainer .locationShowInfo #map_locations a[title="Report errors in the road map or imagery to Google"] {
  display: none !important;
}
.locationContainer .locationShowInfo #map_locations a[title="Signaler à Google une erreur dans la carte routière ou les images"] {
  display: none !important;
}
.locationContainer .locationNotSelected {
  background: #e9ecef;
  border-radius: 15px;
  border: 1px solid #ccc;
}
.locationContainer .locationNotSelected p {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.locationContainer .locationsTitleInfo {
  background-size: 700px;
  width: 100%;
  background: url(./_svgs/background_green.svg);
  background-size: 896px 1080px;
  min-height: 60px;
  border-radius: 10px 10px 0 0;
}
.locationContainer #locationListContainer {
  position: absolute;
  top: 0;
  padding: 15px;
  height: 100%;
  width: 100%;
}
.locationContainer #locationListContainer .footer-content {
  position: absolute;
  bottom: 60px;
  right: 0;
  margin: 25px 15px 15px 15px;
}
.locationContainer .childLocationTitle {
  padding: 5px;
  margin: 0;
  min-height: 35px;
  background-color: #e9ecef;
}
.locationContainer .childLocationTitle h6 {
  color: #000;
  margin: 0;
  padding-top: 5px;
  padding-left: 15px;
}
.locationContainer .height100 {
  height: 100%;
}
.locationContainer .anySelectedLocation {
  background: #ccc;
}
.locationContainer .react-autosuggest__container {
  position: relative;
}
.locationContainer .react-autosuggest__container ::placeholder {
  color: darkgray;
  font-style: italic;
}
.locationContainer .react-autosuggest__container .icon {
  position: absolute;
  top: 22px;
  right: 5px;
  width: 15px;
  height: 15px;
}
.locationContainer .react-autosuggest__input {
  width: 100%;
  height: 30px;
  padding: 10px 40px 10px 20px;
  font-weight: 300;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin: 15px 0;
}
.locationContainer .react-autosuggest__input--focused {
  outline: none;
}
.locationContainer .react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.locationContainer .react-autosuggest__suggestions-container {
  display: none;
}
.locationContainer .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 45px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  max-height: 200px;
  overflow-y: auto;
}
.locationContainer .react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.locationContainer .react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.locationContainer .react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.statistique-tournee {
  width: 100%;
  display: flex;
  padding-left: 30px;
  flex-wrap: wrap;
}
.statistique-tournee .doughnut-chart {
  width: 48%;
  margin: 0 2% 2% 0;
}
.statistique-tournee .doughnut-chart.bar {
  width: 100%;
}
.statistique-tournee .doughnut-chart.bar canvas {
  max-height: 300px;
}
.statistique-tournee .doughnut-chart .chart-container {
  height: 100%;
  background-color: white;
  border-radius: 15px;
  padding-top: 10px;
}
.statistique-tournee .doughnut-chart .chart-container h3 {
  margin-top: 10px;
  text-align: center;
}

.maskAlarmComponent {
  padding: 0 15px 0 5px;
}
.maskAlarmComponent h2 {
  padding-left: 20px;
}
.maskAlarmComponent h2 span {
  margin-left: 10px;
}
.maskAlarmComponent .maskSelectedArrow {
  position: absolute;
  right: 15px;
  bottom: 10px;
  transform: rotate(90deg);
}
.maskAlarmComponent .maskUnselectedArrow {
  position: absolute;
  right: 10px;
}
.maskAlarmComponent .maskAlarmList {
  cursor: pointer;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  margin-bottom: 1px;
}
.maskAlarmComponent .spacingBlock {
  margin-top: 20px;
}
.maskAlarmComponent .maskContainer {
  margin-left: 10px;
}
.maskAlarmComponent .maskDate {
  font-size: 18px;
  color: black;
}

.modal-mask-alarm {
  margin-bottom: 6px;
}

.selection-component {
  width: 100%;
  border: solid 1px lightgrey;
  background-color: white;
  border-radius: 15px;
}
.selection-component .preview {
  text-align: center;
}
.selection-component .preview h4 {
  margin: 0 2vw;
  color: black;
}
.selection-component .items-container {
  padding: 20px 0px;
}
.selection-component .items-container .item-selector {
  width: 20%;
  text-align: center;
  margin: 0 10%;
}
.selection-component .items-container .item-selector h3 {
  color: black;
  margin-bottom: 0;
  border: 1px solid #31c6b3;
  padding: 5px 0;
  border-radius: 5px;
  cursor: pointer;
}

.alarm-configurator {
  padding: 0 20px;
}
.alarm-configurator .selection-component {
  margin: 10px 0;
}
.alarm-configurator a:link {
  color: white;
  text-decoration: none;
}
.alarm-configurator a:visited {
  color: white;
  text-decoration: none;
}

.button-alarm-configurator a:link {
  color: white;
  text-decoration: none;
}
.button-alarm-configurator a:visited {
  color: white;
  text-decoration: none;
}

.user-selector {
  width: 100%;
}
.user-selector .filter-container {
  padding: 30px 25px;
}
.user-selector .filter-container .title {
  cursor: pointer;
}
.user-selector .filter-container .title svg {
  align-self: center;
  fill: black;
  height: 1.5em;
}
.user-selector .filter-container .title h2 {
  align-self: center;
  margin-bottom: 0;
  color: black;
  margin: 0 10px;
}
.user-selector .filter-container .user-list {
  width: 35%;
  margin-top: 15px;
}

.alarm-option-form .form-control.is-valid {
  background-image: none;
}
.alarm-option-form .form-control.is-invalid {
  background-image: none;
}
.alarm-option-form.filter-container {
  padding: 30px 25px;
}
.alarm-option-form .rc-slider-track {
  background-color: #31c6b3;
}
.alarm-option-form .title svg {
  align-self: center;
  fill: black;
  height: 1.5em;
}
.alarm-option-form .title h2 {
  align-self: center;
  margin-bottom: 0;
  color: black;
  margin: 0 10px;
}
.alarm-option-form h5 {
  font-weight: bold;
  color: black;
}
.alarm-option-form .calcul-method {
  margin-top: 2vh;
}
.alarm-option-form .name-input {
  width: 40%;
}
.alarm-option-form .wrapper {
  margin-top: 2vh;
}
.alarm-option-form .range-wrapper .MuiSlider-root {
  color: #31c6b3;
  padding-bottom: 0;
}
.alarm-option-form .ref-witness-selector {
  width: 40%;
}
.alarm-option-form .MuiFormLabel-root.Mui-focused {
  color: #31c6b3;
}
.alarm-option-form .MuiInputBase-root:hover {
  border-bottom-color: #31c6b3;
}
.alarm-option-form .MuiInput-underline:after {
  border-bottom-color: #31c6b3;
}
.alarm-option-form .MuiInput-underline:before:hover {
  border-bottom-color: #31c6b3;
}

.MuiSlider-root.MuiSlider-colorPrimary {
  color: #31c6b3;
}

.selected-content {
  margin: 2vh 0;
}
.selected-content a:link {
  color: #2c303b;
  text-decoration: none;
}
.selected-content a:visited {
  color: #2c303b;
  text-decoration: none;
}
.selected-content .select-option {
  padding: 20px;
  background-color: white;
  border-radius: 0 10px 10px 10px;
  border: 1px solid lightgrey;
  margin-top: -1px;
}
.selected-content .select-option .preview {
  text-align: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px 0;
}
.selected-content .select-option .preview h4 {
  margin-bottom: 0;
}
.selected-content .filter-container {
  margin-top: 10px;
}
.selected-content .selection-component {
  border: none;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
}
.selected-content .selection-component .c32 {
  cursor: pointer;
  width: 25%;
  text-align: center;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
}
.selected-content .selection-component .c32 .space-svg {
  margin-right: 2vh;
}
.selected-content .selection-component .c32 svg {
  align-self: center;
}
.selected-content .selection-component .c32 h3 {
  margin-bottom: 0;
}
.selected-content .selection-component .c33:not(:last-child) {
  cursor: pointer;
  width: 33%;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px 5px 0 0;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
}
.selected-content .selection-component .c33:not(:last-child) .space-svg {
  margin-right: 2vh;
}
.selected-content .selection-component .c33:not(:last-child) svg {
  align-self: center;
}
.selected-content .selection-component .c33:not(:last-child) h3 {
  margin-top: auto;
  margin-bottom: auto;
}
.selected-content .selection-component .c50 {
  cursor: pointer;
  width: 50%;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px 5px 0 0;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
}
.selected-content .selection-component .c50 .space-svg {
  margin-right: 2vh;
}
.selected-content .selection-component .c50 svg {
  align-self: center;
}
.selected-content .selection-component .c50 h3 {
  margin-top: auto;
  margin-bottom: auto;
}
.selected-content .selection-component .c33:last-child {
  cursor: pointer;
  width: 34%;
  text-align: center;
  border-radius: 5px 5px 0 0;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
}
.selected-content .selection-component .c33:last-child .space-svg {
  margin-right: 2vh;
}
.selected-content .selection-component .c33:last-child svg {
  align-self: center;
}
.selected-content .selection-component .c33:last-child h3 {
  margin-top: auto;
  margin-bottom: auto;
}

.mask-alarm .filter-label {
  display: block;
  margin-bottom: 0;
}
.mask-alarm th {
  vertical-align: top !important;
}
.mask-alarm #date-filter-comparator-startDate {
  display: none !important;
}
.mask-alarm #date-filter-comparator-endDate {
  display: none !important;
}
.mask-alarm label {
  margin-bottom: 0;
}

.noFilled {
  color: lightgrey;
  font-style: italic;
  font-size: 1em;
}

.infoDisplayMeters {
  font-size: 2em;
}

@media screen and (min-width: 1300px) {
  .modal-addwidget-container {
    max-width: 1100px;
  }
}
.modal-addwidget-container .modal-add-widget .content {
  height: 100%;
}
.modal-addwidget-container .modal-add-widget .content .widget-card {
  width: 47%;
  cursor: pointer;
  display: flex;
  height: 90px;
}
.modal-addwidget-container .modal-add-widget .content .widget-card img {
  height: 100%;
  width: auto;
}
.modal-addwidget-container .modal-add-widget .content .widget-card .card-info {
  display: grid;
  width: 60%;
}

.vmeter-tab label {
  display: block;
  margin-bottom: 0;
  width: 40%;
}
.vmeter-tab th {
  vertical-align: top !important;
}

#locationDelete {
  position: absolute;
  right: 25px;
  top: 15px;
  border: 0;
}

.dropDown-add-perm {
  margin: 10px 0;
}

.locationContainerSlick .slick-track {
  height: 500px;
}
.locationContainerSlick .slick-track div {
  height: 100%;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  height: 100%;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.user-permissions-container {
  margin: 3vh 0;
  width: 100%;
}
.user-permissions-container .permission-colonne {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 10px;
  width: 47.5%;
}
.user-permissions-container .permission-colonne .colonne-header {
  border-bottom: 1px solid #31c6b3;
  padding: 10px;
}
.user-permissions-container .permission-colonne .colonne-header h3 {
  color: black;
  margin-bottom: 0;
}
.user-permissions-container .permission-colonne .body-element {
  border: 1px solid lightgrey;
  padding: 10px 20px;
  margin: 10px 0;
  border-right: 0;
  border-left: 0;
}
.user-permissions-container .permission-colonne .body-element h4 {
  margin-bottom: 0;
}
.user-permissions-container .permission-colonne .body-element .circle {
  align-self: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}
.user-permissions-container .permission-colonne .body-element .circle.right {
  float: right;
}

.profil-modal-footer .btn-success {
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.profil-modal-footer .btn-success:hover {
  background-color: #34827a;
  border-color: #34827a;
}

td .MuiFormControl-root {
  width: 50%;
}

.profil-container {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 20px;
  width: 100%;
}
.profil-container.user {
  margin-top: 4vh;
}
.profil-container.user .profil-footer button {
  float: right;
  background-color: #31c6b3;
  border-color: #31c6b3;
}
.profil-container .profil-header h2 {
  margin-bottom: 0;
  margin-left: 15px;
  color: black;
}
.profil-container .profil-header svg {
  align-self: center;
}
.profil-container .profil-header .add-container {
  height: fit-content;
  align-self: center;
  width: 100%;
}
.profil-container .profil-header .add-container div {
  float: right;
  margin-right: 10px;
  background-color: lightgrey;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.profil-container .profil-header .add-container div:hover {
  background-color: #34827a;
}
.profil-container .profil-body {
  margin-top: 3vh;
}
.profil-container .profil-body .MuiInput-underline:before {
  border-bottom: 1px solid #31c6b3;
}
.profil-container .profil-body .MuiInput-underline:after {
  border-bottom: 2px solid #31c6b3;
}
.profil-container .profil-body .react-bootstrap-table th {
  border-top: none;
}
.profil-container .profil-body .react-bootstrap-table .delete-profil-row {
  cursor: pointer;
  background-color: lightgrey;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
}
.profil-container .profil-body .react-bootstrap-table .delete-profil-row svg {
  align-self: center;
}
.profil-container .profil-body .react-bootstrap-table .delete-profil-row:hover {
  background-color: #34827a;
}

.filter-label {
  display: block;
}

.center-td {
  text-align: "center";
  vertical-align: "middle";
}

.source-sheet-date-component .best-date-component {
  padding: 0;
}

.roundConso {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 180px;
  border-radius: 100%;
  padding: 15px 0;
  background-color: white;
  text-align: center;
  color: #333;
  z-index: 10;
  font-size: 14px;
  -webkit-box-shadow: 0px 0px 6px 3px rgb(196, 196, 196);
  -moz-box-shadow: 0px 0px 6px 3px rgb(196, 196, 196);
  box-shadow: 0px 0px 6px 3px rgb(196, 196, 196);
}

.nbConsumption {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.nbXl {
  font-size: 5em;
}

.nbL {
  font-size: 3.5em;
}

.nbM {
  font-size: 3.1em;
}

.nbS {
  font-size: 2.8em;
}

.nbXs {
  font-size: 2.2em;
}

.arrowContainer {
  align-self: center;
  padding: 0;
  text-align: -webkit-right;
  text-align: -moz-right;
}

.absoluteCentered, .reportContainer .noReportSelected, .reportContainer #preview-report #fullscreen .svgContent .svgFullScreen, .centerMeterSelected svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.absoluteVerticalCenter, .clusterMeterList .react-bootstrap-table .clusterListColumn .cell-svg, .clusterMeterList .react-bootstrap-table .clusterListColumn .cell-meterSerial {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.absoluteHorizontalCenter, .clusterMeterList .react-bootstrap-table-pagination-list, .remoteReadingData .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .pagination {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.containerZoom {
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.5s;
  transform: translate(-50%, -50%) scale(0.2);
}
.containerZoom .infoToShow {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: none;
}
.containerZoom .svgInfo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(4);
}

.containerZoom:hover {
  transform: translate(-50%, -50%) scale(1);
}
.containerZoom:hover .svgInfo {
  display: none;
}
.containerZoom:hover .infoToShow {
  display: flex;
  opacity: 1;
}

#triangle {
  width: fit-content;
  width: -moz-fit-content;
  margin: auto;
}
#triangle .arrow {
  width: 30px;
  height: 30px;
  border: solid lightgray;
  border-width: 0 1px 1px 0;
  background-color: white;
  display: inline-block;
  padding: 3px;
}
#triangle .right {
  margin-left: -14px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
#triangle .left {
  margin-right: -14px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
#triangle .up {
  margin-bottom: -14px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
#triangle .down {
  margin-top: -14px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.consumption {
  width: 65%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #2c303b;
  border-radius: 10px;
  padding: 3% 2%;
  margin-top: -10px;
}
.consumption .conso-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.consumption h3 {
  font-weight: lighter;
  font-size: 1.2rem;
  margin: 0;
}
.consumption .avg {
  font-weight: bold;
}
.consumption .consoRange {
  color: white;
  opacity: 0.5;
  margin: 0 2%;
}
.consumption .interval_symbol {
  color: white;
  opacity: 0.5;
  font-size: 1.3rem;
  margin: 0 5px;
}
.consumption .consoOver {
  color: white;
  opacity: 1;
}
.consumption .limitContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.consumption .warning-limit {
  font-size: 20px;
  margin: 0 5px;
}
.consumption div {
  color: white;
}
@media screen and (max-width: 1920px) {
  .consumption .nbXl {
    font-size: 3.5em;
  }
  .consumption .nbL {
    font-size: 2.7em;
  }
  .consumption .nbM {
    font-size: 2.3em;
  }
  .consumption .nbS {
    font-size: 1.7em;
  }
  .consumption .nbXs {
    font-size: 1.7em;
  }
}
@media screen and (max-width: 1680px) {
  .consumption .nbXl {
    font-size: 3.3em;
  }
  .consumption .nbL {
    font-size: 2.5em;
  }
  .consumption .nbM {
    font-size: 1.9em;
  }
  .consumption .nbS {
    font-size: 1.4em;
  }
  .consumption .nbXs {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 1444px) {
  .consumption h3 {
    font-size: 1.2em;
  }
  .consumption .nbXl {
    font-size: 3em;
  }
  .consumption .nbL {
    font-size: 2.3em;
  }
  .consumption .nbM {
    font-size: 1.7em;
  }
  .consumption .nbS {
    font-size: 1.3em;
  }
  .consumption .nbXs {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 1366px) {
  .consumption .nbXl {
    font-size: 2.9em;
  }
  .consumption .nbL {
    font-size: 2.1em;
  }
  .consumption .nbM {
    font-size: 1.6em;
  }
  .consumption .nbS {
    font-size: 1.2em;
  }
  .consumption .nbXs {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 1280px) {
  .consumption .nbXl {
    font-size: 2.7em;
  }
  .consumption .nbL {
    font-size: 2em;
  }
  .consumption .nbM {
    font-size: 1.4em;
  }
  .consumption .nbS {
    font-size: 1em;
  }
  .consumption .nbXs {
    font-size: 1em;
  }
}

@media screen and (max-width: 1920px) {
  .indexWrapped h3 {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 1680px) {
  .indexWrapped h3 {
    font-size: 1.2em;
  }
  .indexWrapped .DigitRoll__Cell {
    font-size: 1.5em;
    width: 1.5rem !important;
  }
}
@media screen and (max-width: 1444px) {
  .indexWrapped h3 {
    font-size: 1.1em;
  }
}
@media screen and (max-width: 1366px) {
  .indexWrapped h3 {
    font-size: 1em;
  }
  .indexWrapped .DigitRoll__Cell {
    width: 1.4rem !important;
    height: 2.5rem;
  }
}
@media screen and (max-width: 1280px) {
  .indexWrapped .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1rem !important;
    height: 2.5rem;
  }
}

@media screen and (max-width: 1920px) {
  .indexWrappedLight h3 {
    font-size: 1.2em;
  }
  .indexWrappedLight .date {
    font-size: 1em;
  }
  .indexWrappedLight .DigitRoll__Cell {
    font-size: 1.5em;
    width: 1.5rem !important;
  }
}
@media screen and (max-width: 1680px) {
  .indexWrappedLight h3 {
    font-size: 1em;
  }
  .indexWrappedLight .date {
    font-size: 0.9em;
  }
  .indexWrappedLight .DigitRoll__Cell {
    font-size: 1.4em;
    width: 1.2rem !important;
  }
}
@media screen and (max-width: 1444px) {
  .indexWrappedLight .DigitRoll__Cell {
    font-size: 1.3em;
    width: 1.1rem !important;
  }
}
@media screen and (max-width: 1366px) {
  .indexWrappedLight .DigitRoll__Cell {
    font-size: 1.3em;
    width: 1rem !important;
  }
}
@media screen and (max-width: 1280px) {
  .indexWrappedLight .DigitRoll__Cell {
    font-size: 1.2em;
    width: 0.8rem !important;
  }
}

.DigitRoll__Cell {
  margin: 0 1px;
  background: #333;
  font-size: 2em;
  color: white;
}

.DigitRoll__Divider {
  padding: 0 !important;
}

.DigitRoll {
  border: none !important;
}

@media screen and (max-width: 1920px) {
  .consMeter .meterInfo .infoDisplay {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 1680px) {
  .consMeter .meterInfo .infoDisplay {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 1444px) {
  .consMeter h2 {
    font-size: 1.8em;
  }
  .consMeter .picto {
    display: none;
  }
}
@media screen and (max-width: 1366px) {
  .ficheDetailMeter .presentation-main-title {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 1280px) {
  .ficheDetailMeter .presentation-main-title {
    font-size: 1.1em;
  }
}

.add-profil {
  width: 100%;
}
.add-profil div {
  float: right;
  display: flex;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: lightgrey;
  border-radius: 15px;
  margin-right: 10px;
  cursor: pointer;
}
.add-profil div:hover {
  background-color: #34827a;
}
.add-profil div svg {
  align-self: center;
}

.mobile-chat {
  height: 500px;
  width: 300px;
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 15px;
}
.mobile-chat .header-chat {
  height: 15%;
  background-color: #31c6b3;
  border-radius: 15px 15px 0 0;
  display: flex;
  justify-content: center;
}
.mobile-chat .header-chat h3 {
  align-self: center;
  color: white;
}

.modal-profil .site {
  width: 49%;
  margin-right: 2%;
}
.modal-profil .profil {
  width: 49%;
}

.tourneeFicheTable .react-bootstrap-table {
  overflow: hidden;
}
.tourneeFicheTable table {
  height: 100%;
}
.tourneeFicheTable table thead th {
  background-color: white;
  border: 0;
}
.tourneeFicheTable table th {
  position: sticky;
  top: 0;
}

.device-container .logo {
  height: 20%;
  justify-content: center;
}
.device-container .logo img {
  height: 100%;
  width: 100%;
}
.device-container .container-login {
  height: 80% !important;
}

.synchro-device {
  height: 100%;
  overflow: hidden;
}
.synchro-device .header {
  display: flex;
  padding: 15px;
  align-items: center;
  background-color: #333;
}
.synchro-device .header h3 {
  margin-bottom: 0;
  margin-left: 10px;
  width: 80%;
  color: white;
}
.synchro-device .body {
  height: 100%;
  padding: 15px;
  background-image: linear-gradient(to top, #31c6b3, #239eab, #40768d, #465161, #333333);
}
.synchro-device .body .spinner-container {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.synchro-device .body .spinner-container .round-spinner {
  width: 5rem;
  height: 5rem;
  color: #31c6b3;
}
.synchro-device .body .synchro-button {
  width: calc(100% - 30px);
  bottom: 0.5rem;
  position: fixed;
  text-align: center;
}
.synchro-device .body .synchro-button button {
  border-radius: 30px;
  background-image: linear-gradient(to right top, #31c6b3, #59c88d, #8bc663, #bebe3d, #f2ae2e);
  width: 50%;
  height: 4em;
  border: none;
}
.synchro-device .body .synchro-button h5 {
  margin-bottom: 0;
}
.synchro-device .body .tri-container {
  width: 70%;
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.synchro-device .body .tri-container input[type=text],
.synchro-device .body .tri-container input[type=number],
.synchro-device .body .tri-container textarea {
  font-size: 16px;
}
.synchro-device .body .tri-container .filter-name {
  background-color: transparent;
  color: white;
  width: 70%;
  border: 1px solid #31c6b3;
}
.synchro-device .body .tri-container .svg-filter {
  margin-left: 10%;
  width: 20%;
  text-align: center;
  padding: 3px;
  border-radius: 5px;
}
.synchro-device .body .tri-container .open-fleche {
  position: absolute;
  right: 0;
  background-color: #31c6b3;
  padding: 5px 15px 5px 10px;
  border-radius: 15px 0 0 15px;
}
.synchro-device .body .separator {
  width: 80%;
  border-bottom: 1px solid #31c6b3;
  height: 0;
  margin: 2rem 0 2rem 10%;
}
.synchro-device .body .rounds-container {
  overflow: auto;
  max-height: 75%;
  padding: 5px;
}
.synchro-device .body .rounds-container .information-container-device h5 {
  color: white;
  font-weight: normal;
}
.synchro-device .body .rounds-container .round-device {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
}
.synchro-device .body .rounds-container .round-device .point-container {
  width: 10%;
}
.synchro-device .body .rounds-container .round-device .point-container .point {
  height: 10px;
  width: 10px;
  border-radius: 9999px;
}
.synchro-device .body .rounds-container .round-device .title {
  width: 20%;
}
.synchro-device .body .rounds-container .round-device .infos {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.synchro-device .black-opacity {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}
.synchro-device .black-opacity .actions-container {
  position: absolute;
  top: 10%;
  left: 100%;
  width: 80%;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  animation: slidein 1s forwards;
}
.synchro-device .black-opacity .actions-container h3 {
  margin-bottom: 0;
}
.synchro-device .black-opacity .actions-container .title-affect {
  margin-bottom: 0.5rem;
}
.synchro-device .black-opacity .actions-container .title-affect .label {
  display: flex;
  align-items: center;
  margin-top: 1px;
  padding: 1px 0;
}
.synchro-device .black-opacity .actions-container .title-action {
  display: flex;
  align-items: center;
}
.synchro-device .black-opacity .actions-container .title-action svg {
  margin-right: 15px;
}
.synchro-device .black-opacity .actions-container .title-action .round {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  float: right;
}
.synchro-device .black-opacity .actions-container .title-action .round:hover {
  background-color: #34827a;
}
.synchro-device .black-opacity .actions-container .title-action .round svg {
  margin: 0;
}
.synchro-device .black-opacity .actions-container .title-action {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.synchro-device .black-opacity .actions-container .title-action svg {
  margin-right: 15px;
}
@keyframes slidein {
  from {
    left: 100%;
  }
  to {
    left: 10%;
  }
}

.tag-container {
  width: 100%;
  display: flex;
}
.tag-container .input-container {
  width: 70%;
}
.tag-container .input-container.left {
  width: 18%;
  margin-right: 2%;
}
.tag-container .poubelle-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  justify-content: center;
  width: 10%;
}
.tag-container .poubelle-container .clickable-poubelle {
  background-color: lightgrey;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.tag-container .poubelle-container .clickable-poubelle:hover {
  background-color: #34827a;
}

.label-list-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.label-list-container .red-cross {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.label-list-container .red-cross .span {
  opacity: 0;
  transition: 0.3s;
}
.label-list-container .red-cross .svg {
  cursor: pointer;
  margin-right: 7px;
}
.label-list-container .red-cross .svg:hover + .span {
  opacity: 1;
}
.label-list-container .solo-graph .doughnut-chart {
  width: 100%;
}
.label-list-container span {
  color: black;
  margin-right: 5px;
}
.label-list-container .labels-container {
  padding: 5px;
  overflow-y: auto;
  width: 100%;
  height: 77%;
  margin-top: -1px;
  margin-bottom: 1px;
}
.label-list-container .labels-container .label {
  display: flex;
  align-items: center;
  margin-top: 1px;
  justify-content: center;
}
.label-list-container .labels-container .label .hover-container {
  width: auto;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
}
.label-list-container .labels-container .label .hover-container .pointe {
  height: 15px;
  width: 15px;
  background-color: #000000;
  transform: rotate(45deg);
  margin-left: -9px;
}
.label-list-container .labels-container .label .hover-container .info-label {
  background-color: #000000;
  padding: 5px;
  border-radius: 5px;
}
.label-list-container .labels-container .label .hover-container span {
  color: white;
  margin: 0 5px;
}
.label-list-container .labels-container span {
  display: block;
  font-size: 14px;
}

.device-container .logo {
  height: 20%;
  justify-content: center;
}
.device-container .logo img {
  height: 100%;
  width: 100%;
}
.device-container .container-login {
  height: 80% !important;
}

.synchro-device {
  height: 100%;
  overflow: hidden;
}
.synchro-device .header {
  display: flex;
  padding: 15px;
  align-items: center;
  background-color: #333;
}
.synchro-device .header h3 {
  margin-bottom: 0;
  margin-left: 10px;
  width: 80%;
  color: white;
}
.synchro-device .body {
  height: 100%;
  padding: 15px;
  background-image: linear-gradient(to top, #31c6b3, #239eab, #40768d, #465161, #333333);
}
.synchro-device .body .spinner-container {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.synchro-device .body .spinner-container .round-spinner {
  width: 5rem;
  height: 5rem;
  color: #31c6b3;
}
.synchro-device .body .synchro-button {
  width: calc(100% - 30px);
  bottom: 0.5rem;
  position: fixed;
  text-align: center;
}
.synchro-device .body .synchro-button button {
  border-radius: 30px;
  background-image: linear-gradient(to right top, #31c6b3, #59c88d, #8bc663, #bebe3d, #f2ae2e);
  width: 50%;
  height: 4em;
  border: none;
}
.synchro-device .body .synchro-button h5 {
  margin-bottom: 0;
}
.synchro-device .body .tri-container {
  width: 70%;
  display: flex;
  align-items: center;
  margin: 1rem 0;
}
.synchro-device .body .tri-container input[type=text],
.synchro-device .body .tri-container input[type=number],
.synchro-device .body .tri-container textarea {
  font-size: 16px;
}
.synchro-device .body .tri-container .filter-name {
  background-color: transparent;
  color: white;
  width: 70%;
  border: 1px solid #31c6b3;
}
.synchro-device .body .tri-container .svg-filter {
  margin-left: 10%;
  width: 20%;
  text-align: center;
  padding: 3px;
  border-radius: 5px;
}
.synchro-device .body .tri-container .open-fleche {
  position: absolute;
  right: 0;
  background-color: #31c6b3;
  padding: 5px 15px 5px 10px;
  border-radius: 15px 0 0 15px;
}
.synchro-device .body .separator {
  width: 80%;
  border-bottom: 1px solid #31c6b3;
  height: 0;
  margin: 2rem 0 2rem 10%;
}
.synchro-device .body .rounds-container {
  overflow: auto;
  max-height: 75%;
  padding: 5px;
}
.synchro-device .body .rounds-container .information-container-device h5 {
  color: white;
  font-weight: normal;
}
.synchro-device .body .rounds-container .round-device {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
}
.synchro-device .body .rounds-container .round-device .point-container {
  width: 10%;
}
.synchro-device .body .rounds-container .round-device .point-container .point {
  height: 10px;
  width: 10px;
  border-radius: 9999px;
}
.synchro-device .body .rounds-container .round-device .title {
  width: 20%;
}
.synchro-device .body .rounds-container .round-device .infos {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.synchro-device .black-opacity {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}
.synchro-device .black-opacity .actions-container {
  position: absolute;
  top: 10%;
  left: 100%;
  width: 80%;
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  animation: slidein 1s forwards;
}
.synchro-device .black-opacity .actions-container h3 {
  margin-bottom: 0;
}
.synchro-device .black-opacity .actions-container .title-affect {
  margin-bottom: 0.5rem;
}
.synchro-device .black-opacity .actions-container .title-affect .label {
  display: flex;
  align-items: center;
  margin-top: 1px;
  padding: 1px 0;
}
.synchro-device .black-opacity .actions-container .title-action {
  display: flex;
  align-items: center;
}
.synchro-device .black-opacity .actions-container .title-action svg {
  margin-right: 15px;
}
.synchro-device .black-opacity .actions-container .title-action .round {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  float: right;
}
.synchro-device .black-opacity .actions-container .title-action .round:hover {
  background-color: #34827a;
}
.synchro-device .black-opacity .actions-container .title-action .round svg {
  margin: 0;
}
.synchro-device .black-opacity .actions-container .title-action {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.synchro-device .black-opacity .actions-container .title-action svg {
  margin-right: 15px;
}
@keyframes slidein {
  from {
    left: 100%;
  }
  to {
    left: 10%;
  }
}

.switch-container {
  display: flex;
  align-items: center;
}
.switch-container .switch-point {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  z-index: 2;
  position: absolute;
  cursor: pointer;
}
.switch-container .switch-point.slide-right {
  animation: slideRight 0.25s forwards;
}
.switch-container .switch-point.slide-left {
  animation: slideLeft 0.25s forwards;
}
.switch-container .switch-bar {
  height: 10px;
  width: 30px;
  margin-left: -10px;
  z-index: 1;
  border-radius: 5px;
}

@keyframes slideRight {
  from {
    margin-left: -15px;
  }
  to {
    margin-left: 0px;
  }
}
@keyframes slideLeft {
  from {
    margin-left: 0px;
  }
  to {
    margin-left: -15px;
  }
}
.detail-site-pie .chart-container {
  height: 200px;
}

.synchro-message-new {
  background-color: #c8e6c9;
}

.synchro-message-old td:nth-child(2) {
  background-color: #c8e6c9;
}

.synchro-message {
  background-color: white;
}

.loadingg {
  background-color: lightgreen;
}

.fixed-scroll-bar-container {
  position: fixed;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: lightgrey;
  border-radius: 20px;
}
.fixed-scroll-bar-container .fixed-scroll-bar {
  max-width: 95%;
  overflow-x: auto;
  height: 15px;
  overflow-y: hidden;
  background-color: white;
}
.fixed-scroll-bar-container .fixed-scroll-bar h3 {
  color: lightgrey;
}

.filter.date-filter {
  display: flex;
}
.filter.date-filter .filter-label {
  display: none;
}
.filter.date-filter .filter-label select {
  display: none;
}
.filter.date-filter label {
  width: 100%;
}

.fiche-tab-container .table-info-container {
  min-height: 500px;
}
.fiche-tab-container .table-info-container .react-bootstrap-table,
.fiche-tab-container .table-info-container .table.table-hover {
  min-height: 500px;
}
.fiche-tab-container .table-info-container .unsortable-input {
  margin-bottom: 0.5rem;
}
.fiche-tab-container .table-info-container .table-select__placeholder {
  font-weight: 400;
  line-height: 1.5;
  font-size: 10px;
  color: #495057;
}
.fiche-tab-container .table-info-container .table-select__value-container {
  padding: 0;
}
.fiche-tab-container .table-info-container .table-select__indicators span {
  display: none;
}
.fiche-tab-container .table-info-container .table-select__indicators svg {
  width: 15px;
  fill: #333333 !important;
}
.fiche-tab-container .table-info-container .table-select__control {
  min-height: 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 6px;
  border-color: #ced4da;
}
.fiche-tab-container .table-info-container .table-select__control span {
  font-weight: 400;
  line-height: 1.5;
  font-size: 10px;
  color: #495057;
}
.fiche-tab-container .table-info-container .table-select__indicator {
  padding: 4px;
}

.material-icon-check {
  color: #31c6b3;
}

.material-icon-close {
  color: #dc3545;
}

@media screen and (max-width: 1920px) {
  .remoteReadingData .simple-date-component .form-group {
    width: 30% !important;
  }
  .remoteReadingData .displayChartBlock .table-info-container {
    height: 415px !important;
  }
  .remoteReadingData .displayChartBlock .flip-card-inner {
    height: 415px !important;
  }
}
@media screen and (max-width: 1600px) {
  .remoteReadingData h2 {
    font-size: 22px;
  }
  .remoteReadingData h4 {
    font-size: 18px;
  }
  .remoteReadingData .simple-date-component .form-group {
    width: 35% !important;
  }
  .remoteReadingData .displayChartBlock .table-info-container {
    height: 360px !important;
  }
  .remoteReadingData .displayChartBlock .flip-card-inner {
    height: 360px !important;
  }
  .remoteReadingData .displayChartBlock .svgChartNotAvailable svg {
    max-height: 250px;
  }
  .remoteReadingData .map {
    margin: 0 -20px -53px -20px !important;
  }
}
@media screen and (max-width: 1366px) {
  .remoteReadingData h2 {
    font-size: 20px;
  }
  .remoteReadingData h4 {
    font-size: 16px;
  }
  .remoteReadingData .simple-date-component .form-group {
    width: 50% !important;
  }
  .remoteReadingData .displayChartBlock .table-info-container {
    height: 300px !important;
  }
  .remoteReadingData .displayChartBlock .flip-card-inner {
    height: 300px !important;
  }
  .remoteReadingData .displayChartBlock .svgChartNotAvailable svg {
    max-height: 200px;
  }
}
@media screen and (max-width: 1280px) {
  .remoteReadingData h2 {
    font-size: 16px;
  }
  .remoteReadingData .simple-date-component .form-group {
    width: 50% !important;
  }
  .remoteReadingData .map {
    margin: 0 -20px -60px -20px !important;
  }
}
@media screen and (max-width: 1200px) {
  .remoteReadingData h2 {
    font-size: 20px;
  }
  .remoteReadingData .displayChartBlock .table-info-container {
    height: 380px !important;
  }
  .remoteReadingData .displayChartBlock .flip-card-inner {
    height: 380px !important;
  }
  .remoteReadingData .map {
    margin: 0 -20px -17px -20px !important;
  }
}
.remoteReadingData td.crystalList-column {
  white-space: initial;
}
.remoteReadingData .tableRemoteReadingMeter tr:hover {
  background-color: #e9ecef !important;
}
.remoteReadingData .displayMeterBloc {
  height: 1000px;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0;
  overflow: hidden;
}
.remoteReadingData .map {
  width: -webkit-fill-available !important;
  height: -webkit-fill-available !important;
  overflow: hidden;
  border-radius: 0 0 15px 15px;
  margin: 0 -20px -46px -20px;
}
.remoteReadingData .react-bootstrap-table {
  overflow-y: auto;
  position: relative;
  flex: 1;
}
.remoteReadingData .react-bootstrap-table-pagination {
  padding-top: 10px;
}
.remoteReadingData .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list {
  padding: 0;
  width: fit-content;
  width: -moz-fit-content;
  position: relative;
  height: 35px;
}
.remoteReadingData .react-bootstrap-table-pagination .react-bootstrap-table-pagination-list .pagination {
  margin-bottom: 0;
}
.remoteReadingData .selectedMeterInfoFromTable .noFilled {
  font-size: 16px;
}
.remoteReadingData #displayMeterFlipCard .infoMeterSelectedBand {
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #ccc;
  color: black;
  height: 55px;
}
.remoteReadingData .svgChartNotAvailable {
  text-align: center;
  margin-top: 20px;
}
.remoteReadingData .displayChartBlock {
  margin: 0 -15px 0 0;
}
.remoteReadingData .displayChartBlock .col-md-6,
.remoteReadingData .displayChartBlock col-xl-4 {
  padding: 0 15px 0 0;
}
.remoteReadingData .second-date-component {
  z-index: 10;
  background-color: white;
  border: 1px solid lightgrey;
  top: 90px;
  padding: 10px 0;
  position: fixed;
  margin-left: -15px;
  width: calc(100% - 260px);
  border-radius: 0px;
}
.remoteReadingData .second-date-component .title-date {
  align-self: center;
  padding: 15px;
  min-width: fit-content;
  width: -moz-fit-content;
  width: 25%;
  display: flex;
}
.remoteReadingData .second-date-component .title-date svg {
  margin-right: 40px;
  padding-left: 15px;
}
.remoteReadingData .second-date-component .title-date h3 {
  color: black;
  margin-bottom: 0;
  white-space: nowrap;
}
.remoteReadingData .second-date-component .flex-box {
  padding: 0;
}
.remoteReadingData .second-date-component .form-group {
  margin-bottom: 0;
  width: 25%;
}

input::placeholder {
  color: #808080;
  font-style: italic;
}

.vmeter-tab .unsortable-input {
  width: 40%;
}

.tab-onglet-import-container .onglet-container {
  display: flex;
  align-items: center;
}
.tab-onglet-import-container .onglet-container .onglet {
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid lightgrey;
}
.tab-onglet-import-container .onglet-container .onglet:hover {
  border-radius: 5px 5px 0 0;
  border-color: lightgrey;
}
.tab-onglet-import-container .onglet-container .onglet.actif {
  background-color: lightgrey;
  border-radius: 5px 5px 0 0;
  border-color: lightgrey;
}
.tab-onglet-import-container .onglet-container .onglet.inactif {
  background-color: #666666;
  cursor: auto;
  border-radius: 0;
}
.tab-onglet-import-container .onglet-container .onglet.inactif span {
  color: white;
}

.map .marker-info span {
  color: black;
}
.map .marker-info h5 {
  color: black;
}
.map .marker-info h4 {
  color: black;
}

.map-geoloc-list .table {
  margin-bottom: 0;
}

.close-infomap-container {
  background-color: lightgrey;
}

.close-infomap-container:hover {
  background-color: #34827a;
}

.loading-animation-container {
  height: 780px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loading-animation-container .items-container {
  border: 1px solid lightgrey;
  background-color: white;
  padding: 15px;
  border-radius: 50%;
  width: 370px;
  height: 370px;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-animation-container .spinner {
  margin: 100px auto;
  margin-bottom: 0;
  margin-top: 0;
  width: 70px;
  height: 70px;
  position: absolute;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}
.loading-animation-container .spinner .dot1,
.loading-animation-container .spinner .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.loading-animation-container .spinner .dot1 {
  background-color: #31c6b3;
}
.loading-animation-container .spinner .dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background-color: #dc3545;
}
.loading-animation-container .spinner2 {
  z-index: -1;
  top: calc(50% - 120px);
  left: calc(50% - 40px);
  width: 350px;
  height: 350px;
  position: absolute;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}
.loading-animation-container .spinner2 .dot1,
.loading-animation-container .spinner2 .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
.loading-animation-container .spinner2 .dot1 {
  background-color: #f2ae2e;
}
.loading-animation-container .spinner2 .dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background-color: #007bff;
}
@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@media screen and (max-width: 1920px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.5rem !important;
  }
}
@media screen and (max-width: 1680px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.5rem !important;
  }
  .selectedBand .elementWithSvg svg {
    display: none;
  }
}
@media screen and (max-width: 1444px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.5rem !important;
  }
}
@media screen and (max-width: 1366px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.4rem !important;
    height: 2.5rem;
  }
}
@media screen and (max-width: 1280px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1rem !important;
    height: 2.5rem;
  }
}
.locationModal .locationModalHeader .locationName {
  font-size: 2em;
}

.yield-chart-container {
  height: 100%;
  width: 100%;
}
.yield-chart-container #yield-chart {
  position: relative;
}
.yield-chart-container #tooltip {
  position: absolute;
  left: -200px;
  background-color: white;
  border-radius: 10px;
}

.clusterCC:hover .popupCluster {
  display: inherit;
}

.clusterCC .popupCluster {
  display: none;
  position: absolute;
  left: calc(100% + 5px);
  width: 220px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  z-index: 20;
  box-shadow: 0px 0px 5px 0px #adb5bd;
}
.clusterCC .popupCluster h3 {
  font-weight: bold;
  font-size: 15px;
  color: #31c6b3;
}
.clusterCC .popupCluster .nbMeter {
  font-size: 15px;
  font-weight: bold;
  padding-left: 5px;
}
.clusterCC .popupCluster .dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.popupClusterMeterList {
  left: -245px;
  padding-top: 50px;
}

.clusterMeterList {
  position: relative;
  width: 240px;
  height: 500px;
  padding: 0 30px 0 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px #adb5bd;
}
.clusterMeterList .react-bootstrap-table {
  height: fit-content;
  max-height: fit-content;
}
.clusterMeterList .react-bootstrap-table .table th {
  border-top: 0;
}
.clusterMeterList .react-bootstrap-table .clusterListColumn {
  position: relative;
  padding: 0 20px 0 20px;
  height: 40px;
}
.clusterMeterList .react-bootstrap-table .clusterListColumn .cell-meterSerial {
  left: 60px;
}
.clusterMeterList .react-bootstrap-table .clusterListColumn .cell-svg svg {
  height: 25px;
}
.clusterMeterList .react-bootstrap-table .clusterListColumn:hover {
  background-color: #e9ecef;
}
.clusterMeterList .react-bootstrap-table-pagination-list {
  bottom: 0;
  padding: 0;
  width: fit-content;
  width: -moz-fit-content;
}

.markerGM {
  width: fit-content;
  height: fit-content;
  width: -moz-fit-content;
  position: absolute;
}
.markerGM .selected-marker {
  display: none;
  position: absolute;
  top: -75px;
  left: -37.5px;
}
.markerGM .marker {
  position: absolute;
  top: -16px;
  left: -16px;
}
.markerGM .centerMarker {
  top: -20px;
  left: -20px;
}
.markerGM .markerSelected {
  top: -70px;
}
.markerGM .popupMarker {
  display: none;
  position: absolute;
  left: calc(100% + 25px);
  width: 220px;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  z-index: 20;
  box-shadow: 0px 0px 5px 0px #adb5bd;
}
.markerGM .popupMarker h3 {
  font-weight: bold;
  font-size: 15px;
  color: #31c6b3;
}
.markerGM .popupMarker .meterSerial {
  font-size: 15px;
  font-weight: bold;
  padding-left: 5px;
}

.markerGM:hover .marker {
  top: -20px;
  left: -20px;
}
.markerGM:hover .marker svg {
  height: 40px;
  width: 40px;
}
.markerGM:hover .centerMarker {
  top: -25px;
  left: -25px;
}
.markerGM:hover .centerMarker svg {
  height: 50px;
  width: 50px;
}
.markerGM:hover .markerSelected {
  top: -80px;
}
.markerGM:hover .selected-marker {
  position: absolute;
  top: -85px;
  left: -42.5px;
}
.markerGM:hover .selected-marker svg {
  height: 85px;
  width: 85px;
}
.markerGM:hover .popupMarker {
  display: inherit;
}

.centerMeterSelected {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50px;
  position: absolute;
  bottom: 125px;
  right: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px #adb5bd;
  -moz-box-shadow: 0px 0px 5px 0px #adb5bd;
  box-shadow: 0px 0px 5px 0px #adb5bd;
}
.centerMeterSelected svg {
  height: 2.5em;
  width: 2.5em;
}

.tele-releve-container {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 20px;
  width: 49%;
  margin-bottom: 15px;
}
.tele-releve-container .tele-releve-header {
  display: flex;
  align-items: center;
}
.tele-releve-container .tele-releve-header h2 {
  margin-bottom: 0;
  color: black;
}

.tele-releve-info-container {
  border-radius: 10px;
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 2%;
  align-items: center;
  display: flex;
  height: 80px;
}
.tele-releve-info-container h3 {
  margin-bottom: 0;
  color: black;
}
.tele-releve-info-container .c35 {
  width: 25%;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10px 0 0 10px;
  background: url("./_svgs/background_blue.svg");
  background-size: 896px 1080px;
  height: 100%;
}
.tele-releve-info-container .c35 svg {
  margin: 0 30px 0 10px;
}
.tele-releve-info-container .c35 span {
  color: white;
}
.tele-releve-info-container .c15 {
  width: 15%;
  padding: 15px 30px;
}
.tele-releve-info-container .c20 {
  width: 20%;
  padding: 15px 30px;
}
.tele-releve-info-container .c10 {
  width: 10%;
  padding: 15px 30px;
}
.tele-releve-info-container .c5 {
  width: 15%;
  padding: 15px 20px;
}
.tele-releve-info-container .c5.button {
  text-align: center;
}
.tele-releve-info-container .c25 {
  width: 20%;
  padding: 15px 30px;
}
@media screen and (max-width: 1590px) {
  .tele-releve-info-container h3 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1375px) {
  .tele-releve-info-container h3 {
    font-size: 12px;
  }
}

.tournee-info-container {
  border-radius: 10px;
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 2%;
  align-items: center;
  display: flex;
  height: 80px;
}
.tournee-info-container .flex-ref {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tournee-info-container .flex-ref .round {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
}
.tournee-info-container .flex-ref .round svg {
  margin: 0;
}
.tournee-info-container h3 {
  margin-bottom: 0;
  color: black;
}
.tournee-info-container .c35 {
  width: 25%;
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 10px 0 0 10px;
  background: url("./_svgs/background_orange.svg");
  background-size: 896px 1080px;
  height: 100%;
}
.tournee-info-container .c35 svg {
  margin: 0 30px 0 10px;
}
.tournee-info-container .c35 span {
  color: white;
}
.tournee-info-container .c15 {
  width: 15%;
  padding: 15px 30px;
}
.tournee-info-container .c20 {
  width: 20%;
  padding: 15px 30px;
}
.tournee-info-container .c10 {
  width: 10%;
  padding: 15px 30px;
}
.tournee-info-container .c5 {
  width: 15%;
  padding: 15px 20px;
}
.tournee-info-container .c5.button {
  text-align: center;
}
.tournee-info-container .c25 {
  width: 20%;
  padding: 15px 30px;
}
@media screen and (max-width: 1590px) {
  .tournee-info-container h3 {
    font-size: 16px;
  }
}
@media screen and (max-width: 1375px) {
  .tournee-info-container h3 {
    font-size: 12px;
  }
}

.radio-bar .tele-releve-info-container {
  margin-bottom: 1%;
}
.radio-bar .radio-info-bar {
  width: 50%;
  border: 1px solid lightgrey;
  border-radius: 10px;
  display: flex;
  background-color: white;
  margin-bottom: 1%;
  height: 100%;
}
.radio-bar .radio-info-bar h3 {
  margin-bottom: 0;
  color: black;
}
.radio-bar .radio-info-bar .c60 {
  width: 50%;
  padding: 15px;
  background: url(./_svgs/background_green.svg);
  background-size: 896px 1080px;
  align-items: center;
}
.radio-bar .radio-info-bar .c60 span {
  color: white;
}
.radio-bar .radio-info-bar .c20 {
  width: 25%;
  padding: 15px;
  text-align: center;
}

.telereleve-container-provisionning {
  width: 100%;
}
.telereleve-container-provisionning .provisionning-container {
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 7.5px;
}
.telereleve-container-provisionning .provisionning-container .header h3 {
  color: black;
}
.telereleve-container-provisionning .verif-title-container {
  padding: 10px;
  border: 1px solid lightgrey;
  background-color: white;
  width: 15%;
  border-radius: 15px;
  text-align: left;
  margin-bottom: 10px;
}
.telereleve-container-provisionning .verif-title-container h3 {
  margin-bottom: 0;
  color: black;
}

.provi-postite-container {
  width: 100%;
  height: fit-content;
  margin-left: 15px;
  position: relative;
  padding: 15px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid lightgrey;
}
.provi-postite-container .postite {
  border-radius: 10px 10px 0 0;
  display: flex;
  height: fit-content;
}
.provi-postite-container .postite.un .provisionning-container {
  background-color: #31c6b3;
}
.provi-postite-container .postite.deux {
  margin-top: 15px;
}
.provi-postite-container .postite.deux .provisionning-container {
  background-color: #dc3545;
}
.provi-postite-container .postite svg {
  font-size: 14px;
  fill: black;
}
.provi-postite-container .button-provi-container {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.provi-postite-container .button-provi-container button {
  width: 80px;
}

.fast-provisioning-container-big {
  background-color: white;
  z-index: 0;
  margin-left: 20px;
  height: fit-content;
  border-radius: 15px;
}
.fast-provisioning-container-big .fast-provisioning-container {
  border: 1px solid lightgrey;
  width: 100%;
  background-color: transparent;
  border-radius: 15px;
  padding: 15px;
  height: fit-content;
  position: relative;
}
.fast-provisioning-container-big .fast-provisioning-container .header {
  width: 50%;
  border-bottom: 2px solid lightgrey;
  padding-bottom: 15px;
}
.fast-provisioning-container-big .fast-provisioning-container .clip-container {
  background-color: #31c6b3;
  z-index: -1;
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  clip-path: polygon(81% 19%, 0 61%, 0 100%);
  border-radius: 15px;
}
.fast-provisioning-container-big .fast-provisioning-container h3 {
  color: black;
  margin-bottom: 0;
}
.fast-provisioning-container-big .fast-provisioning-container h4 {
  color: black;
  margin-bottom: 0;
}
.fast-provisioning-container-big .fast-provisioning-container .info-container {
  margin-top: 15px;
  z-index: 10;
}
.fast-provisioning-container-big.open {
  animation: 0.25s openFastProvi;
}
.fast-provisioning-container-big.close {
  animation: 0.25s closeFastProvi;
}

@keyframes openFastProvi {
  from {
    transform: translate(0, 800px);
  }
  to {
    transform: translate(0, 0);
  }
}
@keyframes closeFastProvi {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 800px);
  }
}
.tele-releve-container.up {
  animation: 1s table-up;
}
.tele-releve-container.down {
  animation: 1s table-down;
}

@keyframes table-down {
  from {
    flex: 0 0 100%;
  }
  to {
    flex: 0 0 66.66%;
  }
}
@keyframes table-up {
  from {
    flex: 0 0 66.66%;
  }
  to {
    flex: 0 0 100%;
  }
}
.multi-selection-provisionning label {
  margin-bottom: 0;
  height: 20px;
}

.tele-releve-body table {
  min-width: 0;
  overflow: auto;
  display: block;
  table-layout: auto;
  width: 100%;
}

.text-filter::placeholder {
  color: #808080;
  font-style: italic;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none !important;
}

.remote-table-column:not(:placeholder-shown):not(:disabled):not(.placeholder-selected) {
  background: rgba(104, 226, 13, 0.49);
  color: black;
  font-style: normal;
  font-size: 11px;
}

.demoBand {
  background-color: rgba(240, 132, 12, 0.8);
  color: #444444;
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  height: 20px;
  z-index: 1000;
  padding: 1px 0 0 0;
  text-align: -webkit-center;
  border-radius: 0;
  border: 0;
  font-weight: bold;
}

.main-layout .demoBand {
  left: -260px;
}

.gif {
  height: 25px;
  width: 25px;
}

.react-bootstrap-table .ordered-asc:before {
  margin-right: 0px !important;
  opacity: 1 !important;
  content: "↑";
}

.react-bootstrap-table .ordered-desc:after {
  margin-left: 0px !important;
  opacity: 1 !important;
  content: "↓";
}

.react-bootstrap-table th.sortable span:before {
  margin-left: 3.5px;
  content: "↑";
  opacity: 0.4;
}

.react-bootstrap-table th.sortable span:after {
  margin-left: 0px;
  content: "↓";
  opacity: 0.4;
}

#applyProvisioning {
  position: fixed;
  bottom: 1rem;
  left: calc(50% + 80px);
  z-index: 9999;
}

.table-select__menu {
  width: 250px !important;
  z-index: 10000 !important;
}

.reportContainer {
  height: 100%;
  border-radius: 15px;
  padding: 15px;
  margin: 5px 15px 15px 15px;
}
.reportContainer .round-report-error-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  color: red;
  border: 1px solid lightgrey;
  border-radius: 15px;
}
.reportContainer .round-report-error-container h3 {
  font-size: 0.9rem;
  margin: 0 20px;
  font-weight: normal;
}
.reportContainer .round-report-error-container .round-report-error-head {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: red;
  border-radius: 15px 0 0 15px;
  color: white;
}
.reportContainer h1 {
  color: #2c303b;
  text-align: center;
  margin-bottom: 35px;
}
.reportContainer h3 {
  color: #2c303b;
  text-align: center;
}
.reportContainer .row {
  margin: 0 -15px 0 0;
}
.reportContainer .btn {
  box-shadow: none !important;
}
.reportContainer .roundedContainer {
  border: 1px solid #dee2e6;
  border-radius: 15px;
  margin-right: 15px;
  padding: 10px;
  overflow: hidden;
}
.reportContainer .roundedContainer .list-group {
  margin: -10px;
}
.reportContainer .roundedContainer .list-group .list-group-item-action {
  display: flex;
  flex-direction: row;
  position: relative;
  color: #333333;
}
.reportContainer .roundedContainer .list-group .list-group-item-action .reportName {
  align-self: center;
  flex: 1;
}
.reportContainer .roundedContainer .list-group .list-group-item-action .choiceReportButton {
  align-self: center;
  width: fit-content;
  width: -moz-fit-content;
}
.reportContainer .roundedContainer .list-group .list-group-item-action .choiceReportButton button {
  height: 40px;
  width: 40px;
  border: 1px solid #dee2e6;
  border-radius: 200px;
  background-color: white;
  margin-left: 10px;
}
.reportContainer .reportList {
  height: fit-content;
}
.reportContainer .updateReport {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 8px;
  right: 17px;
  background-color: white;
  border-radius: 50px;
}
.reportContainer #preview-report {
  padding: 0 !important;
  height: 700px;
  position: relative;
  background-color: #adb5bd;
}
.reportContainer #preview-report .pdfobject-container {
  height: 100%;
}
.reportContainer #preview-report #fullscreen {
  position: absolute;
  right: 140px;
  top: 70px;
  width: 35px;
  height: 35px;
}
.reportContainer #preview-report #fullscreen .svgContent {
  position: relative;
  height: 100%;
}
.reportContainer #preview-report #fullscreen:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
}
.reportContainer #preview-report .pdfContainer {
  height: 100%;
}
.reportContainer #preview-report .pdfContainer .reportPdfTitle {
  background-color: #31c6b3;
  text-align: start;
  padding: 15px 40px;
  color: white;
  margin: 0;
}
.reportContainer #preview-report .pdfContainer .reportPdfTitle .orderBy {
  font-size: 20px;
  font-style: italic;
  font-weight: initial;
}
.reportContainer .noReportSelected div {
  text-align: center;
}
.reportContainer .reportButton {
  background-color: #e9ecef !important;
}
.reportContainer .activeButton {
  background-color: #31c6b3 !important;
}
.reportContainer .reportOrderBy {
  font-size: 10px;
  font-style: italic;
  color: grey;
  position: absolute;
}

.settingListGroup {
  margin: 1px !important;
}
.settingListGroup li {
  padding: 5px;
  display: flex;
  border: none;
  align-items: center;
}
.settingListGroup li .orderName {
  flex: 1;
}
.settingListGroup li .arrows, .settingListGroup li .arrowOrderDESC, .settingListGroup li .arrowOrderASC, .settingListGroup li .arrowOrder {
  font-size: 15px;
  margin-right: 25px;
}
.settingListGroup li .arrowOrder::before {
  margin-left: 3.5px;
  content: "↑";
  opacity: 0.4;
}
.settingListGroup li .arrowOrder::after {
  margin-left: 0px;
  content: "↓";
  opacity: 0.4;
}
.settingListGroup li .arrowOrderASC::before {
  margin-left: 3.5px;
  content: "↑";
  opacity: 1;
}
.settingListGroup li .arrowOrderASC::after {
  margin-left: 0px;
  content: "↓";
  opacity: 0.4;
}
.settingListGroup li .arrowOrderDESC::before {
  margin-left: 3.5px;
  content: "↑";
  opacity: 0.4;
}
.settingListGroup li .arrowOrderDESC::after {
  margin-left: 0px;
  content: "↓";
  opacity: 1;
}

.reportPopover {
  min-width: 190px;
}
.reportPopover .settingReportHeader {
  display: flex;
  align-items: flex-end;
}
.reportPopover .horizontalA4 {
  width: 20px;
  height: 15px;
  margin-right: 10px;
  border: 2px #333333 solid;
  border-radius: 3px;
}
.reportPopover .verticalA4 {
  width: 15px;
  height: 20px;
  border: 2px #333333 solid;
  border-radius: 3px;
}

.react-bootstrap-table-pagination {
  margin-bottom: 80px;
}

.tourneeDoublon {
  margin-left: 20px;
  margin-bottom: 10px;
}
.tourneeDoublon p {
  margin-bottom: 0;
}
.tourneeDoublon .bold-tournee {
  font-weight: bold;
}

.support-basic-container h3 {
  color: #2c303b;
}
.support-basic-container .custom-setting-container {
  margin-top: 30px;
  padding: 10px;
}
.support-basic-container .custom-setting-container .resetValues {
  margin-left: 30px;
}
.support-basic-container .custom-setting-container .checkbox-field {
  position: relative;
  min-height: 60px;
}
.support-basic-container .custom-setting-container .checkbox-field .form-check-input {
  top: 35px;
  left: 0;
  margin: 0;
}
.support-basic-container .custom-setting-container .checkbox-field .form-check-input::before {
  background-color: red;
}
.support-basic-container .custom-setting-container .checkbox-field .checkbox-mandatory {
  margin-top: 37px;
}
.support-basic-container .custom-setting-container .allBlockInput {
  display: flex;
  flex-wrap: wrap;
}
.support-basic-container .custom-setting-container .allBlockInput .inputBlock {
  margin-right: 15px;
  min-width: 175px;
}
.support-basic-container .custom-setting-container .allBlockInput .inputBlock .labelInput {
  display: flex;
  align-items: flex-end;
}
.support-basic-container .custom-setting-container .allBlockInput .inputBlock .labelInput span {
  flex: 1;
}
.support-basic-container .custom-setting-container .allBlockInput .inputBlock .labelInput svg {
  margin-bottom: 4px;
  margin-left: 5px;
}

.synchrotools .react-bootstrap-table {
  width: 30%;
}

.fields-enquete p:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.fields-enquete p:first-child {
  padding-top: 0;
}

.fields-enquete p {
  margin-bottom: 0;
  padding: 10px 0;
  min-height: 2rem;
}

.float-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 30px 15px 0;
}

.formgroup-align {
  margin-bottom: 0;
}

#select-type {
  width: 10%;
}

div[id^=defaultValue_list] {
  width: 10%;
}

div[class^=listeColumns] {
  width: 30%;
}

#codeList {
  margin-top: 30px;
}

.enq-rnd-link {
  width: 90%;
}

.gateway-container {
  border: 1px solid lightgrey;
  border-radius: 15px;
  background-color: white;
  padding: 15px;
  margin: 0 15px;
}
.gateway-container .simple-date-component {
  width: 50% !important;
}
.gateway-container .simple-date-component .form-group {
  width: 50% !important;
}
.gateway-container .master-gateway .react-bootstrap-table-pagination {
  margin-bottom: 40px;
  width: fit-content;
}
.gateway-container .big-table-gateway {
  margin-bottom: 0px;
  margin-top: 15px;
}

#remotetable-export {
  display: flex;
  align-items: baseline;
}

#remotetable-export > * {
  margin-right: 10px;
}

.rounded-band {
  border-radius: 10px !important;
}

.rounded-container {
  background-position: left;
  margin-left: 0px;
}

.custom-export-config {
  border-color: white;
  background-color: white;
  color: black;
  display: inline-flex;
  align-items: center;
  margin-left: -14px;
  margin-bottom: 10px;
}

.row {
  z-index: 1;
}

#select-messages {
  z-index: 5;
}

#select-marquages {
  z-index: 5;
}

.sensor-container {
  padding: 5px 15px;
  width: 100%;
}
.sensor-container .section-container .title-container {
  margin-bottom: 15px;
}
.sensor-container .section-container .title-container h3 {
  font-size: 30px;
  margin: 0;
}
.sensor-container span {
  font-size: 20px;
}
.sensor-container span.legend-label {
  font-size: 15px;
}
.sensor-container .info-map {
  width: 100%;
  display: flex;
}
.sensor-container .info-map .info-box {
  background-color: white;
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 15px;
  width: 30%;
  margin-right: 15px;
  min-width: 280px;
}
.sensor-container .info-map .info-box .switch-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sensor-container .info-map .info-box span {
  font-size: 0.9em;
}
.sensor-container .info-map .info-box h5 {
  font-size: 1.5em;
  margin-bottom: 0;
  color: black;
}
.sensor-container .historique-container {
  background-color: white;
  border: 1px solid lightgrey;
  padding: 15px;
  border-radius: 15px;
  margin-top: 15px;
}
.sensor-container .historique-container .wrapper .row:first-child .day,
.sensor-container .historique-container .wrapper .row:last-child .day {
  border-bottom: 0;
}
.sensor-container .historique-container .wrapper .row:first-child .box:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0);
}
.sensor-container .historique-container .legende {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  margin-left: 10px;
}
.sensor-container .historique-container .legende .legend-box {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
.sensor-container .historique-container .wrapper .row {
  margin: 0;
  display: flex;
}
.sensor-container .historique-container .wrapper .row .day {
  width: 150px;
  display: flex;
  border-bottom: 1px solid rgba(128, 128, 128, 0.6);
  align-items: center;
}
.sensor-container .historique-container .wrapper .row .flex-box {
  display: flex;
  width: 89%;
}
.sensor-container .historique-container .wrapper .row .flex-box .box {
  width: 100%;
  height: 50px;
  border-left: 1px solid rgba(128, 128, 128, 0.6);
  border-bottom: 1px solid rgba(128, 128, 128, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.sensor-container .historique-container .wrapper .row .flex-box .box.schema {
  justify-content: unset;
}
.sensor-container .historique-container .wrapper .row .flex-box .box-hour {
  width: 100%;
  height: 50px;
  border-right: 1px solid rgba(128, 128, 128, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.sensor-container .historique-container .wrapper .row .flex-box .box-hour.schema {
  justify-content: unset;
}
.sensor-container .temperature-container {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 15px;
  margin-top: 15px;
  padding: 15px;
}
.sensor-container .section-container {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 15px;
  margin-top: 15px;
  padding: 15px;
}
.sensor-container .downlink-container {
  margin-bottom: 10px;
}
.sensor-container .downlink-container .title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sensor-container .downlink-container .title svg {
  margin-left: 5px;
}
.sensor-container .downlink-container .title h4 {
  margin-bottom: 0;
}
.sensor-container .downlink-container span {
  font-size: 20px;
}
.sensor-container .downlink-container .wrapper .synchro-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.sensor-container .downlink-container .wrapper .synchro-container input {
  margin-right: 15px;
}
.sensor-container .downlink-container .wrapper .section:not(:last-of-type) {
  border-bottom: 1px solid gray;
}
.sensor-container .downlink-container .wrapper .section {
  display: flex;
  align-items: center;
  height: 80px;
  margin-left: 50px;
}
.sensor-container .downlink-container .wrapper .section .span-container {
  width: 150px;
}
.sensor-container .downlink-container .wrapper .section .flex-box {
  display: flex;
}
.sensor-container .downlink-container .wrapper .section .flex-box .button-group {
  display: flex;
  align-items: center;
}
.sensor-container .downlink-container .wrapper .section .flex-box .button-group .round:first-child {
  margin-right: 5px;
}
.sensor-container .downlink-container .wrapper .section .flex-box .button-group .delete {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sensor-container .downlink-container .wrapper .section .flex-box .button-group .delete:hover {
  background-color: #dc3545;
}
.sensor-container .downlink-container .wrapper .section .flex-box .button-group .delete .less {
  height: 4px;
  width: 25px;
  border-radius: 4px;
  background-color: black;
}
.sensor-container .downlink-container .wrapper .section .flex-box .delay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  margin-right: 50px;
}
.sensor-container .downlink-container .wrapper .section .flex-box .delay input:nth-child(1) {
  margin-right: 10px;
}
.sensor-container .downlink-container .wrapper .section .flex-box .add-delay {
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sensor-container .downlink-container .wrapper .section .flex-box .add-delay .round-add {
  height: 50px;
  width: 50px;
  border: 1px solid lightgrey;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.sensor-container .downlink-container .wrapper .section .flex-box .add-delay .round-add:hover {
  background-color: #6c757d;
}
.sensor-container .downlink-container .wrapper .section .flex-box .add-delay .round-add:hover h3 {
  color: white;
}
.sensor-container .downlink-container .wrapper .section .flex-box h3 {
  margin-bottom: 0;
}

.error-sensor {
  background-color: rgba(220, 53, 69, 0.8901960784);
  position: fixed;
  width: 70%;
  bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  transform: translate(10%, 0px);
}
.error-sensor.open {
  animation: translateY 400ms ease-in-out forwards;
}
.error-sensor h5 {
  margin: 0;
  color: white;
}
@keyframes translateY {
  0% {
    opacity: 0;
    transform: translate(10%, 50px);
  }
  100% {
    opacity: 1;
    transform: translateX(10%, 0px);
  }
}

.float-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 30px 15px 0;
}

.frequence-container {
  display: flex;
  margin: 10px 0;
  align-items: center;
}
.frequence-container .span {
  font-size: 15px;
}
.frequence-container .input {
  display: flex;
  align-items: center;
}
.frequence-container .input input {
  width: 50px;
  margin-right: 15px;
}
.frequence-container .input select {
  width: 100px;
}

.input-number {
  height: calc(1.5em + 0.75rem + 2px);
  margin-right: 10px;
}

.size-15 {
  font-size: 15px !important;
}

.no-flex {
  flex: none;
  margin-right: 10px;
}

.row {
  z-index: 1;
}

#select-messages {
  z-index: 5;
}

#select-marquages {
  z-index: 5;
}

.table thead th {
  vertical-align: top;
}

.alarm-schema-container {
  border-radius: 15px;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 2vh;
  display: flex;
}
.alarm-schema-container .type-alarm-container {
  width: 15%;
  border-radius: 15px 0 0 15px;
  padding: 15px;
}
.alarm-schema-container .type-alarm-container .section {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alarm-schema-container .type-alarm-container .section h3 {
  margin-bottom: 0;
}
.alarm-schema-container .alarm-mozaique-container {
  overflow-x: scroll;
  width: 85%;
  max-width: 85%;
  border-radius: 0 0 15px 0;
  height: max-content;
}
.alarm-schema-container .alarm-mozaique-container .mozaique {
  display: flex;
}
.alarm-schema-container .alarm-mozaique-container .mozaique:last-of-type .carreau {
  border-bottom: 0;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .section {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 15px;
  position: relative;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .section h3 {
  color: black;
  margin-bottom: 0;
  position: sticky;
  width: 150px;
  left: calc(50% - 75px);
}
.alarm-schema-container .alarm-mozaique-container .mozaique .carreau {
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  position: relative;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .carreau:last-of-type {
  border-right: 0;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .carreau h5 {
  margin-bottom: 0;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .carreau .circle {
  height: 25px;
  width: 25px;
  background-color: #dc3545;
  border-radius: 50%;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .carreau .line {
  width: 75px;
  position: absolute;
  height: 5px;
  background-color: #dc3545;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .carreau .line-right {
  width: 50px;
  position: absolute;
  height: 5px;
  background-color: #dc3545;
  left: 25px;
}
.alarm-schema-container .alarm-mozaique-container .mozaique .carreau .line-left {
  width: 50px;
  position: absolute;
  height: 5px;
  background-color: #dc3545;
  right: 25px;
}

.alarm-chart-container {
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 15px;
  background-color: white;
  margin-bottom: 2vh;
}
.alarm-chart-container canvas {
  height: 300px !important;
}

.no-data-container {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
  border: 1px solid lightgrey;
  border-radius: 15px;
  background-color: rgba(211, 211, 211, 0.6549019608);
}
.no-data-container h5 {
  color: black;
  margin-bottom: 0;
}

.chart-alarm-container .legend-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.chart-alarm-container .legend-container .title {
  margin-right: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.chart-alarm-container .legend-container .title .echantillon {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}
.chart-alarm-container .legend-container .title h5 {
  margin-bottom: 0;
}

.flipped-svg {
  background-color: #ebecf1;
}
.flipped-svg:hover {
  background-color: #34827a;
}

.rapport-filter {
  border: 1px solid lightgrey;
  border-radius: 10px;
}
.rapport-filter .row {
  margin-left: 5px !important;
}
.rapport-filter button {
  margin-left: 0 !important;
}

.displayChartBlock .col-md-6:nth-child(1),
.displayChartBlock .col-md-6:nth-child(2),
.displayChartBlock .col-md-6:nth-child(3) {
  margin: 10px 0;
}
.displayChartBlock .flip-card {
  margin-top: 0 !important;
}
.displayChartBlock .flip-card .flip-card-inner {
  height: 100% !important;
}

.css-26l3qy-menu {
  z-index: 10 !important;
}

.master-gateway .react-bootstrap-table {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  margin-bottom: 10px;
}
.master-gateway .react-bootstrap-table thead {
  position: sticky;
  top: -1px;
  height: 50px;
  background-color: white;
  z-index: 10;
}

.mapbox-popup-image {
  width: 20px;
}

.mapboxgl-marker {
  cursor: pointer !important;
  position: absolute !important;
}

.spider-leg-container {
  z-index: 2;
}
.spider-leg-container .spiderfier {
  position: relative;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  background-size: 34px 34px;
  background-repeat: no-repeat;
  background-position: center;
}
.spider-leg-container .red_radio {
  background-image: url("Mapbox/images/svg/red_radio.svg");
}
.spider-leg-container .red_manual {
  background-image: url("Mapbox/images/svg/red_manual.svg");
}
.spider-leg-container .red_telereleve {
  background-image: url("Mapbox/images/svg/red_tele.svg");
}
.spider-leg-container .green_manual {
  background-image: url("Mapbox/images/svg/green_manual.svg");
}
.spider-leg-container .green_radio {
  background-image: url("Mapbox/images/svg/green_radio.svg");
}
.spider-leg-container .green_telereleve {
  background-image: url("Mapbox/images/svg/green_tele.svg");
}
.spider-leg-container .purple_radio {
  background-image: url("Mapbox/images/svg/purple_radio.svg");
}
.spider-leg-container .purple_telereleve {
  background-image: url("Mapbox/images/svg/purple_tele.svg");
}
.spider-leg-container .blue_radio {
  background-image: url("Mapbox/images/svg/blue_radio.svg");
}
.spider-leg-container .blue_telereleve {
  background-image: url("Mapbox/images/svg/blue_tele.svg");
}
.spider-leg-container .yellow_manual {
  background-image: url("Mapbox/images/svg/yellow_manual.svg");
}
.spider-leg-container .yellow_radio {
  background-image: url("Mapbox/images/svg/yellow_radio.svg");
}
.spider-leg-container .yellow_telereleve {
  background-image: url("Mapbox/images/svg/yellow_tele.svg");
}

.spider-leg-container .spider-leg-line {
  background-color: #f4f4f4;
}

.spider-leg-container:hover .spider-leg-line {
  background-color: #f404f4;
}

.spider-leg-container {
  width: 1px;
  height: 1px;
  overflow: display;
  will-change: transform;
}

.spider-leg-container:hover {
  cursor: pointer;
}

.spider-leg-container .spider-leg-pin {
  position: relative;
}

.spider-leg-container .spider-leg-pin.default-spider-pin {
  position: relative;
  width: 25px;
  height: 41px;
  margin-left: -11.5px;
  margin-top: -38.5px;
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0-beta.2/images/marker-icon.png);
}

.spider-leg-container .spider-leg-line {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 2px;
  background-color: #343434;
  opacity: 0.45;
  transform-origin: bottom;
  z-index: 0;
}

.spider-leg-container:hover .spider-leg-line {
  opacity: 1;
}

/* Animations specific styles */
.spider-leg-container.animate {
  -webkit-transition: margin 0.15s linear;
  -moz-transition: margin 0.15s linear;
  -ms-transition: margin 0.15s linear;
  -o-transition: margin 0.15s linear;
  transition: margin 0.15s linear;
}

.spider-leg-container.initial,
.spider-leg-container.exit {
  margin-left: 0 !important;
  margin-top: 0 !important;
  height: 0;
}

.spider-leg-container.animate .spider-leg-line {
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
  -webkit-transition-delay: inherit;
  -moz-transition-delay: inherit;
  -ms-transition-delay: inherit;
  -o-transition-delay: inherit;
  transition-delay: inherit;
}

.spider-leg-container.animate.initial .spider-leg-line,
.spider-leg-container.animate.exit .spider-leg-line {
  height: 0 !important;
}

.unclustered {
  line-height: 0px;
  z-index: 2;
}
.unclustered div {
  padding-bottom: 10px;
  padding-top: 13px;
}

.cluster {
  z-index: 2;
  width: 280px;
}
.cluster div h3 {
  line-height: 0;
  margin-top: 5px;
  margin-bottom: 10px;
  width: 270px;
}
.cluster div div {
  padding-top: 3px;
}

.enquete-page-container .select-sortable-container {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.enquete-page-container .select-sortable-error-container {
  margin: 0.3rem 0;
  border: lightgrey 1px solid;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
}
.enquete-page-container .select-sortable-error-container p {
  margin: 0;
}
.enquete-page-container .select-sortable-error-head {
  background-color: red;
  color: white;
  border-radius: 8px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 5%;
  font-weight: bolder;
}
.enquete-page-container .select-sortable-select {
  width: 100%;
}
.enquete-page-container .select-sortable-select-error {
  border: 1px solid red;
  border-radius: 5px;
  box-shadow: 0 0 1px red;
}

#root .react-bootstrap-table {
  max-width: unset;
  overflow: unset;
}