// Override default variables before the import

//Fonts
$font-family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 0.8rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
$white: #ffffff !default;
$txts: #2c303b;
$txtsitalic: #808080;
$hover: #34827a;
$backgroundElement: #31c6b3;
$light: $white !default;
$background: #ebecf1;
$body-bg: $background !default;
$body-color: $txts !default;
$yellow-saphir: #f2ae2e;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$txts: #2c303b;
$txtsitalic: #808080;
$hover: #34827a;
$backgroundElement: #31c6b3;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $backgroundElement,
    'green-dark': $hover,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $backgroundElement !default;
$secondary: $backgroundElement !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$green: $backgroundElement;
$green-dark: $hover;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $hover,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'green': $green,
    'green-dark': $backgroundElement,
  ),
  $theme-colors
);
// Links
//
// Style anchor elements.

$link-color: $txts;
$link-decoration: none !default;
$link-hover-color: $hover !default;
$link-hover-decoration: underline !default;

//NavBar

$navbar-light-color: $white !default;
$navbar-light-hover-color: rgba($txts, 0.7) !default;
$navbar-light-active-color: rgba($txts, 0.9) !default;
$navbar-light-disabled-color: rgba($backgroundElement, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  '#',
  '%23'
) !default;
$navbar-light-toggler-border-color: rgba($hover, 0.1) !default;

@import '~bootstrap/scss/bootstrap.scss';

#root {
  height: 100%;
  font-family: 'Roboto', sans-serif !important;
}

html {
  height: 100%;
}

body {
  color: $txts;
  height: 100%;
}

.flex-box {
  display: flex;
}

.row-expansion-style th {
  display: none;
}

.parent-row {
  background: #31c6b3a6;
}

.logo-sidebar {
  width: 260px;
  margin: 0 auto;
  background: white;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;

  height: 90px;
  padding-top: 10px;
}

.sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background: $backgroundElement;
}

.sidebar .navbar-nav {
  display: inline;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.sidebar nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 260px;
  display: block;
  z-index: 1;

  .nav-active {
    color: $txts !important;
  }
}

.sidebar-item {
  margin-left: 40px;
  margin-top: 10px;

  svg {
    margin-right: 5px;
    width: 35px;
  }
}

.sidebar-mini-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.logo-sidebar-dashboard {
  width: 260px;
  background: white;
  height: 90px;
  padding-top: 10px;
}

.sidebar-mini .navbar-nav {
  display: inline;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  color: white;
}

.sidebar-mini nav {
  position: fixed;
  top: 90px;
  left: 0;
  max-width: 60px;
  height: 100%;
  display: block;
  z-index: 1;
  color: #fff;

  a {
    color: #fff;
  }

  a:hover {
    color: $txts;
  }
}

.sidebar-mini-synchro nav {
  position: fixed;
  top: 90px;
  left: 60px;
  max-width: 60px;
  height: 100%;
  display: block;
  z-index: 1;
  color: #fff;

  #sidebar-synchro {
    color: white;
  }

  a {
    color: #fff;
  }

  a:hover {
    color: $txts;
  }
}

.sidebar-locations-wrapper {
  position: fixed;
  top: 90px;
  height: 100%;
  left: 60px;
  z-index: 100;
  /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-location .navbar-nav {
  display: inline;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100%;

  a#sidebar-tournee {
    color: #333;
  }

  .nav-item {
    a#sidebar-tournee {
      color: #333;
    }

    a.nav-link {
      color: #fff;
    }

    a.nav-link:hover {
      color: #31c6b3 !important;
    }

    a.nav-active {
      color: #31c6b3 !important;
    }
  }

  .navbar-brand {
    height: 40px;
    border-bottom: 1px solid;
    width: 100%;
    line-height: 2em;
    padding: 0;
    padding-left: 10px;
    font-weight: bold;
    font-size: 18px;
  }
}

.sidebar-location nav {
  position: fixed;
  top: 90px;
  bottom: 0;
  left: 60px;
  width: 200px;
  display: block;
  z-index: 1;
  background-color: #127a6d;
}

.sidebar-tournee nav {
  position: fixed;
  top: 90px;
  bottom: 60px;
  left: 120px;
  width: 200px;
  display: block;
  z-index: 1;
  height: 100%;
  background-color: white;
  border-right: 1px solid #333;
}

.interval-date .form-control.is-invalid ~ .invalid-feedback {
  display: none;
}

#tournee-navbar {
  position: fixed;
  top: 90px;
  left: 320px;
  width: calc(100% - 320px);
  display: block;
  z-index: 1;
  height: 60px;
  background-color: #df70ee;
}

.main-panel {
  position: relative;
  width: calc(100% - 260px);
  height: 100%;
}

.main-panel header nav.navbar {
  width: calc(100% - 60px);
  display: block;
}

.container-widget {
  height: 100%;
  padding-bottom: 60px !important;
  padding-left: 10px;
}

section.dash {
  padding-top: 100px;
  //padding-bottom: 140px;
}

.widget-action {
  bottom: 0;
  width: 100%;
}

.widget-unready {
  text-align: center;
}

.clickable td {
  cursor: pointer;
}

a.navbar-brand {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

td.dashboard-cell {
  height: 60px;
  padding-bottom: 0;
  padding-top: 0;
}

td.dashboard-cell div {
  width: 100%;
  height: 100%;
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
}

.btn-bookmarked {
  font-weight: 400;
  color: #dc3545;
  background-color: transparent;
}

.btn-unbookmarked {
  font-weight: 400;
  color: #6c757d;
  background-color: transparent;
}

.dashboards-margin {
  margin-bottom: 100px;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  background-color: #ffffff;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #fafafa;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.details-device > .list-group-item {
  border: none;
  padding: 0.5rem 1rem;
  padding-left: 0;
}

.no-data {
  height: 100%;
}

#helpme {
  color: black;
  cursor: pointer;
}

#HelpMeePlease {
  background: none;
  color: $txtsitalic;
  border: none;
}

.header-item {
  min-width: 70px;
  border-right: 1px solid #ccc !important;
  display: block;
  text-align: center;
  color: $txtsitalic;
}

.header-item-last {
  min-width: 80px;
  display: block;
  text-align: center;
}

#helpmeMini {
  position: fixed;
  bottom: 100px;
  color: black;
  left: 15px;
  z-index: 20000;
  cursor: pointer;
}

.helpModal textarea {
  min-height: 300px;
}

.bottomChoice {
  //position: absolute;
  bottom: 0;
  //margin-bottom: -20px;
  margin-top: 2em;
  width: 100%;
}

.abstractInModal {
  min-height: 370px;
  position: relative;
}

.chart-container {
  width: 100%;
  margin-top: 15px;

  .alert-info {
    align-self: center;
    width: 100%;
  }
}

.presentation-nonGeo-meters {
  canvas {
    margin-top: -8vh;
  }
}

.loading-last-read {
  .alert-info {
    margin-top: 2vh;
    width: 100%;
  }
}

.chart-container-light {
  width: 100%;
  height: 250px;
  margin-top: 15px;
}

.datasourceChoices {
  min-height: 370px;
  position: relative;
  margin-bottom: 10px;
}

.templateChoices {
  font-size: 24px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.CrystalMainHeader {
  position: fixed;
  width: calc(100% - 260px);
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

.CrystalFullMainHeader {
  position: fixed;
  width: 100%;
  margin-left: -260px;
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

.CrystalMainHeaderLocations {
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

.CrystalMainHeaderDashboard {
  position: fixed;
  width: 100%;
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 20px;
}

td.crystalList-column {
  white-space: nowrap;
}

.crystalList-column th {
  white-space: nowrap;
}

#associate-alone {
  height: 30px;
  width: 30px;
  background-color: lightgrey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $hover;
  }
}

.crystalList-container {
  max-width: 1800px;

  .dropdown-menu {
    left: 50px;
    margin-top: -80px;
  }
}

.crystalList-container table {
  min-width: 0;
  white-space: nowrap;
  overflow: auto;
  display: block;
  table-layout: auto;
}

.crystalList-container-user table {
  min-width: 0;
  white-space: nowrap;
  display: block;
  table-layout: auto;
}

.breadcrumb-item.active {
  color: $backgroundElement;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: $backgroundElement;
  content: '>';
}

.table-info-container {
  .row-disabled-export {
    .selection-checkbox {
      opacity: 0.2;
    }
  }

  .error-chip {
    padding: 0 10px;
    margin: 5px;
    border-radius: 100px;
    background: white;
    border: 1px solid $danger;
  }
  .main-error-chip {
    padding: 0 10px;
    margin: 5px;
    border-radius: 100px;
    color: white;
    background: $danger;
    border: 1px solid $danger;
  }
}

.table-info-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;

  .row-user-table {
    .svg {
      height: 20px;
      width: 20px;
      align-self: center;
      margin-left: 10px;
      background-color: lightgrey;
      border: 1px solid lightgrey;
      border-radius: 50%;
      text-align-last: center;
    }
  }

  .title-chart.alarm {
    margin: 10px 0;
  }

  .margin {
    margin: 2vh 0;
  }

  .flex-box {
    display: flex;

    .title-chart {
      margin: 10px 0;

      &.right {
        margin-left: 2ch;
        cursor: pointer;
      }
    }
  }

  .table-bordered {
    border: none !important;
  }

  .text-filter {
    font-style: italic;
    color: $txtsitalic;
  }

  .btn-secondary {
    color: #fff;
    background-color: #31c6b3;
    border-color: #31c6b3;
    border-radius: 50px;
  }

  .import-silex-selection-button {
    background-color: $body-bg;
    color: $body-color;
    border-width: 0;

    &:hover,
    &.selected {
      background-color: $backgroundElement;
      border-width: 0;
      color: $light;
    }
  }

  h2 {
    border-left: 3px solid $green;
    margin-left: -22px;
    padding-left: 50px;
    padding-bottom: 5px;
    color: $txts;

    span {
      margin-left: -30px;
      margin-right: 15px;
    }
  }

  .borderDiv {
    border-right: 3px solid $hover;
  }

  h3 {
    margin-right: -22px;
    padding-right: 50px;
    padding-bottom: 5px;
    margin-top: 10px;
    text-align: center;
    vertical-align: middle;
  }

  .searchbar input {
    font-style: italic;
    color: $txtsitalic;
  }

  .add {
    cursor: pointer;
  }
}

.switch-button {
  top: 7px;
  left: 5px;
}

.information-container {
  background: white;
  border-radius: 0 0 20px 20px;
  margin-left: 10px;
  padding: 10px;
}

.information-container-header {
  background: white;
  border-radius: 20px 20px 0 0;
  margin-left: 10px;
  padding: 15px 30px;
  padding-right: 15px;
  background: url(./_svgs/background.svg);
  background-size: 896px 1080px;
  color: $green;
}

.infoLibelle {
  font-size: 0.9em;
  color: $txtsitalic;
}

.infoDisplay {
  font-size: 1.5em;
  color: $txts;
  font-weight: bold;
  position: relative;
}

.infoDisplayFit {
  font-size: 1.2em;
  color: $txts;
  font-weight: bold;
  position: relative;
}

.modal-body {
  .btn-primary {
    color: white;
  }

  .button-submit-date {
    width: 100%;

    button {
      float: right;
      width: 20%;

      &.btn-primary {
        margin-left: 10px;
      }
    }
  }
}

.alarm-type-selector {
  width: 100%;

  .flex-box {
    justify-content: center;
  }

  .text-container {
    width: fit-content;
    width: -moz-fit-content;
    text-align: center;
    overflow-x: scroll;

    div {
      margin-right: 5px;

      h5 {
        padding: 10px 5px;
        width: 100%;
        border: 1px solid $hover;
        color: white;
        margin-bottom: 1rem;
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }
}

.modal-footer {
  .btn-primary {
    color: white;
  }
}

.modal-header {
  color: $backgroundElement;
}

svg {
  pointer-events: none;
}

div .clickable {
  cursor: pointer;
}

.containerDash {
  min-width: calc(100% - 260px);
  margin-left: 260px;
  display: grid;

  .over {
    padding-left: 8px;
  }
}

.logo-sidebar-dashboard {
  position: fixed;
  top: 0;
  width: 260px;
  margin: 0 auto;
  background: white;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 300;
  height: 90px;
  padding-top: 10px;
}

.round {
  background: $body-bg;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  text-align: center;
}

.round:hover {
  background: $hover;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: white;
}

.round-disabled {
  background: $body-bg;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  text-align: center;
}

.bookmark-container {
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 0;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
  max-width: 23%;
}

.bookmark-header {
  width: 100%;
  background: url(./_svgs/background_green.svg);
  background-size: 896px 1080px;
  min-height: 60px;
  border-radius: 10px 10px 0 0;

  .bookmark-dashboard-title {
    display: block;
    padding-top: 10px;
    padding-left: 10px;
    color: white;

    a {
      margin-top: 100px;
      font-size: 0.8em;
      color: white;
    }
  }

  .bookmark-dashboard-main-title {
    font-size: 1.2em;
    display: block;
    margin-left: 10px;
    color: $txts;
  }
}

.bookmark-body {
  background: #fff;
  min-height: 150px;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  padding-top: 20px;

  img {
    height: 120px;
    width: 120px;
  }
}

.filter-container {
  background: white;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
  border: 0.5px solid #ccc;
}

.trash-filter,
.save-filter {
  margin-top: 5px;
}

.filterbutton {
  margin-left: 15px;
  background: $body-bg;
  border: none;
  color: $txts;
  border-radius: 15px;
}

#buttonAddWidget {
  .btn {
    background: none;
    border: none;
  }
}

h2 div#addWidget,
#addDashboard,
#addAlert {
  height: 30px;
  width: 30px;
  background: $body-bg;
  border-radius: 20px;
  padding: 5px;
  text-align: center;
  font-size: 0.5em;
  color: white;
  cursor: pointer;
}

h2 div#addWidget:hover,
#addDashboard:hover,
#addAlert:hover {
  background: $hover;
  border-radius: 20px;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: white;
  cursor: pointer;
}

.widget-info-container {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  border: 1px solid #ccc;

  h2 {
    border-left: 3px solid $green;
    margin-left: -33px;
    padding-left: 50px;
    padding-bottom: 5px;
    color: $txts;
    font-size: 1.5em;
    font-weight: bold;
    float: left;
    position: absolute;
    left: 30px;

    span {
      margin-left: -30px;
      margin-right: 15px;
      margin-bottom: -5px;
    }
  }

  .btn-group {
    margin-top: 10px;

    button {
      border-radius: 25px;
      border: 1px solid #808080;
      background-color: #fff;
      color: #808080;
    }
  }
}

.container-login {
  height: 100% !important;
  width: 100% !important;
  justify-content: center !important;
  flex-direction: column !important;
  display: flex !important;
  background: url(./_svgs/background.svg);
  background-repeat: repeat;
  background-size: 896px 1081px;

  .login-form {
    width: 50%;
    bottom: 0;
    position: fixed;
    right: 0;
    top: 40%;
    left: 60%;
  }

  .col {
    max-width: 50% !important;
  }

  .login-form form {
    padding: 50px;
    float: left;
    width: 60%;
    min-width: 30%;
    color: white;

    .btn-primary {
      float: right;
      color: white;
    }

    .version {
      margin-top: 50%;
      text-align: center;
    }
  }

  position: fixed;
  bottom: 0;

  div.login-logo-container {
    background: $white;
    position: fixed;
    bottom: 0;
    height: 100%;
    width: 50%;

    .logo {
      position: absolute;
      width: 60%;
      top: 45%;
      margin-left: 20%;
    }
  }
}

.presentation-container {
  margin-bottom: 10px;
  margin-left: 10px;
  padding: 0;
  background: #fff;
  border-radius: 10px 10px 10px 10px;
}

.presentation-header {
  background-size: 700px;
  width: 100%;
  background: url(./_svgs/background_green.svg);
  background-size: 896px 1080px;
  min-height: 60px;
  border-radius: 10px 10px 0 0;

  .presentation-title {
    display: block;
    padding-top: 10px;
    padding-left: 10px;
    color: white;

    a {
      margin-top: 100px;
      font-size: 0.8em;
      color: white;
    }
  }

  .presentation-main-title {
    font-size: 1.3em;
    display: block;
    margin-left: 10px;
    color: $txts;
  }
}

.presentation-header-yellow {
  background-size: 700px;
  width: 100%;
  background: url(./_svgs/background_yellow.svg);
  background-size: 896px 1080px;
  min-height: 60px;
  border-radius: 10px 10px 0 0;

  .presentation-title {
    display: block;
    padding-top: 10px;
    padding-left: 10px;
    color: white;

    a {
      margin-top: 100px;
      font-size: 0.8em;
      color: white;
    }
  }

  .presentation-main-title {
    font-size: 1.3em;
    display: block;
    margin-left: 10px;
    color: $txts;
  }
}

.yellowHover:hover {
  background-color: #96740e;
}

.presentation-body {
  background: #fff;
  padding-bottom: 10px;
  min-height: 150px;
  border-radius: 10px 10px 10px 10px;

  .display-info {
    margin-top: 20px;
  }
}

.presentation-body-light {
  background: #fff;
  padding-bottom: 10px;
  min-height: 100px;
  border-radius: 10px 10px 10px 10px;

  .display-info {
    margin-top: 20px;
  }
}

#userEdit,
#passwordChange {
  width: 30px;
  height: 30px;
  font-size: 0.6em;
  margin-left: 20px;
}

#map_canvas {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 100vh;
  margin: 0;
  padding: 0;
}

th .form-control {
  padding: 0 0 0 5px;
  font-size: 10px;
}

.presentation-container-map {
  width: 100%;
  padding-right: 20px;
  height: 340px;
  position: relative;
}

.presentation-container-map-double {
  width: 100%;
  padding-right: 20px;
  padding-top: 20px;
  height: 500px;
  position: relative;
}

.presentation-container-map-full-height {
  width: 100%;
  height: 100%;
  position: relative;
}

.presentation-container-map-meter {
  width: 100%;
  height: 100%;
  position: relative;
}

.presentation-container-map-location {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background: rgba(134, 134, 134, 0.23);
}

.presentation-container-map-meter {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  background: rgba(134, 134, 134, 0.23);
  border-radius: 10px 10px 10px 10px;
}

.react-grid-placeholder {
  background-color: $backgroundElement !important;
}

.react-draggable-dragging {
  cursor: grab;
  cursor: move;
}

#nav-mini-users {
  margin-left: -5px;
}

.containerDashFullScreen {
  height: 100vh;
  background: #000;
  overflow: auto;

  .presentation-header {
    margin-top: 15px;
  }

  .react-grid-item,
  .widget-info-container {
    background: $background;
  }
}

#addPermission {
  svg {
    margin-top: -13px;
  }
}

.lostPass {
  color: white;
}

.reset-login {
  color: white;

  .alert {
    width: 50%;
    padding-right: 15px;
    padding-left: 15px;
    margin-left: -15px;
  }

  .btn {
    float: right;
    color: white;
  }
}

.burger-user {
  margin-left: 20px;
  margin-top: -2px;

  .dropdown,
  .show {
    .btn,
    .btn-secondary {
      background: $background;
      border: none;
      border-radius: 0px;
      border-radius: 20px;
      width: 30px;
      height: 30px;
    }

    .btn:hover,
    .btn-secondary:hover {
      background: $hover;
      border: none;
      border-radius: 0px;
      border-radius: 20px;
      width: 30px;
      height: 30px;
    }

    btn:active,
    .btn-secondary:active {
      border: none;
    }

    .burger-user-icon {
      margin-left: -6px;
    }
  }
}

button.danger {
  background-color: $danger !important;
  border-color: $danger !important;
}

.virtual-meter-info {
  margin-left: 0px;
  border-radius: 15px !important;

  .presentation-header {
    border-radius: 15px 15px 0 0 !important;
    background-position: left;
  }

  .presentation-body {
    min-height: 65px;

    form {
      margin-top: 15px;
    }

    border-radius: 15px !important;
  }

  .btn-secondary {
    color: #fff;
    background-color: #31c6b3;
    border-color: #31c6b3;
    border-radius: 50px;
  }
}

.wrapper-modal-addUser {
  h5 {
    width: 70%;
    display: flex;
  }
}

#adduser {
  margin-left: 15%;

  .add {
    margin-top: -13px;
  }
}

#addTourmalineUser {
  .add {
    margin-top: -13px;
  }
}

.modal-add-user {
  display: flex;
  width: 70%;
}

.virtual-meter-editor {
  .react-bootstrap-table-pagination > div:not(.react-bootstrap-table-pagination-list) {
    width: 40% !important;
    flex: 0 0 40% !important;
  }

  .existing-meter-list {
    .react-bootstrap-table-pagination > div:not(.react-bootstrap-table-pagination-list) {
      display: none;
    }
  }
}

.react-bootstrap-table {
  input[type='checkbox']:disabled {
    display: none;
  }

  td input[type='checkbox']:enabled,
  th .all-cb {
    -webkit-appearance: none;
    background-color: #fafafa;
    border: 1px solid grey;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 8px !important;
    border-radius: 3px;
    display: inline-block;
    position: relative;
  }

  input[type='checkbox']:active,
  input[type='checkbox']:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  input[type='checkbox']:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  input[type='checkbox']:checked:after {
    font-size: 14px;
    position: absolute;
    top: 0;
    left: 1.5px;
    line-height: 14px;
  }

  .checkbox-add input[type='checkbox']:checked:after,
  .checkbox-add:checked:after {
    content: '\2714';
    color: green;
  }

  .checkbox-remove input[type='checkbox']:checked:after,
  .checkbox-remove:checked:after {
    content: '\292B';
    color: red;
  }

  .checkbox-add-all:checked:after {
    content: '\2015';
    color: green;
    font-weight: bold;
  }

  .checkbox-remove-all:checked:after {
    content: '\2015';
    color: red;
    left: 1px;
    font-weight: bold;
  }
}

.widget-info {
  margin-left: 0px;
  border-radius: 15px !important;

  .presentation-header {
    border-radius: 10px !important;
    background-position: left;
  }
}

.notification-count {
  background-color: red;
  color: white;
  height: 15px;
  width: 15px;
  border-radius: 25px;
  position: absolute;
  bottom: 5px;
  right: 25px;
  font-size: 10px;
  font-weight: bold;

  span {
    margin: auto;
  }
}

.gap-tooltip {
  z-index: 1200;
}

.title-chart {
  margin-bottom: 25px;
}

.head-widget {
  div {
    float: right;
  }
}

.simple-container {
  .fullscreen-button {
    position: absolute;
    top: 17px;
    right: 35px;
  }
}

.modal {
  .config-form label,
  .label-text {
    font-weight: bold;
  }
}

.bold-text {
  font-weight: bold;
}

/* Search bar */

.TreeView > input[type='search'] {
  width: 100%;
  height: 3em;
  font-style: italic;
  color: $txtsitalic;
  background: white;
  padding: 10px;
  border-radius: 7px;
  border: 0.5px solid #ccc;
  outline: none;
  margin-top: 10px;
}

.depth-0 li:first-child > .item {
  border-top: 0px;
}

.separator {
  height: 1em;
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

/*
filter text-filter form-control dashboard-custom-text-filter
*/

/* Elements */

.TreeView {
  white-space: nowrap;
}

.item {
  min-height: 2.5em;
  vertical-align: middle;
  line-height: 2em;
  font-size: 1em;
  border-top: 1px solid #ccc;
  padding-left: 20px;
  padding-top: 5px;
}

li.category:not(.folded) {
  padding-bottom: 10px;
}

li.category:not(.folded) ul li:first-child > .item {
  border-top: 0px;
}

.opener {
  //margin-left: 10px;
  padding: 0 10px;
}

.category {
  vertical-align: middle;
}

.TreeView ul {
  list-style: none;
}

.TreeView li {
  min-width: 100px;
  transition: all 0.25s ease-in-out;
}

.TreeView ul li a {
  color: #222;
}

.TreeView ul li > .item > a {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 55px);
  margin-right: 30px;
  padding: 10px 5px;
  text-decoration: none;
  transition: all 0.25s;
}

.TreeView ul li:not(.disabled) {
  cursor: pointer;
}

.TreeView ul li.selected > .item > a {
  color: crimson;
}

.TreeView ul li.selected > .item > a:hover {
  color: #aaa;
}

.TreeView ul li:not(.disabled) > .item > a:hover {
  color: #31c6b2bb;
}

/* Root elements */

.TreeView ul.depth-0 {
  padding: 20px 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 0.4);
  user-select: none;
  transition: all 0.25s;
}

/* Categories : Nodes with children */

.TreeView li.category > .item {
  display: block;
  padding-bottom: 5px;
  transition: all 0.25s ease-in-out;
}

.TreeView li.category:not(.folded) > .item {
  background-color: #31c6b2bb;
  margin-bottom: 5px;
  border-radius: 7px;
  color: #fff;
  width: 100%;
  border-top: 0px;
}

/* Category opener */

.TreeView .opener {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  margin-right: 10px;
  cursor: pointer;
  float: right;
}

.TreeView .opener::after {
  content: '>';
  display: block;
  transition: all 0.25s;
}

.TreeView li.category.async > .item > .opener::after {
  content: '!';
}

.TreeView .opener:hover {
  color: #31c6b2bb;
}

.TreeView li.category:not(.folded) > .item > .opener::after {
  color: white;
  transform: rotate(90deg);
}

.TreeView li.category.loading > .item > .opener::after {
  animation: spin 1s infinite;
}

/* Animations on fold / unfold */

.TreeViewTransition-enter {
  opacity: 0;
  transform: translateX(-20px) scale(0);
  transform-origin: top;
  transition: all 0.3s ease-in-out;
}

.TreeViewTransition-enter-active {
  opacity: 1;
  transform: translateX(0px) scale(1);
}

.TreeViewTransition-leave {
  transition: all 0.3s ease-in-out;
  transform-origin: center;
}

.TreeViewTransition-leave-active {
  opacity: 0;
  transform: translateX(20px) scale(0);
}

.with-border {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
}

.with-border h3 {
  border-left: 3px solid $green;
  margin-left: -22px;
  padding-left: 20px;
  padding-bottom: 5px;
  color: $txts;
}

.identityChoices small {
  font-weight: bold;
}

.addObj {
  cursor: pointer;

  .add {
    width: 1em;
    height: 1em;
  }
}

#more_info {
  padding: 0px 5px 0px 5px;
  margin-left: 10px;
}

a:not([href]):not([tabindex])[class='display_lines'] {
  cursor: pointer;
  font-weight: bold;
  color: #31c6b3;
  font-size: 12px;
  text-decoration: underline;
}

.help-next-step {
  color: #999;
  font-style: italic;
}

.sidebar-stock {
  background-color: #2c303b !important;
}

.google-suggest {
  width: 89%;
  margin-top: 2%;
  margin-left: 5%;
}

.google-suggest-pdi {
  width: 50%;
  margin-top: 2%;
  margin-left: 5%;
}

.sc-EHOje.hijnLS {
  margin-left: 5%;
  width: 50%;
}

.locationchoice {
  display: inline;
}

.index-title {
  font-size: 1.5em;
  color: black;
  padding: 10px;
  text-align: center;
  padding-bottom: 0px;
}

.locationchoicelabel {
  padding: 6px;
}

.locationchoice label {
  margin-right: 5%;
}

.btn-info {
  margin-left: 25%;
}

.table-info-container-create-pdi .row {
  margin-top: 5%;
  margin-left: 25%;
  display: inline;
}

.row .col-lg-6 {
  display: inline-block;
}

.modal-footer .btn.btn-primary button {
  visibility: hidden;
}

.create-location {
  display: block;
  width: 100%;
}

.create-pdi {
  width: 100%;
}

.col-lg-78 {
  margin-top: 5%;
  width: 100%;
}

.additif-label {
  display: flex;
}

.form-group-additif {
  width: 100%;
}

.form-group-create-pdi {
  display: inline-flex;
  width: 50%;
  margin-left: 5%;
  margin-top: 2%;
}

.form-group-complementaire {
  margin-left: 5%;
  width: 100%;
}

.row .col-lg-80 {
  display: inline-table;
  width: 30%;
}

.dropdown-toggle.nav-link::after {
  display: none;
}

.container-tab-synchro {
  width: 60%;
  margin-left: 20%;

  .table-info-container {
    h2 {
      svg {
        margin-right: 15px;
      }
    }

    .react-bootstrap-table {
      .table th {
        width: 10%;
      }

      .table td {
        width: 10%;
        text-align: left;
      }
    }
  }
}

.extra-tab-synchro {
  display: inline-flex;

  svg {
    width: 30px;
  }
}

.sidebar-mask-alarm {
  width: 100%;
  height: 7em;
  border-radius: 15px;
  background-color: white;
  display: inline-flex;
  border: 0.5px solid #ccc;

  .masque-alarm-side {
    display: flex;
    margin: auto;
    width: 20%;

    .svg {
      width: 15%;
      height: auto;
    }
  }

  .svgAlarm {
    display: inline-table;
    width: 100%;
    margin: auto;
    table-layout: fixed;

    div {
      padding: 8px;
      margin-left: 5%;
      display: inline-table;
      margin-top: 8px;
      background-color: white;
    }

    div:hover {
      background-color: #333333;
      background-size: 3em;

      svg {
        fill: #31c6b3;
      }
    }
  }

  h2 {
    color: #2c303b;
    margin: auto;
    padding-left: 5%;
  }

  .masque-vert-display {
    margin: auto;
    width: 70px;
    max-width: 50px;
    margin-right: -8%;
    overflow: hidden;
    vertical-align: middle;
  }
}

.svg-table-title {
  margin: inherit;
}

#deployFilter {
  display: inline-flex;

  h2 {
    margin: auto;
    padding-left: 5%;
    color: #2c303b;
  }

  svg {
    margin: auto;
  }
}

.dropdown-toggle.nav-link::after {
  display: none;
}

.container-tab-synchro {
  width: 60%;
  margin-left: 20%;
}

.extra-tab-synchro {
  display: inline-flex;

  svg {
    width: 30px;
  }
}

.sidebar-mask-alarm {
  width: 100%;
  height: 7em;
  border-radius: 15px;
  background-color: white;
  display: inline-flex;
  border: 0.5px solid #ccc;

  .masque-alarm-side {
    display: flex;
    margin: auto;
    width: 20%;

    .svg {
      width: 15%;
      height: auto;
    }
  }

  .svgAlarm {
    display: inline-table;
    width: 100%;
    margin: auto;
    table-layout: fixed;

    div {
      padding: 8px;
      margin-left: 5%;
      display: inline-table;
      margin-top: 8px;
      background-color: white;
    }

    div:hover {
      background-color: #333333;
      background-size: 3em;

      svg {
        fill: #31c6b3;
      }
    }
  }

  h2 {
    color: #2c303b;
    margin: auto;
    padding-left: 5%;
  }

  .masque-vert-display {
    margin: auto;
    width: 70px;
    max-width: 50px;
    margin-right: -8%;
    overflow: hidden;
    vertical-align: middle;
  }
}

.svg-table-title {
  margin: inherit;
}

#deployFilter {
  display: inline-flex;

  h2 {
    margin: auto;
    padding-left: 5%;
    color: #2c303b;
  }

  svg {
    margin: auto;
  }
}

#deployColumns {
  display: inline-flex;

  h2 {
    margin: auto;
    color: #2c303b;
    margin-left: 5px;
  }

  svg {
    margin: auto;
  }
}

.infoLibelle-drag {
  color: #31c6b3;
}

.task-content-drag {
  height: 140px;

  li {
    list-style: none;
    color: black;
    font-size: 12px;
    margin-top: 5px;
  }

  .chart-container {
    max-height: 75px;
    max-width: 150px;
    height: 75px;
    width: 150px;
    position: relative;
  }
}

#column-drag {
  z-index: 1;

  .information-container-header {
    border-radius: 5px 5px 0 0;
    margin-left: 0;

    .header-drag-column {
      vertical-align: middle;
      display: inline-flex;
      width: 100%;

      .title {
        width: 70%;
        display: flex;

        svg {
          width: 20%;
        }
      }

      .col-12 {
        .presentation-container {
          background-color: transparent;
          margin-bottom: 0;
        }
      }

      .flex-box {
        width: 100%;
        display: inline-flex;
      }

      h3 {
        color: white;
        margin: auto;
        margin-left: 20px;
      }
    }
  }

  .information-container {
    padding-top: 15px;
    border-radius: 0 0 5px 5px;
    padding-bottom: 0px;
    max-height: 590px;
    max-width: 100%;
    margin-left: 0;
    border: 1px solid lightgrey;
    border-top: none;

    .Wrapper {
      overflow-x: hidden;
    }
  }
}

.drag-associate-label {
  display: flex;
  flex-wrap: wrap;

  .row {
    color: black;
  }
}

.synchro-ul-hover {
  text-align: left;
  padding: 0;
  list-style-type: none;
  margin-bottom: 0;
}

#drag-label-container-W {
  padding: 2px;

  .title-drag-W {
    white-space: nowrap;
    margin: 0 -5px 0 10px;
    margin-top: 2px;
    color: black;
    width: auto;
    align-self: center;
  }

  svg {
    margin: auto;
    margin-left: 8px;
  }
}

#import-silex-button::after {
  content: 'Valider' !important;
}

#drag-label-container-A {
  padding: 2px;
  background-color: white;
  width: max-content;

  .title-drag-W {
    margin-left: 20%;
    white-space: nowrap;
  }

  svg {
    margin-left: auto;
  }
}

.waiting-action-drag {
  margin-top: 7px;

  b {
    color: black;
  }
}

.waiting-action-label {
  overflow: auto;
  display: flex;
  width: 100%;
}

.button-valid-drag {
  background: lightgrey;
  color: black;
  border-color: lightgrey;
  width: 50%;
  height: 20%;

  &:hover {
    color: white;
    background-color: $backgroundElement;
  }

  //padding: 7px 0;
}

.waiting-action-button {
  width: 100%;
  font-size: 15px;
  display: flex;

  .button-valid-drag {
    box-shadow: none;
    transition: none;
    -webkit-transition: none;
  }
}

.drag-and-drop {
  width: 100%;
  display: flex;
  overflow: hidden;

  .presentation-header {
    display: none;
  }

  .clearfix {
    display: none;
  }

  .presentation-body {
    display: inline-flex;
    width: 100%;
    margin-left: 20px;
    padding-bottom: 0;

    .drag-and-drop-search {
      display: inline-flex;
      margin: auto;
      text-align: center;

      h3 {
        margin: auto;
        margin-left: 15px;
        color: black;
      }

      svg {
        color: #31c6b3;
      }
    }
  }
}

.header-item {
  .nav-link {
    color: #808080;
  }

  .nav-active {
    color: #31c6b3;
  }
}

.logo-sidebar-synchronisation {
  width: 260px;
  background: white;
  height: 90px;
  padding-top: 10px;
}

.CrystalMainHeader {
  position: fixed;
  width: calc(100% - 260px);
  height: 90px;
  z-index: 200;
  background: #ffffff;
  padding-top: 0;

  &.synchronisation {
    width: 100%;

    .logo-sidebar {
      margin: 0;
    }

    .flex-box {
      width: 100%;
    }
  }

  .CrystalBreadCrumb {
    width: 50%;
    display: flex;
  }

  #head-navbar {
    width: 50%;
  }
}

.logo-sidebar-synchronisation {
  position: fixed;
  top: 0;
  width: 260px;
  margin: 0 auto;
  background: white;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 300;
  height: 90px;
  padding-top: 10px;
}

.fullContainer {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  .over {
    padding-left: 8px;
  }
}

.containerSynchro {
  width: 100%;
  padding-left: 260px;

  .over {
    padding-left: 8px;
  }

  .react-chat-widget {
    position: fixed;
    bottom: 1vh;
    right: 1vh;
    z-index: 1000;
  }
}

.containerTournees {
  min-width: calc(100% - 320px);
  margin-left: 320px;
  overflow: hidden;

  .over {
    padding-left: 8px;
  }
}

.container-tab-synchro {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0;
}

.extra-tab-synchro {
  display: inline-flex;

  svg {
    width: 30px;
  }
}

section.synchro {
  padding-top: 100px;
}

section.tournee {
  padding-top: calc(180px);
}

.title-drag-A {
  padding-left: 10px;
  margin: auto;
}

.title-drag-A:hover {
  cursor: pointer;
}

.title-drag-W {
  margin-left: 10%;
  margin: auto;
}

.synchro-button-wrapper {
  margin-top: 5%;
  padding-bottom: 5%;

  .synchro-button-pointer:hover {
    cursor: pointer;
  }
}

.droppable-zone {
  border-style: dotted;
}

.task-content-wrapper {
  width: 100%;
}

.extra-content-wrapper {
  width: 10%;
}

.placeholder-synchro {
  border: 1px solid $backgroundElement;
  border-radius: 0 0 10px 10px;
  transition: (transform 0.2s, box-shadow 0.2s);
  box-shadow: (darken($backgroundElement, 10%) 0 0 5px);

  background-color: white;
  padding: 3px 20px 3px 20px;
  border-top: none;
}

.display-info {
  width: 100%;
  background-color: transparent;
}

.display-info .infoLibelle-drag {
  width: 100% !important;
  border: 1px solid;
  border-radius: 10px 10px 0 0;
  display: inline-flex;
  background-color: white;

  &.multi {
    background: red;
    position: relative;
  }

  &.multi,
  &.multi::before,
  &.multi::after {
    /* Styles to distinguish sheets from one another */
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    border: 1px solid #bbb;
  }

  &.multi::before,
  &.multi::after {
    content: '';
    position: absolute;
    height: 95%;
    width: 99%;
    background-color: #fff;
    border: 1px solid $backgroundElement;
    border-radius: 10px;
  }

  &.multi::before {
    right: 15px;
    top: 0;
    transform: rotate(-3deg);
    z-index: -1;
  }

  &.multi::after {
    top: 5px;
    right: -5px;
    transform: rotate(3deg);
    z-index: -2;
  }

  .draggable-content-wrapper {
    width: 100%;

    h3 {
      margin-bottom: 3px;
    }

    display: inline-flex;

    .draggable-icone-format {
      display: table-cell;
      height: 15px;
      margin: auto;
      margin-right: 8px;
      margin-left: 4px;

      ul {
        padding-left: 0;
      }

      li {
        list-style: none;
      }

      .draggable-icone-row {
        display: flex;
        margin-bottom: 2px;

        .drag-point {
          margin: auto;
          height: 3px;
          width: 3px;
          border-radius: 1.5px;
          background-color: $hover;
          margin: 0 1px 0 1px;
        }
      }
    }
  }
}

.delete-round-preview-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.delete-round-preview {
  border: 2px solid red;
  border-radius: 100px;
  padding: 0 5px;
  color: red;
  margin: 5px 2px;
}

.delete-round-preview-not-allowed {
  border: 2px solid lightgray;
  background-color: lightgray;
  border-radius: 100px;
  padding: 0 10px;
  color: gray;
  margin: 5px;
}

.delete-round-preview-undeletable {
  border: 2px solid red;
  border-radius: 100px;
  background-color: red;
  padding: 0 5px;
  color: white;
  font-weight: bold;
  margin: 5px 2px;
}

.synchroSwitch {
  vertical-align: text-top;

  .react-switch-bg {
    height: 15px !important;
    width: 30px !important;
    margin-top: 21% !important;
  }

  .react-switch-handle {
    margin-left: -12px !important;
  }
}

.synchro-column {
  input::after {
    background-color: green;
  }
}

.synchro-column .checkbox-synchro:checked ~ .display-info::before {
  background-color: green !important;
}

.drag-clone-wrapper {
  width: fit-content;
  width: -moz-fit-content;
  min-width: 200px;
  height: max-content;
  min-height: 80px;
  border-radius: 10px;
  //background-color: #2c303b;
  margin: auto;

  span {
    font-size: 13px;
    color: white;
  }

  h5 {
    font-size: 18px;
    color: $backgroundElement;
    margin-left: 15px;
  }
}

.form-check {
  background-color: transparent;
}

.provided-drop {
  margin-left: 150px;
}

.header-drag-clone {
  display: flex;
  margin-left: 10px;
}

.synchro-task-header {
  margin-top: 5px;
}

.progress-bar-wrapper {
  border: 2px dashed #777;
}

.checkbox-synchro .custom-checkbox .custom-control {
  margin-top: 100%;
}

.synchro-tab-reduc:hover {
  cursor: pointer;
}

.sidebar-mask-alarm {
  width: 100%;
  height: 7em;
  border-radius: 15px;
  background-color: white;
  display: inline-flex;
  border: 0.5px solid #ccc;
  margin-bottom: 10px;

  .masque-alarm-side {
    display: flex;
    margin: auto;
    width: 20%;

    .svg {
      width: 15%;
      height: auto;
    }
  }

  .svgAlarm {
    display: inline-table;
    width: 100%;
    margin: auto;
    table-layout: fixed;

    div {
      padding: 8px;
      margin-left: 5%;
      display: inline-table;
      margin-top: 8px;
      background-color: white;
    }

    div:hover {
      background-color: #333333;
      background-size: 3em;

      svg {
        fill: #31c6b3;
      }
    }
  }

  h2 {
    color: #2c303b;
    margin: auto;
    padding-left: 5%;
  }

  .masque-vert-display {
    margin: auto;
    width: 70px;
    max-width: 50px;
    margin-right: -8%;
    overflow: hidden;
    vertical-align: middle;
  }
}

.svg-table-title {
  margin: inherit;
}

#deployFilter {
  display: inline-flex;
  margin-left: -15px;
  width: 10.7%;

  h2 {
    margin: auto;
    padding-left: 5%;
    color: #2c303b;
  }

  svg {
    margin: auto;
  }
}

.best-date-component-mask {
  width: 100%;
  display: flex;
  margin-left: 5%;

  h5 {
    color: black;
  }

  .flex-box {
    display: inline-flex;
  }

  .c32 {
    margin-right: 2%;
    width: 30%;
    margin-bottom: 0;
    align-self: center;

    .text-content {
      text-align: center;
      width: fit-content;
      width: -moz-fit-content;
      align-self: center;
      white-space: nowrap;
      height: 50%;

      h5 {
        border: 1px solid lightgrey;
        color: black;
        padding: 5px;
        cursor: pointer;
        border-radius: 5px;
      }
    }
  }
}

// BEFORE

.sidebar-stock {
  background-color: #333333 !important;
}

.button-all-associate {
  margin-left: 15px;
}

.export-pdi-content {
  color: black;
  width: 100%;
  margin-left: 0;

  .filter-content-header {
    display: flex;
  }

  .filter-container {
    padding: 10px;

    .react-bootstrap-table {
      padding: 5px;

      .table {
        border-bottom: 1px solid lightgray;
      }

      th {
        border: none;
      }
    }

    .flexbox {
      display: flex;

      .title {
        display: flex;
        padding: 5px;

        h3 {
          margin-left: 10px;
        }
      }
    }
  }

  .presentation-header {
    display: none;
  }

  .presentation-body {
    .drag-and-drop-search {
      display: flex;
      margin: auto;
      padding: 10px;
      margin-top: 5px;

      h3 {
        margin: 4px 0px;
        margin-left: 10px;
      }
    }
  }
}

// FROM 234

.row-meter-bar {
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;

  .col-lg-4 {
    padding: 0;
  }

  .col-lg-8 {
    padding-right: 0;

    .container {
      padding-right: 0;
    }
  }

  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.clean-meter-bar {
  margin-bottom: 2vh;
  width: 100%;
  display: flex;
  border: 1px solid lightgrey;
  border-radius: 10px;
  background-color: white;
  color: black;

  @media screen and (max-width: 1460px) {
    h3 {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1200px) {
    h3 {
      font-size: 12px;
    }
  }

  .flex-box {
    width: 100%;
    display: flex;
    align-self: center;
  }

  .c20 {
    width: 16.5%;
    height: 60%;

    svg {
      margin-right: 1vw;
      align-self: center;
    }

    &.background-texture {
      background: url(./_svgs/background_green.svg);
      background-size: 800px;
    }

    &.background-texture-yellow {
      background: url(./_svgs/background_yellow.svg);
      background-size: 800px;
    }

    &.left {
      border-radius: 10px 0 0 10px;
      padding: 15px;
      height: auto;
      text-align-last: center;
      display: grid;
      align-content: center;

      span {
        color: white;
      }
    }

    &.right {
      border-radius: 0 10px 10px 0;
      margin: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.b-right {
      border-right: 1px solid lightgrey;
      margin: auto 0;
    }

    .flex-box {
      display: flex;

      &.center {
        justify-content: center;
        align-items: center;
      }
    }

    .centerAlign {
      align-self: center;
    }
  }

  .c50 {
    width: 50%;

    svg {
      margin-right: 1vw;
    }

    &.background-texture {
      background: url(./_svgs/background_green.svg);
      background-size: 800px;
    }

    &.left {
      border-radius: 10px 0 0 10px;
      padding: 15px;

      span {
        color: white;
      }
    }

    &.right {
      border-radius: 0 10px 10px 0;
      padding: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.b-right {
      border-right: 1px solid lightgrey;
      margin: auto 0;
    }

    .flex-box {
      display: flex;

      &.center {
        justify-content: center;
        align-items: center;
      }
    }

    .centerAlign {
      align-self: center;
    }
  }

  .c33 {
    width: 33%;

    svg {
      margin-right: 1vw;
    }

    &.background-texture {
      background: url(./_svgs/background_green.svg);
      background-size: 800px;
    }

    &.left {
      border-radius: 10px 0 0 10px;
      padding: 15px;

      span {
        color: white;
      }
    }

    &.right {
      border-radius: 0 10px 10px 0;
      padding: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.b-right {
      border-right: 1px solid lightgrey;
      margin: auto 0;
    }

    .flex-box {
      display: flex;

      &.center {
        justify-content: center;
        align-items: center;
      }
    }

    .centerAlign {
      align-self: center;
    }
  }

  .linkpdi {
    margin: auto;
  }
}

.fixed {
  position: fixed;
}

.custom-file-label::after {
  width: 40px;

  background-size: 15px;
  content: url('./_svgs/loupe_bleu.svg') !important;
}

.fichePdi {
  padding: 0 10px;
  min-width: 20em;

  .presentation-container {
    margin-left: 0;
  }

  .table-info-container {
    border: none;
  }

  .generateMeter-pdi {
    border: 1px solid lightgrey;

    th {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-height: 1080px) {
    .chart-container {
      height: 600px;

      &.alarm {
        height: 300px;
      }

      canvas {
        position: relative;
        max-height: 100%;
      }
    }
  }

  @media screen and (max-height: 820px) {
    .chart-container {
      height: 400px;

      canvas {
        position: relative;
        max-height: 100%;
      }
    }
  }

  @media screen and (max-height: 620px) {
    .chart-container {
      height: 300px;

      canvas {
        position: relative;
        max-height: 100%;
      }
    }
  }

  .second-date-component {
    z-index: 10;
    background-color: white;
    border: 1px solid lightgrey;
    width: 100%;
    margin-bottom: 2%;
    border-radius: 10px;
    padding: 10px 0;

    @media screen and (max-width: 1460px) {
      h3 {
        font-size: 16px;
      }
    }

    @media screen and (max-width: 1200px) {
      h3 {
        font-size: 12px;
      }
    }

    .title-date {
      align-self: center;
      padding: 15px;
      width: 25%;
      display: flex;
      margin-right: 6%;

      svg {
        margin-right: 40px;
        padding-left: 15px;
      }

      h3 {
        color: black;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }

    .best-date-component {
      padding: 0;

      .c32 {
        width: 25%;
        margin-bottom: 0;
      }
    }
  }

  .bi-container {
    border-radius: 10px;
    background-color: white;
    border: 1px solid lightgrey;
    margin-left: 0;
    height: 100%;
  }

  .own-container {
    border-radius: 10px;
    width: 100%;
    background-color: white;
    border: 1px solid lightgrey;
    margin-bottom: 2%;
  }

  .flex-box {
    display: flex;
    width: 100%;

    &.infinite-box {
      overflow-x: scroll;
      justify-content: center;
    }
  }

  .flex-box-pdi {
    display: flex;
    width: 100%;
    margin-bottom: 2%;

    .map-container {
      display: grid;
      height: 100%;

      .add-coordinates-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .flex-box {
          justify-content: center;
          align-items: center;

          h2 {
            margin: 0;
          }
        }
      }
    }
  }

  .info-pdi {
    width: 35%;
    margin-right: 4%;
    height: fit-content;

    .ref-intervention {
      width: 100%;

      .ref-pdi {
        background-color: white;
        color: black;
        border-radius: 10px;
        border: 1px solid lightgrey;
        text-align: center;
        margin-left: 0;

        .flex-box {
          span {
            width: 33%;
          }

          .custom-switch {
            width: 33%;
            text-align: center;
            margin-left: 1ch;
          }
        }
      }
    }

    .presentation-container {
      margin-bottom: 4vh;
    }

    .smooth-border {
      border-radius: 10px;
    }

    .ref-pdi {
      margin-left: 7.5%;

      .form-group {
        margin-bottom: 0;
        padding: 10px 0;
      }

      span {
        margin-left: 0;
        padding-left: 0;
      }
    }

    .presentation-body {
      p {
        margin: 20px 7.5%;
      }
    }
  }

  .map-pdi {
    width: 65%;

    .presentation-container-map {
      div {
        border-radius: 10px;
      }
    }

    .ajout-gps {
      border-radius: 50%;
      background-color: white;
      padding: 5px;
      height: 3em;
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      cursor: pointer;

      svg {
        fill: $backgroundElement;
      }

      &:hover {
        background-color: $hover;

        svg {
          fill: white;
        }
      }
    }
  }

  .last-read-pdi-container {
    height: 20%;

    h3 {
      color: black;
      margin-bottom: 0;
    }

    @media screen and (max-width: 1460px) {
      h3 {
        font-size: 16px;
      }
    }

    @media screen and (max-width: 1200px) {
      h3 {
        font-size: 12px;
      }
    }

    .c25 {
      width: 25%;
      padding: 15px;

      &.flex-box {
        align-self: center;
      }

      svg {
        padding-right: 30px;
        padding-left: 15px;
      }
    }

    .c20 {
      width: 20%;
      padding: 15px;

      &.flex-box {
        align-self: center;
      }

      svg {
        padding-right: 30px;
        padding-left: 15px;
      }
    }

    .c50 {
      width: 50%;
      padding: 15px;

      &.flex-box {
        align-self: center;
      }

      svg {
        padding-right: 30px;
        padding-left: 15px;
      }
    }

    .c33 {
      width: 33%;
      padding: 15px;

      &.flex-box {
        align-self: center;
      }

      svg {
        padding-right: 30px;
        padding-left: 15px;
      }
    }

    .c15 {
      width: 15%;
      padding: 15px;
    }

    .c10 {
      width: 15%;
      padding: 15px;
    }

    .conso-container {
      .center-content {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .conso-carousel {
      background: none;
      position: relative;
      padding: 0;

      p {
        margin: 0;
        font-size: 20px;
        color: $primary;

        &:hover {
          cursor: pointer;
        }
      }

      button {
        border: none;
        background: none;
        outline: none;
        transition: 0.3s;

        &:hover {
          transition: 0.3s;
          scale: 1.3;
        }

        svg {
          padding: 0;
          scale: 1.3;
          color: $primary;
        }
      }

      .MuiMobileStepper-dotActive {
        background-color: $primary;
        scale: 1.3;
      }
    }
  }

  @media (max-width: 1450px) {
    #triangle {
      display: none;
    }
  }

  .last-read-pdi-container .horizontal-line {
    height: 40% !important;
  }

  .last-read-pdi-container .conso-display {
    height: 175px;
  }

  .conso-display {
    display: flex;
    flex-direction: row;
    color: #2e3643;

    .previousUnavailable {
      margin: 0 auto;
      font-size: 2em;
    }

    .noFilled {
      color: #2e3643;
      opacity: 0.3;
      font-weight: bold;
      font-size: 1.5em;
    }

    .real-prev-switch {
      margin: 0 10px;
      width: 40px !important;
      border-radius: 100px;
      cursor: pointer;
      box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.25);

      i {
        background: white;
        display: flex;
        justify-content: center;
        margin: 1px;
        align-items: center;
        width: fit-content;
        border-radius: 100px;
        position: relative;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }

    .real-switch-on {
      transition: 0.2s;
      background: $warning;
      color: $warning;

      i {
        left: 19px;
        animation: prev-switch-rollon 0.1s linear normal;
      }
    }

    .real-switch-off {
      background: $hover;
      color: $primary;
      transition: 0.2s;

      i {
        left: 0;
        animation: prev-switch-rolloff 0.1s linear reverse;
      }
    }

    #triangle {
      position: relative;
      height: 40px;
      width: 40px;
      margin: 0;
      transform: rotate(45deg);
    }

    .previous,
    .current {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 25px;

      h3 {
        font-size: 1.1rem;
        color: #2e3643;
      }
    }

    @media (max-width: 1220px) {
      .current,
      .previous {
        h3 {
          font-size: 0.8rem;
        }
      }
    }

    .previous {
      background-color: #8ebdb7;
    }

    .current {
      border-radius: 0 10px 10px 0;
    }

    .average {
      width: 30%;
      border-top: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: white;
      text-align: center;

      svg {
        height: 25px;
        fill: $primary;
      }

      .start-arrow {
        background: #8ebdb7;
        right: 20px;
      }

      .end-arrow {
        background: white;
        left: 20px;
      }

      h3 {
        color: #2e3643;
      }
    }

    .telereleve-radio {
      background: #91e0f2 !important;
    }

    .manuelle {
      background: #26a653 !important;
      color: white;

      h3 {
        filter: invert(1) grayscale(1) contrast(9) !important;
      }

      svg {
        fill: white !important;
      }
    }

    .unknown-method {
      background: $primary !important;
    }

    .content {
      display: flex;
      width: max-content;
      flex-direction: column;
      flex-wrap: wrap;
      height: 100%;
      justify-content: space-evenly;

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      div:nth-child(odd) {
        height: 35%;
      }
    }

    .last-read-pdi-container .content div {
      justify-content: space-evenly;
    }

    .horizontal-line {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 2%;

      .noFilled {
        font-size: 0.9rem;
      }

      div {
        width: 50%;
      }
    }
  }

  @keyframes prev-switch-rollon {
    from {
      left: 0;
    }

    to {
      left: 19px;
    }
  }

  .block-pdi-container {
    padding: 30px;
    position: relative;

    .chart-alarm-container {
      width: 100%;

      &.absolute {
        position: absolute;
        bottom: 90px;
        left: 0;
      }
    }

    .alarm-item-selector {
      position: absolute;
      bottom: 40px;
    }

    .alarm-item-selector {
      display: flex;
      width: 100%;

      h5 {
        white-space: nowrap;
      }

      .alarm-item {
        width: 10%;
        text-align: center;
      }

      svg {
        padding: 0;
        fill: $backgroundElement;
      }
    }

    .alarm-selector {
      justify-content: center;
      width: 100%;
      display: flex;
      margin-bottom: 3vh;

      .selector-item {
        border: 1px solid $backgroundElement;
        padding: 5px 10px;
        text-align: center;
        width: 11vw;

        h3 {
          margin-bottom: 0;
          white-space: nowrap;
        }
      }
    }

    .button-display-meter {
      background-color: $backgroundElement;
      border-radius: 50px;
      border: none;
      white-space: nowrap;
      margin: 3vh 50% 3vh calc(50% - 80px);
    }

    h3 {
      color: black;
    }

    svg {
      padding-right: 30px;
    }

    .c32 {
      width: 32%;

      &.margTop {
        margin-top: 2vh;
      }
    }

    .table-info-container {
      .nav-link {
        color: black;
      }

      svg {
        padding-right: 0;
      }
    }

    .button-wrapper {
      margin: 15px 0;

      button {
        background-color: $backgroundElement;
        border-radius: 50px;
        border: none;
        margin-right: 10px;
      }
    }

    .old-meter {
      background-color: lightgrey;

      h3 {
        padding: 10px;
      }
    }
  }

  .second-date-component {
    .flex-box .title-date {
      width: 20%;
      align-self: auto;
      align-items: center;
      margin-right: 0;
      border-right: 1px solid lightgrey;
      padding: 0 2%;
    }
    .conso-display {
      width: 100%;
      padding-top: 10px;

      .horizontal-line {
        justify-content: center;

        div {
          text-align: center;
        }

        h3 {
          margin: 0;
        }

        .noFilled {
          font-size: 0.8rem;
          margin: 0 auto;
        }
      }

      .previous,
      .current {
        width: 30%;
        min-height: 80px;
      }

      .current {
        border-radius: 0 0 9px 0;
      }

      .average {
        min-width: 40%;
        align-items: center;

        .content {
          flex-direction: row;
          width: 80%;
          height: fit-content;
        }
      }
    }
  }

  #fixed-date-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    .title-date {
      width: 20%;
      align-self: auto;
      align-items: center;
      margin-right: 0;
      border-right: 1px solid lightgrey;
      padding: 0 2%;
    }

    @media (max-width: 1730px) {
      .title-date h3 {
        font-size: 20px;
      }
    }

    @media (max-width: 1575px) {
      .title-date h3 {
        font-size: 15px;
      }
    }

    @media (max-width: 1300px) {
      .title-date h3 {
        font-size: 13px;
      }
    }

    @media (max-width: 1150px) {
      .title-date h3 {
        font-size: 10px;
      }
    }

    .best-date-component {
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }

    .svg-container svg {
      margin-right: 10px;
      padding-left: 0;
    }

    .conso-display-disabled {
    }
  }
}

.best-date-component {
  padding: 20px 0;
  width: 100%;

  .flex-box {
    display: flex;
  }

  .c32 {
    margin-right: 2%;
  }
}

.chart-wrapper {
  display: flex;
  justify-content: center;
}

.old-meter-bar {
  display: flex;

  .c15 {
    width: 15%;
    border-bottom: 1px solid lightgrey;
    margin-right: 3vw;
    margin-top: 2vh;

    h5 {
      color: black;
      font-size: 13px;
      margin-bottom: 3vh;
    }

    .pad10 {
      padding: 10px;
    }
  }
}

.pdi-alarm {
  width: 100%;

  .c100 {
    padding: 20px 0;
    width: 60%;

    svg {
      padding-right: 0;
    }
  }
}

.old-meter-displayer {
  padding: 0;
  padding-top: 0;

  .row-meter-bar {
    text-align: left;
  }
}

.pdi-old-meter {
  .btn-group {
    width: 100%;
    margin: 0 30px;
    height: 60px;
    overflow-x: scroll;
  }

  h3 {
    align-self: center;
  }

  h4 {
    align-self: center;
    display: contents;
  }

  .flex-box {
    padding: 0 30px;
    justify-content: center;
  }
}

.export-pdi-content {
  color: black;
  width: 100%;
  margin-left: 0;

  .filter-content-header {
    display: flex;
  }

  .filter-container {
    padding: 10px;

    .react-bootstrap-table {
      padding: 5px;

      .table {
        border-bottom: 1px solid lightgray;
      }

      th {
        border: none;
      }
    }

    .flexbox {
      display: flex;

      .title {
        display: flex;
        padding: 5px;

        h3 {
          margin-left: 10px;
        }
      }
    }
  }

  .presentation-header {
    display: none;
  }

  .presentation-body {
    .drag-and-drop-search {
      display: flex;
      margin: auto;
      padding: 10px;
      margin-top: 5px;

      h3 {
        margin: 4px 0px;
        margin-left: 10px;
      }
    }
  }
}

#deleteParentSite {
  margin-left: 0;
}

.synchro-gestionnaire {
  li {
    border-bottom: 1px solid #ccc;
  }

  span {
    border-top: 0;
  }
}

.sidebar-synchro {
  li {
    svg {
      margin-right: 5px;
    }
  }
}

.synchro-batterie,
.battery-component {
  padding: 0px 20px 0px 20px;

  svg {
    transform: rotate(180deg);
  }
}

.synchro-date {
  width: 50%;
  padding: 5px 5px 5px 5px;
  font-size: 15px;

  .synchro-date-box {
    color: black;
    align-items: center;
    justify-content: center;
  }
}

.synchro-batterie {
  width: 50%;
  display: flex;
  justify-content: center;

  .battery-component {
    width: fit-content;
    width: -moz-fit-content;
    display: flex;

    .lvl-battery {
      background-color: red;
      width: 36px;
      height: 16px;
      position: absolute;
      margin: 3px;
    }
  }
}

.synchro-version {
  font-size: 15px;
  width: 50%;
  color: black;
  border-left: 1px solid lightgrey;

  .synchro-version-container {
    padding: 10px 0px 0px 5px;
  }
}

.multi-drag-bar {
  border: 1px solid lightgrey;
  height: calc(100vh - 114px);
  border-radius: 5px;
  background-color: white;
  width: 5%;
  padding: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    z-index: 50;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid $backgroundElement;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      fill: #31c6b3;
    }

    &:hover {
      transition: 0.25s;
      background-color: white;
      width: 180px;
      border-radius: 10px;
      border: 2px solid #31c6b3;
      transform: translateX(-60px);

      svg {
        fill: #31c6b3;
        margin-right: 5px;
      }
    }

    &.checked {
      background-color: #31c6b3;

      svg {
        fill: white;
      }
    }

    .span-button {
      width: auto;

      h5 {
        margin-bottom: 0;
      }

      @keyframes display-span {
        from {
          width: 0%;
        }

        to {
          width: 90%;
        }
      }
    }
  }

  .svg-container {
    margin-top: 15px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .switched-button {
    float: right;
  }

  .synchro-button-wrapper-column {
    position: absolute;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button-bottom-column {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      cursor: pointer;
      border: none;

      .body-container {
        display: flex;
        align-items: center;
      }

      &:hover {
        transition: 0.25s;
        width: 130px;
        border-radius: 10px;
        background-color: white;
        border: 2px solid;
        transform: translateX(-35px);
      }

      h5 {
        color: white;
        margin-bottom: 0;
      }

      .span-button {
        width: auto;

        h5 {
          margin-bottom: 0;
        }

        @keyframes display-span {
          from {
            width: 0%;
          }

          to {
            width: 90%;
          }
        }
      }
    }
  }
}

.column-synchronisation {
  .col-12 {
    padding: 0;
  }

  .Wrapper {
    overflow-x: hidden;
  }

  .DefaultContainer {
    z-index: 1;
  }
}

.drag-title-clone {
  padding: 2px;
  margin-right: 5px;
  padding-right: 5px;
  white-space: nowrap;
  border-radius: 5px;
}

.synchro-online {
  width: 50%;
  padding: 0px 20px 0px 20px;
  border-left: 1px solid lightgrey;
}

.backSidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

//LOCATIONS CSS

.containerLocs {
  min-width: calc(100% - 260px);
  margin-left: 260px;
  padding: 10px;

  .over {
    padding-left: 8px;
  }
}

.locationContainer {
  .crumbContainer {
    .crumbContent {
      margin: 1px 0 0 0;
      width: fit-content;
      width: -moz-fit-content;
      white-space: nowrap;
    }

    .home {
      height: fit-content;
    }

    .crumbTooltip {
      position: relative;
      display: inline-block;
    }

    .crumbTooltip .tooltipText {
      visibility: hidden;
      width: fit-content;
      width: -moz-fit-content;
      background-color: #555;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      bottom: 125%;
      left: 50%;
      opacity: 0;
      transition: opacity 0.3s;
    }

    .crumbTooltip .tooltipText::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    .crumbTooltip:hover .tooltipText {
      visibility: visible;
      display: block;
      opacity: 1;
    }
  }

  //SLICK
  .slick-list {
    background-color: $gray-200;
  }

  .slick-dots {
    > li {
      display: inline-block;
    }

    > li:only-child {
      display: inline-block;
    }
  }

  .slick-track {
    height: 500px;

    div {
      height: 100%;
    }
  }

  .slick-prev:before {
    color: $primary;
  }

  .slick-next:before {
    color: $primary;
  }

  .locationsList {
    overflow-y: auto;
    background-color: #ffffff;
  }

  .slick-slide > div {
    margin: 0 1px;
  }

  .slick-list {
    margin: 0 -1px;
  }

  .addLocationButton {
    position: absolute;
    bottom: 30px;
    right: 20px;
    cursor: pointer;

    .round {
      height: 30px;

      .absoluteCentered {
        padding: 0 0 3px 0;
      }
    }
  }

  .noChildLocation {
    padding: 15px;
    color: darkGray;
    font-style: italic;
  }

  //END SLICK

  .slick-slider {
    border-top: 1px solid lightgrey;
  }

  .locBrowser {
    background: #ffffff;
    border: 1px solid lightgrey;
    border-radius: 15px;
    height: 100%;

    .rowListLocation {
      height: 100%;
      margin: 0;
      width: max-content;
    }
  }

  .addWidget {
    padding: 0;
  }

  div.scrollLocBrowser > div:last-child > div:last-child > .locationsList {
    border-right: 0px;
  }

  .addLocationButton {
    position: absolute;
    bottom: 30px;
    right: 20px;
    cursor: pointer;
  }

  .noLocationFoundError {
    margin: -15px 0 0 5px;
    font-size: 10px;
    color: red;
    height: 20px;
  }

  .locationParentBlock {
    border: silver;
  }

  .locationShowInfo {
    background: #ffffff;
    margin: 0;
    border-radius: 15px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    border: 1px solid #ccc;

    h4 {
      padding-top: 15px;
      color: black;
    }

    .mapContainer {
      height: 100%;
      padding: 0 0 60px 0;
    }

    #map_locations {
      position: relative;
      width: 100%;
      height: 220px;
      margin: 0;
      padding: 0;

      // remove the link only
      a[title='Report errors in the road map or imagery to Google'] {
        display: none !important;
      }

      a[title='Signaler à Google une erreur dans la carte routière ou les images'] {
        display: none !important;
      }
    }
  }

  .locationNotSelected {
    background: $gray-200;
    border-radius: 15px;
    border: 1px solid #ccc;

    p {
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .locationsTitleInfo {
    background-size: 700px;
    width: 100%;
    background: url(./_svgs/background_green.svg);
    background-size: 896px 1080px;
    min-height: 60px;
    border-radius: 10px 10px 0 0;
  }

  #locationListContainer {
    position: absolute;
    top: 0;
    padding: 15px;
    height: 100%;
    width: 100%;

    .footer-content {
      position: absolute;
      bottom: 60px;
      right: 0;
      margin: 25px 15px 15px 15px;
    }
  }

  .childLocationTitle {
    padding: 5px;
    margin: 0;
    min-height: 35px;
    background-color: $gray-200;

    h6 {
      color: $black;
      margin: 0;
      padding-top: 5px;
      padding-left: 15px;
    }
  }

  .height100 {
    height: 100%;
  }

  .anySelectedLocation {
    background: #ccc;
  }

  .react-autosuggest__container {
    position: relative;

    ::placeholder {
      color: darkgray;
      font-style: italic;
    }

    .icon {
      position: absolute;
      top: 22px;
      right: 5px;
      width: 15px;
      height: 15px;
    }
  }

  .react-autosuggest__input {
    width: 100%;
    height: 30px;
    padding: 10px 40px 10px 20px;
    font-weight: 300;
    border: 1px solid #aaa;
    border-radius: 4px;
    margin: 15px 0;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 45px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
}

.statistique-tournee {
  width: 100%;
  display: flex;
  padding-left: 30px;
  flex-wrap: wrap;

  .doughnut-chart {
    width: 48%;
    margin: 0 2% 2% 0;

    &.bar {
      width: 100%;

      canvas {
        max-height: 300px;
      }
    }

    .chart-container {
      height: 100%;
      background-color: white;
      border-radius: 15px;
      padding-top: 10px;

      h3 {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}

.maskAlarmComponent {
  padding: 0 15px 0 5px;

  h2 {
    padding-left: 20px;

    span {
      margin-left: 10px;
    }
  }

  .maskSelectedArrow {
    position: absolute;
    right: 15px;
    bottom: 10px;
    transform: rotate(90deg);
  }

  .maskUnselectedArrow {
    position: absolute;
    right: 10px;
  }

  .maskAlarmList {
    cursor: pointer;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    margin-bottom: 1px;
  }

  .spacingBlock {
    margin-top: 20px;
  }

  .maskContainer {
    margin-left: 10px;
  }

  .maskDate {
    font-size: 18px;
    color: black;
  }
}

.modal-mask-alarm {
  margin-bottom: 6px;
}

.selection-component {
  width: 100%;
  border: solid 1px lightgrey;
  background-color: white;
  border-radius: 15px;

  & .preview {
    text-align: center;

    h4 {
      margin: 0 2vw;
      color: black;
    }
  }

  .items-container {
    padding: 20px 0px;

    .item-selector {
      width: 20%;
      text-align: center;
      margin: 0 10%;

      h3 {
        color: black;
        margin-bottom: 0;
        border: 1px solid $backgroundElement;
        padding: 5px 0;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

.alarm-configurator {
  padding: 0 20px;

  .selection-component {
    margin: 10px 0;
  }

  a:link {
    color: white;
    text-decoration: none;
  }

  a:visited {
    color: white;
    text-decoration: none;
  }
}

.button-alarm-configurator {
  a:link {
    color: white;
    text-decoration: none;
  }

  a:visited {
    color: white;
    text-decoration: none;
  }
}

.user-selector {
  width: 100%;

  .filter-container {
    padding: 30px 25px;

    .title {
      cursor: pointer;

      svg {
        align-self: center;
        fill: black;
        height: 1.5em;
      }

      h2 {
        align-self: center;
        margin-bottom: 0;
        color: black;
        margin: 0 10px;
      }
    }

    .user-list {
      width: 35%;
      margin-top: 15px;
    }
  }
}

.alarm-option-form {
  .form-control.is-valid {
    background-image: none;
  }

  .form-control.is-invalid {
    background-image: none;
  }

  &.filter-container {
    padding: 30px 25px;
  }

  .rc-slider-track {
    background-color: $backgroundElement;
  }

  .title {
    svg {
      align-self: center;
      fill: black;
      height: 1.5em;
    }

    h2 {
      align-self: center;
      margin-bottom: 0;
      color: black;
      margin: 0 10px;
    }
  }

  h5 {
    font-weight: bold;
    color: black;
  }

  .calcul-method {
    margin-top: 2vh;
  }

  .name-input {
    width: 40%;
  }

  .wrapper {
    margin-top: 2vh;
  }

  .range-wrapper {
    .MuiSlider-root {
      color: $backgroundElement;
      padding-bottom: 0;
    }
  }

  .ref-witness-selector {
    width: 40%;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $backgroundElement;
  }

  .MuiInputBase-root {
    &:hover {
      border-bottom-color: $backgroundElement;
    }
  }

  .MuiInput-underline {
    &:before {
    }

    &:after {
      border-bottom-color: $backgroundElement;
    }

    &:before:hover {
      border-bottom-color: $backgroundElement;
    }
  }
}

.MuiSlider-root {
  &.MuiSlider-colorPrimary {
    color: $backgroundElement;
  }
}

.selected-content {
  margin: 2vh 0;

  a:link {
    color: #2c303b;
    text-decoration: none;
  }

  a:visited {
    color: #2c303b;
    text-decoration: none;
  }

  .select-option {
    padding: 20px;
    background-color: white;
    border-radius: 0 10px 10px 10px;
    border: 1px solid lightgrey;
    margin-top: -1px;

    .preview {
      text-align: center;
      background-color: white;
      border-radius: 10px;
      padding: 10px 0;

      h4 {
        margin-bottom: 0;
      }
    }
  }

  .filter-container {
    margin-top: 10px;
  }

  .selection-component {
    border: none;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    //box-shadow: rgb(49, 198, 179) 5px 1px 5px 0px;

    .c32 {
      cursor: pointer;
      width: 25%;
      text-align: center;
      padding: 20px 0;
      border-radius: 5px 5px 0 0;
      border: 1px solid lightgrey;
      display: flex;
      justify-content: center;

      .space-svg {
        margin-right: 2vh;
      }

      svg {
        align-self: center;
      }

      h3 {
        margin-bottom: 0;
      }
    }

    .c33:not(:last-child) {
      cursor: pointer;
      width: 33%;
      text-align: center;
      padding: 10px 0;
      border-radius: 5px 5px 0 0;
      border: 1px solid lightgrey;
      display: flex;
      justify-content: center;

      .space-svg {
        margin-right: 2vh;
      }

      svg {
        align-self: center;
      }

      h3 {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .c50 {
      cursor: pointer;
      width: 50%;
      text-align: center;
      padding: 10px 0;
      border-radius: 5px 5px 0 0;
      border: 1px solid lightgrey;
      display: flex;
      justify-content: center;

      .space-svg {
        margin-right: 2vh;
      }

      svg {
        align-self: center;
      }

      h3 {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .c33:last-child {
      cursor: pointer;
      width: 34%;
      text-align: center;
      border-radius: 5px 5px 0 0;
      border: 1px solid lightgrey;
      display: flex;
      justify-content: center;

      .space-svg {
        margin-right: 2vh;
      }

      svg {
        align-self: center;
      }

      h3 {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}

.mask-alarm {
  .filter-label {
    display: block;
    margin-bottom: 0;
  }

  th {
    vertical-align: top !important;
  }

  #date-filter-comparator-startDate {
    display: none !important;
  }

  #date-filter-comparator-endDate {
    display: none !important;
  }

  label {
    margin-bottom: 0;
  }
}

.noFilled {
  color: lightgrey;
  font-style: italic;
  font-size: 1em;
}

.infoDisplayMeters {
  font-size: 2em;
}

.modal-addwidget-container {
  @media screen and (min-width: 1300px) {
    max-width: 1100px;
  }

  .modal-add-widget {
    .content {
      height: 100%;

      .widget-card {
        width: 47%;
        cursor: pointer;
        display: flex;
        height: 90px;

        img {
          height: 100%;
          width: auto;
        }

        .card-info {
          display: grid;
          width: 60%;
        }
      }
    }
  }
}

.vmeter-tab {
  label {
    display: block;
    margin-bottom: 0;
    width: 40%;
  }

  th {
    vertical-align: top !important;
  }
}

#locationDelete {
  position: absolute;
  right: 25px;
  top: 15px;
  border: 0;
}

.dropDown-add-perm {
  margin: 10px 0;
}

.locationContainerSlick {
  .slick-track {
    height: 500px;

    div {
      height: 100%;
    }
  }
}

//Flip card in FicheDetail
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  height: 100%;
  perspective: 1000px;
  /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  //transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

.user-permissions-container {
  margin: 3vh 0;
  width: 100%;

  .permission-colonne {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 10px;
    width: 47.5%;

    .colonne-header {
      border-bottom: 1px solid $backgroundElement;
      padding: 10px;

      h3 {
        color: black;
        margin-bottom: 0;
      }
    }

    .body-element {
      border: 1px solid lightgrey;
      padding: 10px 20px;
      margin: 10px 0;
      border-right: 0;
      border-left: 0;

      h4 {
        margin-bottom: 0;
      }

      .circle {
        align-self: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        margin-right: 5px;

        &.right {
          float: right;
        }
      }
    }
  }
}

.profil-modal-footer {
  .btn-success {
    background-color: $backgroundElement;
    border-color: $backgroundElement;

    &:hover {
      background-color: $hover;
      border-color: $hover;
    }
  }
}

td {
  .MuiFormControl-root {
    width: 50%;
  }
}

.profil-container {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 20px;
  width: 100%;

  &.user {
    margin-top: 4vh;

    .profil-footer {
      button {
        float: right;
        background-color: $backgroundElement;
        border-color: $backgroundElement;
      }
    }
  }

  .profil-header {
    h2 {
      margin-bottom: 0;
      margin-left: 15px;
      color: black;
    }

    svg {
      align-self: center;
    }

    .add-container {
      height: fit-content;
      align-self: center;
      width: 100%;

      div {
        float: right;
        margin-right: 10px;
        background-color: lightgrey;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background-color: $hover;
        }
      }
    }
  }

  .profil-body {
    margin-top: 3vh;

    .MuiInput-underline:before {
      border-bottom: 1px solid $backgroundElement;
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid $backgroundElement;
    }

    .react-bootstrap-table {
      th {
        border-top: none;
      }

      .delete-profil-row {
        cursor: pointer;
        background-color: lightgrey;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;

        svg {
          align-self: center;
        }

        &:hover {
          background-color: $hover;
        }
      }
    }
  }
}

.filter-label {
  display: block;
}

.center-td {
  text-align: 'center';
  vertical-align: 'middle';
}

.source-sheet-date-component {
  .best-date-component {
    padding: 0;
  }
}

.roundConso {
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 180px;
  width: 180px;
  border-radius: 100%;
  padding: 15px 0;
  background-color: white;
  text-align: center;
  color: #333;
  z-index: 10;
  font-size: 14px;
  -webkit-box-shadow: 0px 0px 6px 3px rgba(196, 196, 196, 1);
  -moz-box-shadow: 0px 0px 6px 3px rgba(196, 196, 196, 1);
  box-shadow: 0px 0px 6px 3px rgba(196, 196, 196, 1);
}

.nbConsumption {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.nbXl {
  font-size: 5em;
}

.nbL {
  font-size: 3.5em;
}

.nbM {
  font-size: 3.1em;
}

.nbS {
  font-size: 2.8em;
}

.nbXs {
  font-size: 2.2em;
}

.arrowContainer {
  align-self: center;
  padding: 0;
  text-align: -webkit-right;
  text-align: -moz-right;
}

.absoluteCentered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.absoluteVerticalCenter {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.absoluteHorizontalCenter {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

//Zoom effect on relative element
.containerZoom {
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.5s;
  transform: translate(-50%, -50%) scale(0.2);

  .infoToShow {
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    display: none;
  }

  .svgInfo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(4);
  }
}

.containerZoom:hover {
  .svgInfo {
    display: none;
  }

  .infoToShow {
    display: flex;
    opacity: 1;
  }

  transform: translate(-50%, -50%) scale(1);
}

#triangle {
  width: fit-content;
  width: -moz-fit-content;
  margin: auto;

  .arrow {
    width: 30px;
    height: 30px;
    border: solid lightgray;
    border-width: 0 1px 1px 0;
    background-color: white;
    display: inline-block;
    padding: 3px;
  }

  .right {
    margin-left: -14px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .left {
    margin-right: -14px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .up {
    margin-bottom: -14px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    margin-top: -14px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

.consumption {
  width: 65%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #2c303b;
  border-radius: 10px;
  padding: 3% 2%;
  margin-top: -10px;

  .conso-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  h3 {
    font-weight: lighter;
    font-size: 1.2rem;
    margin: 0;
  }

  .avg {
    font-weight: bold;
  }

  .consoRange {
    color: white;
    opacity: 0.5;
    margin: 0 2%;
  }

  .interval_symbol {
    color: white;
    opacity: 0.5;
    font-size: 1.3rem;
    margin: 0 5px;
  }

  .consoOver {
    color: white;
    opacity: 1;
  }

  .limitContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .warning-limit {
    font-size: 20px;
    margin: 0 5px;
  }

  div {
    color: white;
  }

  @media screen and (max-width: 1920px) {
    .nbXl {
      font-size: 3.5em;
    }

    .nbL {
      font-size: 2.7em;
    }

    .nbM {
      font-size: 2.3em;
    }

    .nbS {
      font-size: 1.7em;
    }

    .nbXs {
      font-size: 1.7em;
    }
  }

  @media screen and (max-width: 1680px) {
    .nbXl {
      font-size: 3.3em;
    }

    .nbL {
      font-size: 2.5em;
    }

    .nbM {
      font-size: 1.9em;
    }

    .nbS {
      font-size: 1.4em;
    }

    .nbXs {
      font-size: 1.4em;
    }
  }

  @media screen and (max-width: 1444px) {
    h3 {
      font-size: 1.2em;
    }

    .nbXl {
      font-size: 3em;
    }

    .nbL {
      font-size: 2.3em;
    }

    .nbM {
      font-size: 1.7em;
    }

    .nbS {
      font-size: 1.3em;
    }

    .nbXs {
      font-size: 1.3em;
    }
  }

  @media screen and (max-width: 1366px) {
    .nbXl {
      font-size: 2.9em;
    }

    .nbL {
      font-size: 2.1em;
    }

    .nbM {
      font-size: 1.6em;
    }

    .nbS {
      font-size: 1.2em;
    }

    .nbXs {
      font-size: 1.2em;
    }
  }

  @media screen and (max-width: 1280px) {
    .nbXl {
      font-size: 2.7em;
    }

    .nbL {
      font-size: 2em;
    }

    .nbM {
      font-size: 1.4em;
    }

    .nbS {
      font-size: 1em;
    }

    .nbXs {
      font-size: 1em;
    }
  }
}

.indexWrapped {
  @media screen and (max-width: 1920px) {
    h3 {
      font-size: 1.3em;
    }
  }

  @media screen and (max-width: 1680px) {
    h3 {
      font-size: 1.2em;
    }

    .DigitRoll__Cell {
      font-size: 1.5em;
      width: 1.5rem !important;
    }
  }

  @media screen and (max-width: 1444px) {
    h3 {
      font-size: 1.1em;
    }
  }

  @media screen and (max-width: 1366px) {
    h3 {
      font-size: 1em;
    }

    .DigitRoll__Cell {
      width: 1.4rem !important;
      height: 2.5rem;
    }
  }

  @media screen and (max-width: 1280px) {
    .DigitRoll__Cell {
      font-size: 1.2em;
      width: 1rem !important;
      height: 2.5rem;
    }
  }
}

.indexWrappedLight {
  @media screen and (max-width: 1920px) {
    h3 {
      font-size: 1.2em;
    }

    .date {
      font-size: 1em;
    }

    .DigitRoll__Cell {
      font-size: 1.5em;
      width: 1.5rem !important;
    }
  }

  @media screen and (max-width: 1680px) {
    h3 {
      font-size: 1em;
    }

    .date {
      font-size: 0.9em;
    }

    .DigitRoll__Cell {
      font-size: 1.4em;
      width: 1.2rem !important;
    }
  }

  @media screen and (max-width: 1444px) {
    .DigitRoll__Cell {
      font-size: 1.3em;
      width: 1.1rem !important;
    }
  }

  @media screen and (max-width: 1366px) {
    .DigitRoll__Cell {
      font-size: 1.3em;
      width: 1rem !important;
    }
  }

  @media screen and (max-width: 1280px) {
    .DigitRoll__Cell {
      font-size: 1.2em;
      width: 0.8rem !important;
    }
  }
}

.DigitRoll__Cell {
  margin: 0 1px;
  background: #333;
  font-size: 2em;
  color: white;
}

.DigitRoll__Divider {
  padding: 0 !important;
}

.DigitRoll {
  border: none !important;
}

.poseIndex {
}

.consMeter {
  @media screen and (max-width: 1920px) {
    .meterInfo {
      .infoDisplay {
        font-size: 1.3em;
      }
    }
  }

  @media screen and (max-width: 1680px) {
    .meterInfo {
      .infoDisplay {
        font-size: 1.2em;
      }
    }
  }

  @media screen and (max-width: 1444px) {
    h2 {
      font-size: 1.8em;
    }

    .picto {
      display: none;
    }
  }

  @media screen and (max-width: 1366px) {
  }

  @media screen and (max-width: 1280px) {
  }
}

.ficheDetailMeter {
  @media screen and (max-width: 1920px) {
  }

  @media screen and (max-width: 1680px) {
  }

  @media screen and (max-width: 1444px) {
  }

  @media screen and (max-width: 1366px) {
    .presentation-main-title {
      font-size: 1.2em;
    }
  }

  @media screen and (max-width: 1280px) {
    .presentation-main-title {
      font-size: 1.1em;
    }
  }
}

.add-profil {
  width: 100%;

  div {
    float: right;
    display: flex;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: lightgrey;
    border-radius: 15px;
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      background-color: $hover;
    }

    svg {
      align-self: center;
    }
  }
}

.mobile-chat {
  height: 500px;
  width: 300px;
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 15px;

  .header-chat {
    height: 15%;
    background-color: $backgroundElement;
    border-radius: 15px 15px 0 0;
    display: flex;
    justify-content: center;

    h3 {
      align-self: center;
      color: white;
    }
  }
}

.modal-profil {
  .site {
    width: 49%;
    margin-right: 2%;
  }

  .profil {
    width: 49%;
  }
}

.tourneeFicheTable {
  .react-bootstrap-table {
    //height: 100%;
    overflow: hidden;
  }

  table {
    height: 100%;

    thead {
      th {
        background-color: white;
        border: 0;
      }
    }

    th {
      position: sticky;
      top: 0;
    }
  }
}

.device-container {
  .logo {
    height: 20%;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .container-login {
    height: 80% !important;
  }
}

.synchro-device {
  height: 100%;
  overflow: hidden;

  .header {
    display: flex;
    padding: 15px;
    align-items: center;
    background-color: #333;

    h3 {
      margin-bottom: 0;
      margin-left: 10px;
      width: 80%;
      color: white;
    }
  }

  .body {
    height: 100%;
    padding: 15px;
    background-image: linear-gradient(to top, #31c6b3, #239eab, #40768d, #465161, #333333);

    .spinner-container {
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;

      .round-spinner {
        width: 5rem;
        height: 5rem;
        color: #31c6b3;
      }
    }

    .synchro-button {
      width: calc(100% - 30px);
      bottom: 0.5rem;
      position: fixed;
      text-align: center;

      button {
        border-radius: 30px;
        background-image: linear-gradient(to right top, #31c6b3, #59c88d, #8bc663, #bebe3d, #f2ae2e);
        width: 50%;
        height: 4em;
        border: none;
      }

      h5 {
        margin-bottom: 0;
      }
    }

    .tri-container {
      width: 70%;
      display: flex;
      align-items: center;
      margin: 1rem 0;

      input[type='text'],
      input[type='number'],
      textarea {
        font-size: 16px;
      }

      .filter-name {
        background-color: transparent;
        color: white;
        width: 70%;
        border: 1px solid #31c6b3;
      }

      .svg-filter {
        margin-left: 10%;
        width: 20%;
        text-align: center;
        padding: 3px;
        border-radius: 5px;
      }

      .open-fleche {
        position: absolute;
        right: 0;
        background-color: $backgroundElement;
        padding: 5px 15px 5px 10px;
        border-radius: 15px 0 0 15px;
      }
    }

    .separator {
      width: 80%;
      border-bottom: 1px solid #31c6b3;
      height: 0;
      margin: 2rem 0 2rem 10%;
    }

    .rounds-container {
      overflow: auto;
      max-height: 75%;
      padding: 5px;

      .information-container-device {
        h5 {
          color: white;
          font-weight: normal;
        }
      }

      .round-device {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        height: 100px;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: white;
        //background-image: linear-gradient(to right, #40768d, #446c83, #466378, #475a6d, #465161);
        -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);

        .point-container {
          width: 10%;

          .point {
            height: 10px;
            width: 10px;
            border-radius: 9999px;
          }
        }

        .title {
          width: 20%;
        }

        .infos {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }

  .black-opacity {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: none;

    .actions-container {
      position: absolute;
      top: 10%;
      left: 100%;
      width: 80%;
      background-color: white;
      padding: 15px;
      border-radius: 15px;
      animation: slidein 1s forwards;

      h3 {
        margin-bottom: 0;
      }

      .title-affect {
        margin-bottom: 0.5rem;

        .label {
          display: flex;
          align-items: center;
          margin-top: 1px;
          padding: 1px 0;
        }
      }

      .title-action {
        display: flex;
        align-items: center;

        svg {
          margin-right: 15px;
        }

        .round {
          &:hover {
            background-color: $hover;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          float: right;

          svg {
            margin: 0;
          }
        }
      }

      .title-action {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        svg {
          margin-right: 15px;
        }
      }
    }

    @keyframes slidein {
      from {
        left: 100%;
      }

      to {
        left: 10%;
      }
    }
  }
}

.tag-container {
  width: 100%;
  display: flex;

  .input-container {
    width: 70%;

    &.left {
      width: 18%;
      margin-right: 2%;
    }
  }

  .poubelle-container {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    justify-content: center;
    width: 10%;

    .clickable-poubelle {
      background-color: lightgrey;
      border-radius: 50%;
      height: 35px;
      width: 35px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: $hover;
      }
    }
  }
}

.label-list-container {
  width: 100%;
  height: 100%;
  display: flex;

  .red-cross {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .span {
      opacity: 0;
      transition: 0.3s;
    }

    .svg {
      cursor: pointer;
      margin-right: 7px;
    }

    .svg:hover + .span {
      opacity: 1;
    }
  }

  .solo-graph {
    .doughnut-chart {
      width: 100%;
    }
  }

  span {
    color: black;
    margin-right: 5px;
  }

  .labels-container {
    padding: 5px;
    overflow-y: auto;
    width: 100%;
    height: 77%;
    margin-top: -1px;
    margin-bottom: 1px;

    .label {
      display: flex;
      align-items: center;
      margin-top: 1px;
      justify-content: center;

      .hover-container {
        width: auto;
        position: absolute;
        z-index: 10;
        display: flex;
        align-items: center;

        .pointe {
          height: 15px;
          width: 15px;
          background-color: #000000;
          transform: rotate(45deg);
          margin-left: -9px;
        }

        .info-label {
          background-color: #000000;
          padding: 5px;
          border-radius: 5px;
        }

        span {
          color: white;
          margin: 0 5px;
        }
      }
    }

    span {
      display: block;
      font-size: 14px;
    }
  }
}

.device-container {
  .logo {
    height: 20%;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .container-login {
    height: 80% !important;
  }
}

.synchro-device {
  height: 100%;
  overflow: hidden;

  .header {
    display: flex;
    padding: 15px;
    align-items: center;
    background-color: #333;

    h3 {
      margin-bottom: 0;
      margin-left: 10px;
      width: 80%;
      color: white;
    }
  }

  .body {
    height: 100%;
    padding: 15px;
    background-image: linear-gradient(to top, #31c6b3, #239eab, #40768d, #465161, #333333);

    .spinner-container {
      height: 70%;
      display: flex;
      align-items: center;
      justify-content: center;

      .round-spinner {
        width: 5rem;
        height: 5rem;
        color: #31c6b3;
      }
    }

    .synchro-button {
      width: calc(100% - 30px);
      bottom: 0.5rem;
      position: fixed;
      text-align: center;

      button {
        border-radius: 30px;
        background-image: linear-gradient(to right top, #31c6b3, #59c88d, #8bc663, #bebe3d, #f2ae2e);
        width: 50%;
        height: 4em;
        border: none;
      }

      h5 {
        margin-bottom: 0;
      }
    }

    .tri-container {
      width: 70%;
      display: flex;
      align-items: center;
      margin: 1rem 0;

      input[type='text'],
      input[type='number'],
      textarea {
        font-size: 16px;
      }

      .filter-name {
        background-color: transparent;
        color: white;
        width: 70%;
        border: 1px solid #31c6b3;
      }

      .svg-filter {
        margin-left: 10%;
        width: 20%;
        text-align: center;
        padding: 3px;
        border-radius: 5px;
      }

      .open-fleche {
        position: absolute;
        right: 0;
        background-color: $backgroundElement;
        padding: 5px 15px 5px 10px;
        border-radius: 15px 0 0 15px;
      }
    }

    .separator {
      width: 80%;
      border-bottom: 1px solid #31c6b3;
      height: 0;
      margin: 2rem 0 2rem 10%;
    }

    .rounds-container {
      overflow: auto;
      max-height: 75%;
      padding: 5px;

      .information-container-device {
        h5 {
          color: white;
          font-weight: normal;
        }
      }

      .round-device {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        height: 100px;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: white;
        //background-image: linear-gradient(to right, #40768d, #446c83, #466378, #475a6d, #465161);
        -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.75);

        .point-container {
          width: 10%;

          .point {
            height: 10px;
            width: 10px;
            border-radius: 9999px;
          }
        }

        .title {
          width: 20%;
        }

        .infos {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }

  .black-opacity {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: none;

    .actions-container {
      position: absolute;
      top: 10%;
      left: 100%;
      width: 80%;
      background-color: white;
      padding: 15px;
      border-radius: 15px;
      animation: slidein 1s forwards;

      h3 {
        margin-bottom: 0;
      }

      .title-affect {
        margin-bottom: 0.5rem;

        .label {
          display: flex;
          align-items: center;
          margin-top: 1px;
          padding: 1px 0;
        }
      }

      .title-action {
        display: flex;
        align-items: center;

        svg {
          margin-right: 15px;
        }

        .round {
          &:hover {
            background-color: $hover;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          float: right;

          svg {
            margin: 0;
          }
        }
      }

      .title-action {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        svg {
          margin-right: 15px;
        }
      }
    }

    @keyframes slidein {
      from {
        left: 100%;
      }

      to {
        left: 10%;
      }
    }
  }
}

.switch-container {
  display: flex;
  align-items: center;

  .switch-point {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    z-index: 2;
    position: absolute;
    cursor: pointer;

    &.slide-right {
      animation: slideRight 0.25s forwards;
    }

    &.slide-left {
      animation: slideLeft 0.25s forwards;
    }
  }

  .switch-bar {
    height: 10px;
    width: 30px;
    margin-left: -10px;
    z-index: 1;
    border-radius: 5px;
  }
}

@keyframes slideRight {
  from {
    margin-left: -15px;
  }

  to {
    margin-left: 0px;
  }
}

@keyframes slideLeft {
  from {
    margin-left: 0px;
  }

  to {
    margin-left: -15px;
  }
}

.detail-site-pie {
  .chart-container {
    height: 200px;
  }
}

.synchro-message-new {
  background-color: #c8e6c9;
}

.synchro-message-old {
  td:nth-child(2) {
    background-color: #c8e6c9;
  }
}

.synchro-message {
  background-color: white;
}
.loadingg {
  background-color: lightgreen;
}

.fixed-scroll-bar-container {
  position: fixed;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: lightgrey;
  border-radius: 20px;

  .fixed-scroll-bar {
    max-width: 95%;
    overflow-x: auto;
    height: 15px;
    overflow-y: hidden;
    background-color: white;

    h3 {
      color: lightgrey;
    }
  }
}

.filter.date-filter {
  display: flex;

  .filter-label {
    display: none;

    select {
      display: none;
    }
  }

  label {
    width: 100%;
  }
}

.fiche-tab-container {
  .table-info-container {
    min-height: 500px;

    .react-bootstrap-table,
    .table.table-hover {
      min-height: 500px;
    }

    .unsortable-input {
      margin-bottom: 0.5rem;
    }

    .table-select__placeholder {
      font-weight: 400;
      line-height: 1.5;
      font-size: 10px;
      color: #495057;
    }

    .table-select__value-container {
      padding: 0;
    }

    .table-select__indicators {
      span {
        display: none;
      }

      svg {
        width: 15px;
        fill: #333333 !important;
      }
    }

    .table-select__control {
      min-height: 0;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 6px;
      border-color: #ced4da;

      span {
        font-weight: 400;
        line-height: 1.5;
        font-size: 10px;
        color: #495057;
      }
    }

    .table-select__indicator {
      padding: 4px;
    }
  }
}

.material-icon-check {
  color: $backgroundElement;
}

.material-icon-close {
  color: $danger;
}

.remoteReadingData {
  @media screen and (max-width: 1920px) {
    .simple-date-component {
      .form-group {
        width: 30% !important;
      }
    }

    .displayChartBlock {
      .table-info-container {
        height: 415px !important;
      }

      .flip-card-inner {
        height: 415px !important;
      }
    }
  }

  @media screen and (max-width: 1600px) {
    h2 {
      font-size: 22px;
    }

    h4 {
      font-size: 18px;
    }

    .simple-date-component {
      .form-group {
        width: 35% !important;
      }
    }

    .displayChartBlock {
      .table-info-container {
        height: 360px !important;
      }

      .flip-card-inner {
        height: 360px !important;
      }

      .svgChartNotAvailable {
        svg {
          max-height: 250px;
        }
      }
    }

    .map {
      margin: 0 -20px -53px -20px !important;
    }
  }

  @media screen and (max-width: 1366px) {
    h2 {
      font-size: 20px;
    }

    h4 {
      font-size: 16px;
    }

    .simple-date-component {
      .form-group {
        width: 50% !important;
      }
    }

    .displayChartBlock {
      .table-info-container {
        height: 300px !important;
      }

      .flip-card-inner {
        height: 300px !important;
      }

      .svgChartNotAvailable {
        svg {
          max-height: 200px;
        }
      }
    }
  }

  @media screen and (max-width: 1280px) {
    h2 {
      font-size: 16px;
    }

    .simple-date-component {
      .form-group {
        width: 50% !important;
      }
    }

    .map {
      margin: 0 -20px -60px -20px !important;
    }
  }

  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 20px;
    }

    .displayChartBlock {
      .table-info-container {
        height: 380px !important;
      }

      .flip-card-inner {
        height: 380px !important;
      }
    }

    .react-bootstrap-table {
      //height: 80%;
    }

    .map {
      margin: 0 -20px -17px -20px !important;
    }

    .react-bootstrap-table {
      //height: 93%;
    }
  }

  @media screen and (max-width: 1024px) {
  }

  td.crystalList-column {
    white-space: initial;
  }

  .tableRemoteReadingMeter {
    tr:hover {
      background-color: $gray-200 !important;
    }
  }

  .displayMeterBloc {
    height: 1000px;
    margin-top: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 0;
    overflow: hidden;
  }

  .map {
    width: -webkit-fill-available !important;
    height: -webkit-fill-available !important;
    overflow: hidden;
    border-radius: 0 0 15px 15px;
    margin: 0 -20px -46px -20px;
  }

  .react-bootstrap-table {
    overflow-y: auto;
    position: relative;
    flex: 1;
  }

  .react-bootstrap-table-pagination {
    padding-top: 10px;

    .react-bootstrap-table-pagination-list {
      @extend .col-md-12;
      padding: 0;
      width: fit-content;
      width: -moz-fit-content;
      position: relative;
      height: 35px;

      .pagination {
        @extend .absoluteHorizontalCenter;
        margin-bottom: 0;
      }
    }
  }

  .selectedMeterInfoFromTable {
    .noFilled {
      font-size: 16px;
    }
  }

  #displayMeterFlipCard {
    .flip-card {
      //height: 90%;
    }

    .infoMeterSelectedBand {
      padding: 15px;
      border-radius: 15px;
      border: 1px solid #ccc;
      color: black;
      height: 55px;
    }
  }

  .svgChartNotAvailable {
    text-align: center;
    margin-top: 20px;
  }

  .displayChartBlock {
    margin: 0 -15px 0 0;

    .col-md-6,
    col-xl-4 {
      padding: 0 15px 0 0;
    }
  }

  .second-date-component {
    z-index: 10;
    background-color: white;
    border: 1px solid lightgrey;
    top: 90px;
    padding: 10px 0;
    position: fixed;
    margin-left: -15px;
    width: calc(100% - 260px);
    border-radius: 0px;

    .title-date {
      align-self: center;
      padding: 15px;
      min-width: fit-content;
      width: -moz-fit-content;
      width: 25%;
      display: flex;

      svg {
        margin-right: 40px;
        padding-left: 15px;
      }

      h3 {
        color: black;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }

    .flex-box {
      padding: 0;
    }

    .form-group {
      margin-bottom: 0;
      width: 25%;
    }
  }
}

input::placeholder {
  color: #808080;
  font-style: italic;
}

.vmeter-tab {
  .unsortable-input {
    width: 40%;
  }
}

.tab-onglet-import-container {
  .onglet-container {
    display: flex;
    align-items: center;

    .onglet {
      padding: 5px 10px;
      cursor: pointer;
      border: 1px solid lightgrey;

      &:hover {
        border-radius: 5px 5px 0 0;
        border-color: lightgrey;
      }

      &.actif {
        background-color: lightgrey;
        border-radius: 5px 5px 0 0;
        border-color: lightgrey;
      }

      &.inactif {
        background-color: #666666;
        cursor: auto;
        border-radius: 0;

        span {
          color: white;
        }
      }
    }
  }
}

.map {
  .marker-info {
    span {
      color: black;
    }

    h5 {
      color: black;
    }

    h4 {
      color: black;
    }
  }
}

.map-geoloc-list {
  .table {
    margin-bottom: 0;
  }
}

.close-infomap-container {
  background-color: lightgrey;
}

.close-infomap-container:hover {
  background-color: $hover;
}

.loading-animation-container {
  height: 780px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .items-container {
    border: 1px solid lightgrey;
    background-color: white;
    padding: 15px;
    border-radius: 50%;
    width: 370px;
    height: 370px;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    margin: 100px auto;
    margin-bottom: 0;
    margin-top: 0;
    width: 70px;
    height: 70px;
    position: absolute;
    text-align: center;

    -webkit-animation: sk-rotate 2s infinite linear;
    animation: sk-rotate 2s infinite linear;

    .dot1,
    .dot2 {
      width: 60%;
      height: 60%;
      display: inline-block;
      position: absolute;
      top: 0;
      border-radius: 100%;

      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .dot1 {
      background-color: $backgroundElement;
    }

    .dot2 {
      top: auto;
      bottom: 0;
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
      background-color: $danger;
    }
  }

  .spinner2 {
    z-index: -1;
    top: calc(50% - 120px);
    left: calc(50% - 40px);
    width: 350px;
    height: 350px;
    position: absolute;
    text-align: center;

    -webkit-animation: sk-rotate 2s infinite linear;
    animation: sk-rotate 2s infinite linear;

    .dot1,
    .dot2 {
      width: 60%;
      height: 60%;
      display: inline-block;
      position: absolute;
      top: 0;
      border-radius: 100%;

      -webkit-animation: sk-bounce 2s infinite ease-in-out;
      animation: sk-bounce 2s infinite ease-in-out;
    }

    .dot1 {
      background-color: $yellow-saphir;
    }

    .dot2 {
      top: auto;
      bottom: 0;
      -webkit-animation-delay: -1s;
      animation-delay: -1s;
      background-color: #007bff;
    }
  }

  @-webkit-keyframes sk-rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes sk-rotate {
    100% {
      transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }

    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
}

@media screen and (max-width: 1920px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.5rem !important;
  }
}

@media screen and (max-width: 1680px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.5rem !important;
  }

  .selectedBand {
    .elementWithSvg {
      svg {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1444px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.5rem !important;
  }
}

@media screen and (max-width: 1366px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1.4rem !important;
    height: 2.5rem;
  }
}

@media screen and (max-width: 1280px) {
  .DigitRoll__Cell {
    font-size: 1.2em;
    width: 1rem !important;
    height: 2.5rem;
  }
}

.locationModal {
  .locationModalHeader {
    .locationName {
      font-size: 2em;
    }
  }
}

.yield-chart-container {
  height: 100%;
  width: 100%;

  #yield-chart {
    position: relative;
  }

  #tooltip {
    position: absolute;
    left: -200px;
    background-color: white;
    border-radius: 10px;
  }
}

.clusterCC:hover .popupCluster {
  display: inherit;
}

.clusterCC {
  .popupCluster {
    display: none; //NONE
    position: absolute;
    left: calc(100% + 5px);
    width: 220px;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    z-index: 20;
    box-shadow: 0px 0px 5px 0px #adb5bd;

    h3 {
      font-weight: bold;
      font-size: 15px;
      color: #31c6b3;
    }

    .nbMeter {
      font-size: 15px;
      font-weight: bold;
      padding-left: 5px;
    }

    .dot {
      height: 15px;
      width: 15px;
      border-radius: 50%;
      display: inline-block;
    }
  }
}

.popupClusterMeterList {
  left: -245px;
  padding-top: 50px;
}

.clusterMeterList {
  position: relative;
  width: 240px;
  height: 500px;
  padding: 0 30px 0 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px #adb5bd;

  .react-bootstrap-table {
    height: fit-content;
    max-height: fit-content;

    .table th {
      border-top: 0;
    }

    .clusterListColumn {
      position: relative;
      padding: 0 20px 0 20px;
      height: 40px;

      .cell-meterSerial {
        @extend .absoluteVerticalCenter;
        left: 60px;
      }

      .cell-svg {
        @extend .absoluteVerticalCenter;

        svg {
          height: 25px;
        }
      }
    }

    .clusterListColumn:hover {
      background-color: $gray-200;
    }
  }

  .react-bootstrap-table-pagination-list {
    @extend .absoluteHorizontalCenter;
    @extend .col-md-12;
    bottom: 0;
    padding: 0;
    width: fit-content;
    width: -moz-fit-content;
  }
}

.markerGM {
  width: fit-content;
  height: fit-content;
  width: -moz-fit-content;
  position: absolute;

  .selected-marker {
    display: none;
    position: absolute;
    top: -75px;
    left: -37.5px;
  }

  .marker {
    position: absolute;
    top: -16px;
    left: -16px;
  }

  .centerMarker {
    top: -20px;
    left: -20px;
  }

  .markerSelected {
    top: -70px;
  }

  .popupMarker {
    display: none; //NONE
    position: absolute;
    left: calc(100% + 25px);
    width: 220px;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    z-index: 20;
    box-shadow: 0px 0px 5px 0px #adb5bd;

    h3 {
      font-weight: bold;
      font-size: 15px;
      color: #31c6b3;
    }

    .meterSerial {
      font-size: 15px;
      font-weight: bold;
      padding-left: 5px;
    }
  }
}

.markerGM:hover {
  .marker {
    top: -20px;
    left: -20px;

    svg {
      height: 40px;
      width: 40px;
    }
  }

  .centerMarker {
    top: -25px;
    left: -25px;

    svg {
      height: 50px;
      width: 50px;
    }
  }

  .markerSelected {
    top: -80px;
  }

  .selected-marker {
    position: absolute;
    top: -85px;
    left: -42.5px;

    svg {
      height: 85px;
      width: 85px;
    }
  }

  .popupMarker {
    display: inherit;
  }
}

.centerMeterSelected {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50px;
  position: absolute;
  bottom: 125px;
  right: 10px;
  -webkit-box-shadow: 0px 0px 5px 0px $gray-500;
  -moz-box-shadow: 0px 0px 5px 0px $gray-500;
  box-shadow: 0px 0px 5px 0px $gray-500;

  svg {
    @extend .absoluteCentered;
    height: 2.5em;
    width: 2.5em;
  }
}

.tele-releve-container {
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 20px;
  width: 49%;
  margin-bottom: 15px;

  .tele-releve-header {
    display: flex;
    align-items: center;

    h2 {
      margin-bottom: 0;
      color: black;
    }
  }
}

.tele-releve-info-container {
  border-radius: 10px;
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 2%;
  align-items: center;
  display: flex;
  height: 80px;

  h3 {
    margin-bottom: 0;
    color: black;
  }

  .c35 {
    width: 25%;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 10px 0 0 10px;
    background: url('./_svgs/background_blue.svg');
    background-size: 896px 1080px;
    height: 100%;

    svg {
      margin: 0 30px 0 10px;
    }

    span {
      color: white;
    }
  }

  .c15 {
    width: 15%;
    padding: 15px 30px;
  }

  .c20 {
    width: 20%;
    padding: 15px 30px;
  }

  .c10 {
    width: 10%;
    padding: 15px 30px;
  }

  .c5 {
    width: 15%;
    padding: 15px 20px;

    &.button {
      text-align: center;
    }
  }

  .c25 {
    width: 20%;
    padding: 15px 30px;
  }

  @media screen and (max-width: 1590px) {
    h3 {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1375px) {
    h3 {
      font-size: 12px;
    }
  }
}

.tournee-info-container {
  border-radius: 10px;
  width: 100%;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 2%;
  align-items: center;
  display: flex;
  height: 80px;

  .flex-ref {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .round {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      cursor: pointer;
      svg {
        margin: 0;
      }
    }
  }

  h3 {
    margin-bottom: 0;
    color: black;
  }

  .c35 {
    width: 25%;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 10px 0 0 10px;
    background: url('./_svgs/background_orange.svg');
    background-size: 896px 1080px;
    height: 100%;

    svg {
      margin: 0 30px 0 10px;
    }

    span {
      color: white;
    }
  }

  .c15 {
    width: 15%;
    padding: 15px 30px;
  }

  .c20 {
    width: 20%;
    padding: 15px 30px;
  }

  .c10 {
    width: 10%;
    padding: 15px 30px;
  }

  .c5 {
    width: 15%;
    padding: 15px 20px;

    &.button {
      text-align: center;
    }
  }

  .c25 {
    width: 20%;
    padding: 15px 30px;
  }

  @media screen and (max-width: 1590px) {
    h3 {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 1375px) {
    h3 {
      font-size: 12px;
    }
  }
}

.radio-bar {
  .tele-releve-info-container {
    margin-bottom: 1%;
  }

  .radio-info-bar {
    width: 50%;
    border: 1px solid lightgrey;
    border-radius: 10px;
    display: flex;
    background-color: white;
    margin-bottom: 1%;
    height: 100%;

    h3 {
      margin-bottom: 0;
      color: black;
    }

    .c60 {
      width: 50%;
      padding: 15px;
      background: url(./_svgs/background_green.svg);
      background-size: 896px 1080px;
      align-items: center;

      span {
        color: white;
      }
    }

    .c20 {
      width: 25%;
      padding: 15px;
      text-align: center;
    }
  }
}

.telereleve-container-provisionning {
  width: 100%;

  .provisionning-container {
    width: 100%;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 7.5px;

    .header {
      h3 {
        color: black;
      }
    }
  }

  .verif-title-container {
    padding: 10px;
    border: 1px solid lightgrey;
    background-color: white;
    width: 15%;
    border-radius: 15px;
    text-align: left;
    margin-bottom: 10px;

    h3 {
      margin-bottom: 0;
      color: black;
    }
  }
}

.provi-postite-container {
  width: 100%;
  height: fit-content;
  margin-left: 15px;
  position: relative;
  padding: 15px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid lightgrey;

  .postite {
    border-radius: 10px 10px 0 0;
    display: flex;
    height: fit-content;

    &.un {
      .provisionning-container {
        background-color: #31c6b3;
      }
    }

    &.deux {
      margin-top: 15px;

      .provisionning-container {
        background-color: $danger;
      }
    }

    svg {
      font-size: 14px;
      fill: black;
    }
  }

  .button-provi-container {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 80px;
    }
  }
}

.fast-provisioning-container-big {
  background-color: white;
  z-index: 0;
  margin-left: 20px;
  height: fit-content;
  border-radius: 15px;

  .fast-provisioning-container {
    border: 1px solid lightgrey;
    width: 100%;
    background-color: transparent;
    border-radius: 15px;
    padding: 15px;
    height: fit-content;
    position: relative;

    .header {
      width: 50%;
      border-bottom: 2px solid lightgrey;
      padding-bottom: 15px;
    }

    .clip-container {
      background-color: $backgroundElement;
      z-index: -1;
      top: 0;
      left: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      clip-path: polygon(81% 19%, 0 61%, 0 100%);
      border-radius: 15px;
    }

    h3 {
      color: black;
      margin-bottom: 0;
    }

    h4 {
      color: black;
      margin-bottom: 0;
    }

    .info-container {
      margin-top: 15px;
      z-index: 10;
    }
  }

  &.open {
    animation: 0.25s openFastProvi;
  }

  &.close {
    animation: 0.25s closeFastProvi;
  }
}

@keyframes openFastProvi {
  from {
    transform: translate(0, 800px);
  }

  to {
    transform: translate(0, 0);
  }
}

@keyframes closeFastProvi {
  from {
    transform: translate(0, 0);
  }

  to {
    transform: translate(0, 800px);
  }
}

.tele-releve-container {
  &.up {
    animation: 1s table-up;
  }

  &.down {
    animation: 1s table-down;
  }
}

@keyframes table-down {
  from {
    flex: 0 0 100%;
  }

  to {
    flex: 0 0 66.66%;
  }
}

@keyframes table-up {
  from {
    flex: 0 0 66.66%;
  }

  to {
    flex: 0 0 100%;
  }
}

.multi-selection-provisionning {
  label {
    margin-bottom: 0;
    height: 20px;
  }
}

.tele-releve-body {
  table {
    min-width: 0;
    overflow: auto;
    display: block;
    table-layout: auto;
    width: 100%;
  }
}

.text-filter::placeholder {
  color: #808080;
  font-style: italic;
}

.react-bootstrap-table-pagination-list-hidden {
  display: none !important;
}

.remote-table-column:not(:placeholder-shown):not(:disabled):not(.placeholder-selected) {
  background: rgba(104, 226, 13, 0.49);
  color: black;
  font-style: normal;
  font-size: 11px;
}

.demoBand {
  background-color: rgba(240, 132, 12, 0.8);
  color: #444444;
  position: absolute;
  left: 0;
  right: 0;
  top: 70px;
  height: 20px;
  z-index: 1000;
  padding: 1px 0 0 0;
  text-align: -webkit-center;
  border-radius: 0;
  border: 0;
  font-weight: bold;
}

.main-layout .demoBand {
  left: -260px;
}

.gif {
  height: 25px;
  width: 25px;
}

.react-bootstrap-table .ordered-asc:before {
  margin-right: 0px !important;
  opacity: 1 !important;
  content: '\2191';
}

.react-bootstrap-table .ordered-desc:after {
  margin-left: 0px !important;
  opacity: 1 !important;
  content: '\2193';
}

.react-bootstrap-table th.sortable span:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

.react-bootstrap-table th.sortable span:after {
  margin-left: 0px;
  content: '\2193';
  opacity: 0.4;
}

#applyProvisioning {
  position: fixed;
  bottom: 1rem;
  left: calc(50% + 80px);
  z-index: 9999;
}

.table-select__menu {
  width: 250px !important;
  z-index: 10000 !important;
}

.reportContainer {
  height: 100%;
  .round-report-error-container {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    color: red;
    border: 1px solid lightgrey;
    border-radius: 15px;
    h3 {
      font-size: 0.9rem;
      margin: 0 20px;
      font-weight: normal;
    }
    .round-report-error-head {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      background-color: red;
      border-radius: 15px 0 0 15px;
      color: white;
    }
  }
  h1 {
    color: #2c303b;
    text-align: center;
    margin-bottom: 35px;
  }

  h3 {
    color: #2c303b;
    text-align: center;
  }

  .row {
    margin: 0 -15px 0 0;
  }

  .btn {
    box-shadow: none !important;
  }

  .roundedContainer {
    border: 1px solid $gray-300;
    border-radius: 15px;
    margin-right: 15px;
    padding: 10px;
    overflow: hidden;

    .list-group {
      margin: -10px;

      .list-group-item-action {
        display: flex;
        flex-direction: row;
        position: relative;
        color: #333333;

        .reportName {
          align-self: center;
          flex: 1;
        }

        .choiceReportButton {
          align-self: center;
          width: fit-content;
          width: -moz-fit-content;

          button {
            height: 40px;
            width: 40px;
            border: 1px solid $gray-300;
            border-radius: 200px;
            background-color: white;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .reportList {
    height: fit-content;
  }

  .updateReport {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 8px;
    right: 17px;
    background-color: white;
    border-radius: 50px;
  }

  #preview-report {
    padding: 0 !important;
    height: 700px;
    position: relative;
    background-color: $gray-500;

    .pdfobject-container {
      height: 100%;
    }

    #fullscreen {
      @extend .clickable;
      position: absolute;
      right: 140px;
      top: 70px;
      width: 35px;
      height: 35px;

      .svgContent {
        position: relative;
        height: 100%;

        .svgFullScreen {
          @extend .absoluteCentered;
        }
      }
    }

    #fullscreen:hover {
      background-color: rgba(#fff, 0.1);
      border-radius: 50px;
    }

    .pdfContainer {
      height: 100%;

      //overflow-y: auto;
      .reportPdfTitle {
        background-color: $backgroundElement;
        text-align: start;
        padding: 15px 40px;
        color: white;
        margin: 0;

        .orderBy {
          font-size: 20px;
          font-style: italic;
          font-weight: initial;
        }
      }
    }
  }

  .noReportSelected {
    @extend .absoluteCentered;

    div {
      text-align: center;
    }
  }

  .reportButton {
    background-color: $gray-200 !important;
  }

  .activeButton {
    background-color: $backgroundElement !important;
  }

  border-radius: 15px;
  padding: 15px;
  margin: 5px 15px 15px 15px;

  .reportOrderBy {
    font-size: 10px;
    font-style: italic;
    color: grey;
    position: absolute;
  }
}

.settingListGroup {
  margin: 1px !important;

  li {
    padding: 5px;
    display: flex;
    border: none;
    align-items: center;

    .orderName {
      flex: 1;
      @extend .clickable;
    }

    .arrows {
      font-size: 15px;
      margin-right: 25px;
    }

    .arrowOrder {
      @extend .arrows;
    }

    .arrowOrderASC {
      @extend .arrows;
    }

    .arrowOrderDESC {
      @extend .arrows;
    }

    .arrowOrder::before {
      margin-left: 3.5px;
      content: '\2191';
      opacity: 0.4;
    }

    .arrowOrder::after {
      margin-left: 0px;
      content: '\2193';
      opacity: 0.4;
    }

    .arrowOrderASC::before {
      margin-left: 3.5px;
      content: '\2191';
      opacity: 1;
    }

    .arrowOrderASC::after {
      margin-left: 0px;
      content: '\2193';
      opacity: 0.4;
    }

    .arrowOrderDESC::before {
      margin-left: 3.5px;
      content: '\2191';
      opacity: 0.4;
    }

    .arrowOrderDESC::after {
      margin-left: 0px;
      content: '\2193';
      opacity: 1;
    }
  }
}

.reportPopover {
  min-width: 190px;

  .settingReportHeader {
    display: flex;
    align-items: flex-end;
  }

  .horizontalA4 {
    @extend .clickable;
    width: 20px;
    height: 15px;
    margin-right: 10px;
    border: 2px #333333 solid;
    border-radius: 3px;
  }

  .verticalA4 {
    @extend .clickable;
    width: 15px;
    height: 20px;
    border: 2px #333333 solid;
    border-radius: 3px;
  }
}

.react-bootstrap-table-pagination {
  margin-bottom: 80px;
}

.tourneeDoublon {
  margin-left: 20px;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
  }

  .bold-tournee {
    font-weight: bold;
  }
}

.support-basic-container {
  h3 {
    color: #2c303b;
  }

  .custom-setting-container {
    margin-top: 30px;
    padding: 10px;

    .resetValues {
      margin-left: 30px;
    }

    .checkbox-field {
      position: relative;
      min-height: 60px;

      .form-check-input {
        top: 35px;
        left: 0;
        margin: 0;
      }

      .form-check-input::before {
        background-color: red;
      }

      .checkbox-mandatory {
        margin-top: 37px;
      }
    }

    .allBlockInput {
      display: flex;
      flex-wrap: wrap;

      .inputBlock {
        margin-right: 15px;
        min-width: 175px;

        .labelInput {
          display: flex;
          align-items: flex-end;

          span {
            flex: 1;
          }

          svg {
            margin-bottom: 4px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.synchrotools {
  .react-bootstrap-table {
    width: 30%;
  }
}

.fields-enquete p:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.fields-enquete p:first-child {
  padding-top: 0;
}

.fields-enquete p {
  margin-bottom: 0;
  padding: 10px 0;
  min-height: 2rem;
}

.float-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 30px 15px 0;
}

.formgroup-align {
  margin-bottom: 0;
}

#select-type {
  width: 10%;
}

div[id^='defaultValue_list'] {
  width: 10%;
}

div[class^='listeColumns'] {
  width: 30%;
}

#codeList {
  margin-top: 30px;
}

.enq-rnd-link {
  width: 90%;
}

.gateway-container {
  border: 1px solid lightgrey;
  border-radius: 15px;
  background-color: white;
  padding: 15px;
  margin: 0 15px;

  .simple-date-component {
    width: 50% !important;

    .form-group {
      width: 50% !important;
    }
  }

  .master-gateway .react-bootstrap-table-pagination {
    margin-bottom: 40px;
    width: fit-content;
  }

  .big-table-gateway {
    margin-bottom: 0px;
    margin-top: 15px;
  }
}

#remotetable-export {
  display: flex;
  align-items: baseline;
}

#remotetable-export > * {
  margin-right: 10px;
}

.rounded-band {
  border-radius: 10px !important;
}

.rounded-container {
  background-position: left;
  margin-left: 0px;
}

.custom-export-config {
  border-color: white;
  background-color: white;
  color: black;
  display: inline-flex;
  align-items: center;
  margin-left: -14px;
  margin-bottom: 10px;
}

.row {
  z-index: 1;
}

#select-messages {
  z-index: 5;
}

#select-marquages {
  z-index: 5;
}

.sensor-container {
  padding: 5px 15px;
  width: 100%;

  .section-container .title-container {
    margin-bottom: 15px;

    h3 {
      font-size: 30px;
      margin: 0;
    }
  }

  span {
    font-size: 20px;
  }

  span.legend-label {
    font-size: 15px;
  }

  .info-map {
    width: 100%;
    display: flex;

    .info-box {
      background-color: white;
      border: 1px solid lightgrey;
      padding: 15px;
      border-radius: 15px;
      width: 30%;
      margin-right: 15px;
      min-width: 280px;

      .switch-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      span {
        font-size: 0.9em;
      }

      h5 {
        font-size: 1.5em;
        margin-bottom: 0;
        color: black;
      }
    }
  }

  .historique-container {
    background-color: white;
    border: 1px solid lightgrey;
    padding: 15px;
    border-radius: 15px;
    margin-top: 15px;

    .wrapper .row:first-child .day,
    .wrapper .row:last-child .day {
      border-bottom: 0;
    }

    .wrapper .row:first-child .box:first-child {
      border-left: 1px solid rgba(255, 255, 255, 0);
    }

    .legende {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100px;
      margin-left: 10px;

      .legend-box {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    .wrapper {
      .row {
        margin: 0;
        display: flex;

        .day {
          width: 150px;
          display: flex;
          border-bottom: 1px solid rgba(128, 128, 128, 0.6);
          align-items: center;
        }

        .flex-box {
          display: flex;
          width: 89%;

          .box {
            width: 100%;
            height: 50px;
            border-left: 1px solid rgba(128, 128, 128, 0.6);
            border-bottom: 1px solid rgba(128, 128, 128, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &.schema {
              justify-content: unset;
            }
          }

          .box-hour {
            width: 100%;
            height: 50px;
            border-right: 1px solid rgba(128, 128, 128, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &.schema {
              justify-content: unset;
            }
          }
        }
      }
    }
  }

  .temperature-container {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 15px;
    margin-top: 15px;
    padding: 15px;
  }

  .section-container {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 15px;
    margin-top: 15px;
    padding: 15px;
  }

  .downlink-container {
    margin-bottom: 10px;

    .title {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-left: 5px;
      }

      h4 {
        margin-bottom: 0;
      }
    }

    span {
      font-size: 20px;
    }

    .wrapper {
      .synchro-container {
        margin-top: 15px;
        display: flex;
        align-items: center;

        input {
          margin-right: 15px;
        }
      }

      .section:not(:last-of-type) {
        border-bottom: 1px solid gray;
      }

      .section {
        display: flex;
        align-items: center;
        height: 80px;
        margin-left: 50px;

        .span-container {
          width: 150px;
        }

        .flex-box {
          display: flex;

          .button-group {
            display: flex;
            align-items: center;

            .round:first-child {
              margin-right: 5px;
            }

            .delete {
              display: flex;
              align-items: center;
              justify-content: center;

              &:hover {
                background-color: #dc3545;
              }

              .less {
                height: 4px;
                width: 25px;
                border-radius: 4px;
                background-color: black;
              }
            }
          }

          .delay {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 150px;
            margin-right: 50px;

            input:nth-child(1) {
              margin-right: 10px;
            }
          }

          .add-delay {
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;

            .round-add {
              height: 50px;
              width: 50px;
              border: 1px solid lightgrey;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              &:hover {
                background-color: #6c757d;

                h3 {
                  color: white;
                }
              }
            }
          }

          h3 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.error-sensor {
  background-color: #dc3545e3;
  position: fixed;
  width: 70%;
  bottom: 15px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  transform: translate(10%, 0px);

  &.open {
    animation: translateY 400ms ease-in-out forwards;
  }

  h5 {
    margin: 0;
    color: white;
  }

  @keyframes translateY {
    0% {
      opacity: 0;
      transform: translate(10%, 50px);
    }

    100% {
      opacity: 1;
      transform: translateX(10%, 0px);
    }
  }
}

.float-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 30px 15px 0;
}

.frequence-container {
  display: flex;
  margin: 10px 0;
  align-items: center;

  .span {
    font-size: 15px;
  }

  .input {
    display: flex;
    align-items: center;

    input {
      width: 50px;
      margin-right: 15px;
    }

    select {
      width: 100px;
    }
  }
}

.input-number {
  height: calc(1.5em + 0.75rem + 2px);
  margin-right: 10px;
}

.size-15 {
  font-size: 15px !important;
}

.no-flex {
  flex: none;
  margin-right: 10px;
}

.row {
  z-index: 1;
}

#select-messages {
  z-index: 5;
}

#select-marquages {
  z-index: 5;
}

.table thead th {
  vertical-align: top;
}

.alarm-schema-container {
  border-radius: 15px;
  background-color: white;
  border: 1px solid lightgrey;
  margin-bottom: 2vh;
  display: flex;

  .type-alarm-container {
    width: 15%;
    border-radius: 15px 0 0 15px;
    padding: 15px;

    .section {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      h3 {
        margin-bottom: 0;
      }
    }
  }

  .alarm-mozaique-container {
    overflow-x: scroll;
    width: 85%;
    max-width: 85%;
    border-radius: 0 0 15px 0;
    height: max-content;

    .mozaique {
      display: flex;

      &:last-of-type {
        .carreau {
          border-bottom: 0;
        }
      }

      .section {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 15px;
        position: relative;

        h3 {
          color: black;
          margin-bottom: 0;
          position: sticky;
          width: 150px;
          left: calc(50% - 75px);
        }
      }

      .carreau {
        height: 50px;
        width: 50px;
        min-height: 50px;
        min-width: 50px;
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        position: relative;

        &:last-of-type {
          border-right: 0;
        }

        h5 {
          margin-bottom: 0;
        }

        .circle {
          height: 25px;
          width: 25px;
          background-color: $danger;
          border-radius: 50%;
        }

        .line {
          width: 75px;
          position: absolute;
          height: 5px;
          background-color: $danger;
        }

        .line-right {
          width: 50px;
          position: absolute;
          height: 5px;
          background-color: #dc3545;
          left: 25px;
        }

        .line-left {
          width: 50px;
          position: absolute;
          height: 5px;
          background-color: #dc3545;
          right: 25px;
        }
      }
    }
  }
}

.alarm-chart-container {
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding: 15px;
  background-color: white;
  margin-bottom: 2vh;

  canvas {
    height: 300px !important;
  }
}

.no-data-container {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2vh;
  border: 1px solid lightgrey;
  border-radius: 15px;
  background-color: #d3d3d3a7;

  h5 {
    color: black;
    margin-bottom: 0;
  }
}

.chart-alarm-container {
  .legend-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .title {
      margin-right: 30px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .echantillon {
        height: 20px;
        width: 20px;
        margin-right: 5px;
      }

      h5 {
        margin-bottom: 0;
      }
    }
  }
}

.flipped-svg {
  background-color: #ebecf1;

  &:hover {
    background-color: $hover;
  }
}

.rapport-filter {
  border: 1px solid lightgrey;
  border-radius: 10px;

  .row {
    margin-left: 5px !important;
  }

  button {
    margin-left: 0 !important;
  }
}

.displayChartBlock {
  .col-md-6:nth-child(1),
  .col-md-6:nth-child(2),
  .col-md-6:nth-child(3) {
    margin: 10px 0;
  }

  .flip-card {
    margin-top: 0 !important;

    .flip-card-inner {
      height: 100% !important;
    }
  }
}

.css-26l3qy-menu {
  z-index: 10 !important;
}

.master-gateway {
  .react-bootstrap-table {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    margin-bottom: 10px;

    thead {
      position: sticky;
      top: -1px;
      height: 50px;
      background-color: white;
      z-index: 10;
    }
  }
}

.mapbox-popup-image {
  width: 20px;
}

.mapboxgl-marker {
  cursor: pointer !important;
  position: absolute !important;
}

.spider-leg-container {
  z-index: 2;

  .spiderfier {
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    background-size: 34px 34px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .red_radio {
    background-image: url('Mapbox/images/svg/red_radio.svg');
  }

  .red_manual {
    background-image: url('Mapbox/images/svg/red_manual.svg');
  }

  .red_telereleve {
    background-image: url('Mapbox/images/svg/red_tele.svg');
  }

  .green_manual {
    background-image: url('Mapbox/images/svg/green_manual.svg');
  }

  .green_radio {
    background-image: url('Mapbox/images/svg/green_radio.svg');
  }

  .green_telereleve {
    background-image: url('Mapbox/images/svg/green_tele.svg');
  }

  .purple_radio {
    background-image: url('Mapbox/images/svg/purple_radio.svg');
  }

  .purple_telereleve {
    background-image: url('Mapbox/images/svg/purple_tele.svg');
  }

  .blue_radio {
    background-image: url('Mapbox/images/svg/blue_radio.svg');
  }

  .blue_telereleve {
    background-image: url('Mapbox/images/svg/blue_tele.svg');
  }

  .yellow_manual {
    background-image: url('Mapbox/images/svg/yellow_manual.svg');
  }

  .yellow_radio {
    background-image: url('Mapbox/images/svg/yellow_radio.svg');
  }

  .yellow_telereleve {
    background-image: url('Mapbox/images/svg/yellow_tele.svg');
  }
}

.spider-leg-container .spider-leg-line {
  background-color: #f4f4f4;
}

.spider-leg-container:hover .spider-leg-line {
  background-color: #f404f4;
}

.spider-leg-container {
  width: 1px;
  height: 1px;
  overflow: display;
  will-change: transform;
}

.spider-leg-container:hover {
  cursor: pointer;
}

.spider-leg-container .spider-leg-pin {
  position: relative;
}

.spider-leg-container .spider-leg-pin.default-spider-pin {
  position: relative;
  width: 25px;
  height: 41px;
  margin-left: -11.5px;
  margin-top: -38.5px;
  background-image: url(https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0-beta.2/images/marker-icon.png);
}

.spider-leg-container .spider-leg-line {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 2px;
  background-color: #343434;
  opacity: 0.45;
  transform-origin: bottom;
  z-index: 0;
  //height: 50px!important
}

.spider-leg-container:hover .spider-leg-line {
  opacity: 1;
}

/* Animations specific styles */

.spider-leg-container.animate {
  -webkit-transition: margin 0.15s linear;
  -moz-transition: margin 0.15s linear;
  -ms-transition: margin 0.15s linear;
  -o-transition: margin 0.15s linear;
  transition: margin 0.15s linear;
}

.spider-leg-container.initial,
.spider-leg-container.exit {
  margin-left: 0 !important;
  margin-top: 0 !important;
  height: 0;
}

.spider-leg-container.animate .spider-leg-line {
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;

  -webkit-transition-delay: inherit;
  -moz-transition-delay: inherit;
  -ms-transition-delay: inherit;
  -o-transition-delay: inherit;
  transition-delay: inherit;
}

.spider-leg-container.animate.initial .spider-leg-line,
.spider-leg-container.animate.exit .spider-leg-line {
  height: 0 !important;
}

.unclustered {
  line-height: 0px;
  z-index: 2;

  div {
    padding-bottom: 10px;
    padding-top: 13px;
  }
}

.cluster {
  z-index: 2;
  width: 280px;

  div {
    h3 {
      line-height: 0;
      margin-top: 5px;
      margin-bottom: 10px;
      width: 270px;
    }

    div {
      padding-top: 3px;
    }
  }
}

.enquete-page-container {
  .select-sortable-container {
    width: 30%;
    display: flex;
    flex-direction: column;
  }
  .select-sortable-error-container {
    margin: 0.3rem 0;
    border: lightgrey 1px solid;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
    p {
      margin: 0;
    }
  }
  .select-sortable-error-head {
    background-color: red;
    color: white;
    border-radius: 8px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 5%;
    font-weight: bolder;
  }
  .select-sortable-select {
    width: 100%;
  }
  .select-sortable-select-error {
    border: 1px solid red;
    border-radius: 5px;
    box-shadow: 0 0 1px red;
  }
}

#root .react-bootstrap-table {
  max-width: unset;
  overflow: unset;
}
