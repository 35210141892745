.message-com-container {
  height: 100%;
  h5 {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
  }
  .wifi-symbol {
    display: none;

    $circleColor: #31c6b3;
    $size: 60px;

    [foo],
    & {
      display: block;
      position: absolute;
      top: 25%;
      left: calc(50% + 50px);
      display: inline-block;
      width: $size;
      height: $size;
      margin-top: 0 - $size - $size * 0.25;

      -ms-transform: rotate(-45deg) translate(-100px);
      -moz-transform: rotate(-45deg) translate(-100px);
      -o-transform: rotate(-45deg) translate(-100px);
      -webkit-transform: rotate(-45deg) translate(-100px);
      transform: rotate(-45deg) translate(-100px);
    }
    .wifi-circle {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      font-size: $size/7;
      position: absolute;
      bottom: 0;
      left: 0;
      border-color: $circleColor;
      border-style: solid;
      border-width: 1em 1em 0 0;
      -webkit-border-radius: 0 100% 0 0;
      border-radius: 0 100% 0 0;

      opacity: 0;
      -o-animation: wifianimation 3s infinite;
      -moz-animation: wifianimation 3s infinite;
      -webkit-animation: wifianimation 3s infinite;
      animation: wifianimation 3s infinite;

      &.first {
        -o-animation-delay: 800ms;
        -moz-animation-delay: 800ms;
        -webkit-animation-delay: 800ms;
        animation-delay: 800ms;
      }
      &.second {
        width: 5em;
        height: 5em;
        -o-animation-delay: 400ms;
        -moz-animation-delay: 400ms;
        -webkit-animation-delay: 400ms;
        animation-delay: 400ms;
      }
      &.third {
        width: 3em;
        height: 3em;
      }
      &.fourth {
        width: 1em;
        height: 1em;
        opacity: 1;
        background-color: $circleColor;
        -o-animation: none;
        -moz-animation: none;
        -webkit-animation: none;
        animation: none;
      }
    }
  }

  @-o-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
  @-moz-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
  @-webkit-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
}
