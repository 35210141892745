.support-import-billing {
  min-height: calc(100vh - 90px);
  margin-top: -10px;
  background-color: #222222;
  padding: 20px;
  color: white;

  form {
    margin-top: 2vh;
  }

  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    text-align: center;
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.5rem;
  }

  .wrapper {
    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  .row {
    display: flex;
    align-items: center;
  }

  .main-container {
    .block {
      margin-top: 5vh;

      h4 {
        margin-bottom: 2.5vh;
      }

      h5 {
        margin-bottom: 20px;
      }
    }

    .allBlockInput {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px;
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      margin-bottom: 2.5vh;

      .inputBlock {
        width: 30%;
        min-width: 450px;
        z-index: 2;
        margin-bottom: 5vh;

        .checkbox-container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .resultBlock {
            z-index: 2;
            font-size: 20px;
          }

          .flex-box {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .izi-switch {
            margin-right: 0;
          }

          label {
            margin-bottom: 5px;
            font-size: 14px;
            text-align: center;
          }

          svg {
            margin-left: 10px;
          }
        }

        .textBlock {
          font-size: 20px;
        }
      }
    }

    .allBlockResult {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px;
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      margin-bottom: 2.5vh;
      gap: 20px;

      .resultBlock {
        z-index: 2;
        font-size: 20px;
      }

      .resultBlock:nth-child(3n + 2) {
        text-align: center;
      }

      .resultBlock:nth-child(3n + 3) {
        text-align: end;
      }
    }

    .allBlockInput::before,
    .allBlockResult::before {
      content: '';
      position: absolute;
      width: 120%;
      height: 120%;
      background: linear-gradient(to left, #75d5fd, #31c6b3);
      margin-left: -10%;
    }

    .allBlockInput::after,
    .allBlockResult::after {
      content: '';
      position: absolute;
      inset: 4px;
      border-radius: 16px;
      background-color: #222222;
    }
  }
}

.styled-input {
  $primary: #11998e;
  $secondary: #38ef7d;
  $white: #fff;
  $gray: #9b9b9b;
  $errorLight: #31c6b3;
  $errorDark: #f21a1d;

  label {
    margin: 0;
  }

  .form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
  }

  input::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $gray;
    outline: 0;
    font-size: 1.3rem;
    color: $white;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form__label {
      font-size: 1.3rem;
      cursor: text;
      top: 20px;
    }
  }

  .form__label {
    position: absolute;
    top: 0;
    transition: 0.2s;
    font-size: 1rem;
    color: $gray;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      display: block;
      float: right;
      height: 16px;
    }
  }

  .form__field:focus {
    ~ .form__label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 1rem;
      color: $primary;
      font-weight: 700;

      &.error {
        color: $errorDark;
      }
    }

    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;
  }

  .form__field.error {
    border-image: linear-gradient(to right, $errorDark, $errorLight);
    border-image-slice: 1;

    ~ .form__label {
      color: $errorDark;
    }
  }

  .form__field.valid {
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;

    ~ .form__label {
      color: $primary;
    }
  }

  /* reset input */
  .form__field {
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }
}
.styled-input-select {
  $primary: #11998e;
  $secondary: #38ef7d;
  $white: #fff;
  $gray: #9b9b9b;
  $errorLight: #31c6b3;
  $errorDark: #f21a1d;

  label {
    margin: 0;
  }

  .form__group {
    position: relative;
    padding: 15px 0 0;
    margin-top: 10px;
  }

  input::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  .form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid $gray;
    outline: 0;
    font-size: 1.3rem;
    color: $white;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;

    &::placeholder {
      color: transparent;
    }

    &:placeholder-shown ~ .form__label {
      font-size: 1.3rem;
      cursor: text;
      top: 20px;
    }
  }

  .form__label {
    position: absolute;
    top: 0;
    transition: 0.2s;
    font-size: 1rem;
    color: $gray;
    width: 100%;

    svg {
      display: block;
      float: right;
      height: 16px;
    }
  }

  .form__field:focus {
    ~ .form__label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 1rem;
      color: $primary;
      font-weight: 700;

      &.error {
        color: $errorDark;
      }
    }

    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;
  }

  .form__field.error {
    border-image: linear-gradient(to right, $errorDark, $errorLight);
    border-image-slice: 1;

    ~ .form__label {
      color: $errorDark;
    }
  }

  .form__field.valid {
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;

    ~ .form__label {
      color: $primary;
    }
  }

  /* reset input */
  .form__field {
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }
}
.flash-button {
  border: 3px solid #75d5fd;
  background-color: transparent;
  color: white;
  border-radius: 5px;
  height: 60px;
  font-size: 18px;
  transition: 1s;

  &:hover {
    background-color: #75d5fd;
    box-shadow: 0 0 10px #75d5fd, 0 0 30px #75d5fd, 0 0 60px #75d5fd, 0 0 90px #75d5fd;
  }

  &.submit {
    display: block;
    margin: 20px auto;
    border-color: #31c6b3;

    &:hover {
      background-color: #31c6b3;
      box-shadow: 0 0 10px #31c6b3, 0 0 30px #31c6b3, 0 0 60px #31c6b3, 0 0 90px #31c6b3;
    }
  }

  &.submitColor {
    border-color: #31c6b3;

    &:hover {
      background-color: #31c6b3;
      box-shadow: 0 0 10px #31c6b3, 0 0 30px #31c6b3, 0 0 60px #31c6b3, 0 0 90px #31c6b3;
    }
  }

  &.error {
    border-color: #f21a1d;

    &:hover {
      background-color: #f21a1d;
      box-shadow: 0 0 10px #f21a1d, 0 0 30px #f21a1d, 0 0 60px #f21a1d, 0 0 90px #f21a1d;
    }
  }

  &:disabled {
    border-color: gray;
    color: lightgray;

    &:hover {
      background-color: inherit;
      box-shadow: none;
    }
  }
}

.izi-switch {
  margin-right: 20px;
  width: 40px;
  height: 20px;
  background-color: #dc3545;
  border-radius: 20px;
  position: relative;
  transition: 0.5s;

  .circle {
    background-color: white;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: 0.5s;
  }

  &.checked {
    background-color: #31c6b3;

    .circle {
      left: 22px;
    }
  }
}

.neon {
  position: relative;
  outline: none;
  color: #fff;
  font-weight: bold;
}

.div-tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.clickable-div-tag {
  height: 30px;
  border-radius: 15px;
  border: 2px solid #007bff;
  padding: 4px 13px;
  cursor: pointer;

  &.selected {
    background-color: #007bff;
  }

  &.mandatory {
    border: 2px solid #f21a1d;

    &.selected {
      background-color: #f21a1d;
    }
  }
}

.div-tag-container::after {
  content: '';
  flex: auto;
}

.configContainer {
  margin-top: 3vh;
  padding: 50px;
  border-radius: 20px;
  margin-bottom: 2.5vh;
  border: solid #31c6b3 5px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 1650px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1250px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 950px) {
    grid-template-columns: 1fr;
  }

  .inputBlock {
    display: grid;
    grid-template-columns: 1fr 3fr;
    margin-bottom: 30px;
    font-size: 16px;
    flex-grow: 1;
    align-items: center;
    color: #2c303b;
    gap: 10px;

    label {
      margin: 0;
      color: white;
    }

    .tag-option {
      display: flex !important;
      justify-content: space-between !important;
    }
  }
}

.MuiInputBase-root {
  color: white;

  &::before {
    border-color: white !important;
  }

  .MuiSelect-icon {
    color: white;
  }
}
